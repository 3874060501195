import { Fetcher, fetchWithRetry } from "@utils/fetcher.js";
import apiUrl from "@utils/url.js";

export async function fetchImageById(imageId) {
  const url = apiUrl(`imagebank/${imageId}`);
  return fetchWithRetry("getJson", [url])
    .then((image = []) => {
      return image;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function postImage(image) {
  const url = apiUrl(`imagebank`);
  return Fetcher.post(url, image)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function putImage(image) {
  const url = apiUrl(`imagebank`);
  return Fetcher.put(url, image)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function fetchClassificationOptions(codegroupno, papercode) {
  const url = apiUrl(`parameters/${codegroupno}/${papercode}`);
  return fetchWithRetry("getJson", [url])
    .then((classification = []) => {
      return classification;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function fetchBusinessUnitIds() {
  const url = apiUrl(`businessunit`);
  return fetchWithRetry("getJson", [url])
    .then((businessUnitIds = []) => {
      return businessUnitIds;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}
