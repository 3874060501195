import { removeNullValues, resolveFieldValueFromObject } from "@utils/common.js";
import { Fetcher, fetchWithRetry } from "@utils/fetcher.js";
import apiUrl from "@utils/url.js";


export async function fetchPriceGroups() {
  const url = apiUrl(`pricegroup`);

  return fetchWithRetry("getJson", [url])
    .then((priceList = []) => {
      return priceList;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function postPriceGroup(priceGroup) {
  const url = apiUrl(`pricegroup`);

  const { papercode, pricegroup, pricegroupname, renew_pricegroup,
    singlecopy_category, winsale_period_mm, winsale_pricegroup,
    cancel_pricegroup, comp_pricegroup } = priceGroup;

  let payload =
  {
    papercode: papercode && papercode.value ? papercode.value : papercode,
    pricegroup,
    pricegroupname,
    renew_pricegroup: renew_pricegroup && renew_pricegroup.value ? renew_pricegroup.value : renew_pricegroup,
    singlecopy_category: singlecopy_category && singlecopy_category.value ? singlecopy_category.value : singlecopy_category,
    winsale_period_mm,
    winsale_pricegroup,
    cancel_pricegroup,
    comp_pricegroup: comp_pricegroup && comp_pricegroup.value ? comp_pricegroup.value : comp_pricegroup
  }

  return Fetcher.post(url, payload)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function putPriceGroup(priceGroup) {
  const url = apiUrl(`pricegroup`);

  const { papercode, pricegroup, pricegroupname, renew_pricegroup, singlecopy_category, winsale_period_mm, winsale_pricegroup, cancel_pricegroup, comp_pricegroup } = priceGroup;

  let payload =
  {
    papercode,
    pricegroup,
    pricegroupname,
    renew_pricegroup: renew_pricegroup && renew_pricegroup.value ? renew_pricegroup.value : !renew_pricegroup ? "" : renew_pricegroup,
    singlecopy_category: singlecopy_category && singlecopy_category.value ? singlecopy_category.value : !singlecopy_category ? "" : singlecopy_category ,
    winsale_period_mm: winsale_period_mm ? winsale_period_mm : 0,
    winsale_pricegroup: winsale_pricegroup ? winsale_pricegroup : "",
    cancel_pricegroup: cancel_pricegroup ? cancel_pricegroup : "",
    comp_pricegroup: comp_pricegroup && comp_pricegroup.value ? comp_pricegroup.value : !comp_pricegroup ? "" : comp_pricegroup,
  }

  return Fetcher.put(url, payload)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function deletePriceGroup(priceGroup) {
  const url = apiUrl(`/pricegroup/${priceGroup.papercode}/${priceGroup.pricegroup}`);

  return Fetcher.delete(url)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}