import { removeNullValues, resolveFieldValueFromObject } from "@utils/common.js";
import { Fetcher, fetchWithRetry } from "@utils/fetcher.js";
import apiUrl from "@utils/url.js";

//Product Packages effects
export async function fetchAllProductPackages() {
  const url = apiUrl("package");

  return fetchWithRetry("getJson", [url])
    .then((productPackages = []) => {
      return productPackages;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function postProductPackages(prodcutPackage) {
  const url = apiUrl(`package`);

  const payload = {
    packageid: prodcutPackage.packageid,
    packagename: prodcutPackage.packagename,
    startdate: prodcutPackage.startdate,
    enddate: prodcutPackage.enddate,
    creditcategory: resolveFieldValueFromObject(prodcutPackage.creditcategory),
    imageid: prodcutPackage.imageid,
    url: prodcutPackage.url,
    descriptivetextid: prodcutPackage.descriptivetextid,

  };
  return Fetcher.post(url, payload)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function putProductPackages(prodcutPackage) {
  const url = apiUrl(`package/${prodcutPackage.packageid}`);

  let payload = {
    packagename: prodcutPackage.packagename,
    startdate: prodcutPackage.startdate,
    enddate: prodcutPackage.enddate,
    imageid: prodcutPackage.imageid,
    url: prodcutPackage.url ? prodcutPackage.url : "",
    creditcategory: resolveFieldValueFromObject(prodcutPackage.creditcategory),
    descriptivetextid: prodcutPackage.descriptivetextid,

  };
  payload = removeNullValues(payload)
  return Fetcher.put(url, payload)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function deleteProductPackages(productPackages) {
  const url = apiUrl(`package/${productPackages.packageid}`);

  return Fetcher.delete(url)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

