/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, { useEffect, useRef, useState } from "react";
import { useSelector, untouch } from "react-redux";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import {
  Col,
  FormGroup,
  Label,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button, ButtonGroup
} from "reactstrap";
import moment from "moment";
import { fi, sv, de, enGB } from "date-fns/locale";
import isValid from "date-fns/isValid";

import { selectCurrentLocale } from "@app/locale/locale.selector";
import { tr } from "@utils/translation.js";
import { dateFormatter } from "@utils/formatters.js";
import { DATE_FORMATS, convertAbbreviationToDate, year1800ToMoment, year2078toMoment } from "@utils/locale.js";

import TextInput from "./TextInput.jsx";
import NumberInput from "./NumberInput.jsx";
import TimeInput from "./TimeInput.jsx";

// Register locale translations
const defaultLocale = "en-gb";
registerLocale("en-gb", enGB);
registerLocale("fi", fi);
registerLocale("sv", sv);
registerLocale("de", de);

/** Find suitable renderer. If not found, uses text input as default. */
const getRenderer = (props) => {
  return renderers[props.type] ? renderers[props.type](props) : renderers.text(props);
};

// List of different renderers for getRenderer to choose from.
const renderers = {
  dropdown: (props) => <Dropdown {...props} />,
  datepicker: (props) => <DatePicker {...props} />,
  text: (props) => <TextInput {...props} />,
  // optimizedText: (props) => <OptimizedTextInput { ...props } />,
  number: (props) => <NumberInput {...props} />,
  // optimizedNumber: (props) => <OptimizedNumberInput { ...props } />,
  time: (props) => <TimeInput {...props} />,
  checkboxgroup: (props) => <CheckboxGroup {...props} />,

};

/**
 * WRAPPER
 *
 * Use FormInput wrapper for regular form inputs. Uses getRenderer to find suitable input.
 * Usage example:
 * ```
 * <Field
 *   name='password'         // redux-form field name
 *   component={FormInput}
 *   type='password'         // types: datepicker, dropdown, text(default)
 *   placeholder='password'  // optional
 *   label='password'        // optional
 *   validate={[ required ]}
 * />
 * ```
 */
export const FormInput = (origProps) => {
  const meta = origProps.meta;
  // Remove FormInput specific props
  const { labelSize = 5, ...props } = origProps;

  // Attach warning or error message + appropriate style if either exists
  let style = "";
  let message = "";
  if (meta.warning) {
    style = "warning";
    message = meta.warning;
  }

  // Consider error more important, thus overwrite potential warning with error message & style
  if (meta.error) {
    style = "error";
    message = meta.error;
  }

  return (
    <FormGroup row className={meta.touched ? style : ""}>
      {props.label && (
        <Label sm={labelSize}>
          {props.required && "*"}
          {props.label.charAt(0).toUpperCase() + props.label.slice(1)}
        </Label>
      )}
      <Col sm={props.label ? 12 - labelSize : 12}>
        {getRenderer(props)}
        {meta.touched && <span>{message}</span>}
      </Col>
    </FormGroup>
  );
};

/** Input with minimal amount of margin and padding. */
export const CompactFormInput = (props) => {
  const { className = "", ...rest } = props;
  return getRenderer({ ...rest, className: `compact-input ${className}` });
};

/**
 * Usage similar to FormInput, however this component should be used when
 * input fields are inline. This way Internet Explorer also renders fields correctly.
 */
export const FormInputInline = (props) => {
  const meta = props.meta;

  return (
    <FormGroup className={meta.touched && meta.error && !props.disabled ? "error" : ""}>
      <div>
        {getRenderer(props)}
        {meta.touched && meta.error && !props.disabled && <span>{meta.error}</span>}
      </div>
    </FormGroup>
  );
};

/* EDITORS: Dropdown, DatePicker */

class Dropdown extends React.Component {
  _renderItems(items) {
    return items.map((item, index) => {
      const onBlur = () => {
        this.props.input.onBlur(item);
      };

      if (typeof item === "object") {
        return (
          <DropdownItem key={index} value={item.name} onClick={onBlur} disabled={item.disabled}>
            {item.name}
          </DropdownItem>
        );
      } else {
        return (
          <DropdownItem key={index} value={item} onClick={onBlur}>
            {item}
          </DropdownItem>
        );
      }
    });
  }

  render() {
    const {
      meta,
      input: { value },
      list = [],
    } = this.props;
    const error = meta.touched && meta.error && !this.props.disabled;

    // In case value is object, get name property which will be displayed in the
    // DropdownToggle. This way if empty array is given to Dropdown which has object
    // value structure, React won't go to infinite loop
    let renderedValue = value;
    if (typeof value === "object") {
      renderedValue = value.name;
    }

    return (
      <UncontrolledDropdown className="default-dropdown">
        <DropdownToggle
          color="default"
          disabled={this.props.disabled}
          className={error ? "error" : ""}
          caret
        >
          {renderedValue || this.props.placeholder || ""}
        </DropdownToggle>
        <DropdownMenu>{this._renderItems(list)}</DropdownMenu>
      </UncontrolledDropdown>
    );
  }
}

function DatePicker(props) {
  const { input, minDate, maxDate,containerStyle = {},showYearPicker=false,isDisabled = false, ...rest } = props;
  // ERP 11465, moment(undefined) is the same as moment(), maxdate or mindate may be wrong if not set in ui, which will make mindate or maxdate as today ...
  let _minDate;
  let _maxDate;
  if (minDate === undefined || minDate === null) {
    _minDate = _formatMomentToDate(year1800ToMoment());
  } else {
    _minDate = _formatMomentToDate(minDate);
  }
  if (maxDate === undefined || maxDate === null) {
    _maxDate = _formatMomentToDate(year2078toMoment());
  } else {
    _maxDate = _formatMomentToDate(maxDate);
  }
  // ... ERP 11465, moment(undefined) is the same as moment(), maxdate or mindate may be wrong if not set in ui, which will make mindate or maxdate as today

  const currentLocale = useSelector(selectCurrentLocale) || defaultLocale;

  const [value, setValue] = useState(null);

  const datepickerRef = useRef(null);

  function _handleBlur(event) {
    const date = convertAbbreviationToDate(event.target.value);
    const formattedDate = _formatMomentToDate(date);

    if (isValid(formattedDate)) {
      _emitMomentValue(formattedDate);
    }
  }

  function _handleChange(date) {
    _emitMomentValue(date);
  }

  /**
   * Accept moment object. This integrates with `moment.js` and is required as long as the project
   * still uses `moment.js`. `react-datepicker` `v4.x.x` uses `date-fns` and native `Date()` objects.
   */
  function _formatMomentToDate(value) {
    // ERP 11446, date object without timezone
    // return new Date(moment.parseZone(value).format("YYYY-MM-DDTHH:mm:ss"));
    // ERP 11465, date still can be wrong if with time left
    return new Date(moment.parseZone(value).format("YYYY-MM-DD"));
    // return new Date(moment(value));
  }

  /**
   * Output moment object. This integrates with `moment.js` and is required as long as the project
   * still uses `moment.js`. `react-datepicker` `v4.x.x` uses `date-fns` and native `Date()` objects.
   */
  function _emitMomentValue(value) {
    input.onChange(moment(value));
  }

  useEffect(() => {
    const incomingValue = _formatMomentToDate(input.value);
    if (isValid(incomingValue) && dateFormatter(incomingValue) !== dateFormatter(value)) {
      setValue(incomingValue);
    }
  }, [input, value, _formatMomentToDate]);

  return (
    <div className="datepicker-container" style={containerStyle}>
      <ReactDatePicker
        className="form-control"
        dateFormat={showYearPicker ? "yyyy" : DATE_FORMATS[currentLocale] || "dd/mm/yyyy"}
        showYearPicker={showYearPicker}
        locale={currentLocale}
        minDate={_minDate || null}
        maxDate={_maxDate || null}
        onBlur={_handleBlur}
        onChange={_handleChange}
        ref={datepickerRef}
        selected={value}
        disabled={isDisabled}
        todayButton={tr("today")}
        {...rest}
      />
      <i
        onClick={() => datepickerRef.current.setOpen(true)}
        className="icon ion-md-calendar datepicker-calendar-icon"
      />
    </div>
  );
}



export const CheckboxGroup = (props) => {

  const { input, meta, value, initialValues, ...rest } = props;
  const [initValues,setInitValues]=useState(initialValues);
  const onCheckboxBtnClick = (name) => {
    var checkboxes = [...input.value];
    if (input.value.indexOf(name) < 0) {
      checkboxes.push(name);
    }
    else {
      checkboxes.splice(input.value.indexOf(name), 1);
    }
    input.onChange(checkboxes);
  };

  const error = meta.touched && meta.error && !props.disabled;

  useEffect(() => {
    let _checkboxes = []
    props.checkboxes.map((checkbox) => {
      if (initValues[checkbox] === true || initValues[checkbox] === "Y" || (initValues[input.name] && initValues[input.name].includes(checkbox)) ) {
        _checkboxes.push(checkbox);
      }
    })

    input.onChange(_checkboxes);
  }, [])

  return (
    <ButtonGroup className={error ? "error" : ""}>
      {
       props.checkboxes.map((name, inx) => {
          return (
            <Button
              color={props.color ? props.color : "primary"}
              outline
              onClick={() => onCheckboxBtnClick(name)}
              active={input.value.includes(name)}
              innerRef={props.inputref}
              size="sm"
              {...rest}
              type="button"
            >
              {props.labels[inx]}
            </Button>)
        })
      }
    </ButtonGroup>

  );
};


