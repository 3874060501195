import { resolveFieldValueFromObject } from "@utils/common.js";
// import * as priceListActions from "./priceList/priceList.action";
// import * as priceGroupActions from "./priceGroup/priceGroup.action";
import * as invoicingProgramActions from "./invoicingProgram/invoicingProgram.action";
import * as invoicingPlanActions from "./invoicingPlan/invoicingPlan.action";


const defaultState = {
 
  invoicingProgram: [],
  invoicingProgramLoading: false,
  removingInvoicingProgram: false,
  selectedProgramRow: {},
  selectedPlanRow: {},

  invoicingPlan: [],
  invoicingPlanLoading: false,
  removingInvoicingPlan: false,

  isSubInvoicesModalOpen: false,
  isCollectionModalOpen: false,
  isProvisionModalOpen: false,
  isReminderModalOpen: false,
  isSingleModalOpen: false,
  isAddEditProgramModalOpen: false,
  isInvoiceSumModalOpen: false,
  invoiceSumLoading:false,
  invoiceSum:{invClassHeading:{}, invClassDetails:[]},
  isAddEditPlanModalOpen: false,



};

const invoicingReducer = (state = defaultState, action) => {
  const { type, payload } = action;

  switch (type) {
    //Invoicing Program Reducers
    case invoicingProgramActions.ADMIN_IS_LOADING_ALL_INVOICING_PROGRAM: {
      return { ...state, invoicingProgramLoading: true };
    }
    case invoicingProgramActions.ADMIN_SET_ALL_INVOICING_PROGRAM: {
      return { ...state, invoicingProgram: payload, invoicingProgramLoading: false };
    }

    case invoicingProgramActions.ADMIN_SET_INVOICING_PROGRAM: {
      console.log({payload})
      //Creating an associative array for invoicing program that will store all the data which user wants to see
      return { ...state, invoicingProgram: {
          ...state.invoicingProgram,
          [`index${payload[0].monetplanno}`]: payload,

      }, invoicingProgramLoading: false };
    }

    case invoicingProgramActions.ADMIN_INVOICING_PROGRAM_EDITED: {
      // let updatedProgram = JSON.parse(JSON.stringify(state.invoicingProgram))
      // let foundProgram = updatedProgram[`index${payload.monetplanno}`].findIndex(sub=> (sub.papergroup == payload.papergroup) && (sub.monetplanno == payload.monetplanno));
      // updatedProgram[`index${payload.monetplanno}`][foundProgram] = payload;
      
      return { ...state, invoicingProgram: updatedProgram };
    }

    case invoicingProgramActions.ADMIN_SET_REMOVING_INVOICING_PROGRAM: {
      return { ...state, removingInvoicingProgram: payload || false };
    }

    case invoicingProgramActions.ADMIN_INVOICING_PROGRAM_REMOVED: {
      const index = state.invoicingProgram.findIndex((p) => (p.monetplanno == payload.monetplanno) && (p.papercode == payload.papercode));
      const invoicingProgram = JSON.parse(JSON.stringify(state.invoicingProgram))
      invoicingProgram.splice(index,1)
      return { ...state, invoicingProgram };
    }

    case invoicingProgramActions.SELECT_INVOICE_PROGRAM_ROW: {
      return { ...state, selectedProgramRow: payload };
    }

    //Invoicing Plan Reducers

    case invoicingPlanActions.ADMIN_IS_LOADING_ALL_INVOICING_PLAN: {
      return { ...state, invoicingPlan: [], invoicingPlanLoading: true };
    }
    case invoicingPlanActions.ADMIN_SET_ALL_INVOICING_PLAN: {
      return { ...state, invoicingPlan: payload, invoicingPlanLoading: false };
    }

    case invoicingPlanActions.INVOICING_CAMPAIGNS_IS_LOADING_ALL_CAMPAIGNS: {
      return { ...state, invoicingPlanCampaigns: [], invoicingPlanCampaignsLoading: true };
    }
    case invoicingPlanActions.INVOICING_CAMPAIGNS_SET_ALL_CAMPAIGNS: {
      return { ...state, invoicingPlanCampaigns: payload, invoicingPlanCampaignsLoading: false };
    }
    case invoicingPlanActions.ADMIN_SET_ALL_AREAS: {
      return { ...state, invoicingPlanAreas: payload, invoicingPlanAreasLoading: false };
    }
    case invoicingPlanActions.ADMIN_SET_ALL_COMMUPAPS: {
      return { ...state, invoicingPlanCommupaps: payload, invoicingPlanCommupapsLoading: false };
    }
    case invoicingPlanActions.INVOICING_COLLECT_IS_LOADING_ALL_COLLECT: {
      return { ...state, invoiceCollect: [], invoiceCollectLoading: true };
    }
    case invoicingPlanActions.INVOICING_COLLECT_SET_ALL_COLLECT: {
      console.log('collect loading falseeeee')
      return { ...state, invoiceCollect: payload, invoiceCollectLoading: false };
    }

    case invoicingPlanActions.ADMIN_INVOICING_PLAN_EDITED: {
      let updatedProgram = JSON.parse(JSON.stringify(state.invoicingPlan))
      let foundProgram = updatedProgram.findIndex(sub=> (sub.papercode == payload.papercode) && (sub.monetplanno == payload.monetplanno));
      updatedProgram[foundProgram] = payload;
      return { ...state, invoicingPlan: updatedProgram };
    }

    case invoicingPlanActions.ADMIN_SET_REMOVING_INVOICING_PLAN: {
      return { ...state, removingInvoicingPlan: payload || false };
    }

    case invoicingPlanActions.ADMIN_INVOICING_PLAN_REMOVED: {
      const index = state.invoicingPlan.findIndex((p) => (p.monetplanno == payload.monetplanno) && (p.papercode == payload.papercode));
      const invoicingPlan = JSON.parse(JSON.stringify(state.invoicingPlan))
      invoicingPlan.splice(index,1)
      return { ...state, invoicingPlan };
    }

    // case invoicingProgramActions.ADMIN_PRICE_IS_LOADING_ALL_INVOICING_CONTROL: {
    //   return { ...state, invoiceControlLoading: true };
    // }
    case invoicingProgramActions.ADMIN_PRICE_LOADING_INVOICING_CONTROL: {
      return { ...state, invoiceControl: [], invoiceControlLoading: true };
    }
    case invoicingProgramActions.ADMIN_PRICE_SET_INVOICING_CONTROL: {
      return { ...state, invoiceControl: payload, invoiceControlLoading: false };
    }
    case invoicingProgramActions.ADMIN_PRICE_LOADING_INVOICING_SINGLE_CONTROL: {
      return { ...state, invoiceSingleControl: [], invoiceSingleControlLoading: true };
    }
    case invoicingProgramActions.ADMIN_PRICE_SET_INVOICING_SINGLE_CONTROL: {
      return { ...state, invoiceSingleControl: payload, invoiceSingleControlLoading: false };
    }
    case invoicingProgramActions.ADMIN_PRICE_LOADING_INVOICING_PROVISION: {
      return { ...state, invoiceProvision: [], invoiceProvisionLoading: true };
    }
    case invoicingProgramActions.ADMIN_PRICE_SET_INVOICING_PROVISION: {
      return { ...state, invoiceProvision: payload, invoiceProvisionLoading: false };
    }
    case invoicingProgramActions.ADMIN_PRICE_LOADING_INVOICING_FEE_GROUPS: {
      return { ...state, invoiceFeeGroups: [], invoiceFeeGroupsLoading: true };
    }
    case invoicingProgramActions.ADMIN_PRICE_SET_INVOICING_FEE_GROUPS: {
      return { ...state, invoiceFeeGroups: payload, invoiceFeeGroupsLoading: false };
    }
    case invoicingProgramActions.ADMIN_PRICE_LOADING_INVOICING_FEEP_GROUPS: {
      return { ...state, invoiceFeepGroups: [], invoiceFeepGroupsLoading: true };
    }
    case invoicingProgramActions.ADMIN_PRICE_SET_INVOICING_FEEP_GROUPS: {
      return { ...state, invoiceFeepGroups: payload, invoiceFeepGroupsLoading: false };
    }
    case invoicingProgramActions.ADMIN_PRICE_LOADING_INVOICING_REMINDER: {
      return { ...state, invoiceReminder: [], invoiceReminderLoading: true };
    }
    case invoicingProgramActions.ADMIN_PRICE_SET_INVOICING_REMINDER: {
      return { ...state, invoiceReminder: payload, invoiceReminderLoading: false };
    }
    case invoicingProgramActions.ADMIN_PRICE_SET_INVOICING_REMINDER_POSTCODES: {
      return { ...state, invoiceReminderPostcodes: payload, invoiceReminderPostcodesLoading: false };
    }
    case invoicingProgramActions.ADMIN_PRICE_SET_INVOICING_COLLECTION_POSTCODES: {
      return { ...state, invoiceCollectionPostcodes: payload, invoiceCollectionPostcodesLoading: false };
    }
    case invoicingProgramActions.ADMIN_PRICE_SET_INVOICING_REMINDER_AREAS: {
      return { ...state, invoiceReminderAreas: payload, invoiceReminderAreasLoading: false };
    }
    case invoicingProgramActions.ADMIN_PRICE_SET_INVOICING_COLLECTION_AREAS: {
      return { ...state, invoiceCollectionAreas: payload, invoiceCollectionAreasLoading: false };
    }
    case invoicingProgramActions.ADMIN_PRICE_LOADING_INVOICING_COLLECTION: {
      return { ...state, invoiceCollection: [], invoiceCollectionLoading: true };
    }
    case invoicingProgramActions.ADMIN_PRICE_SET_INVOICING_COLLECTION: {
      return { ...state, invoiceCollection: payload, invoiceCollectionLoading: false };
    }
    case invoicingProgramActions.ADMIN_PRICE_LOADING_INVOICING_SINGLE_CAMPAIGNS: {
      return { ...state, invoiceSingleCampaigns: [], invoiceSingleCampaignsLoading: true };
    }
    case invoicingProgramActions.ADMIN_PRICE_SET_INVOICING_SINGLE_CAMPAIGNS: {
      return { ...state, invoiceSingleCampaigns: payload, invoiceSingleCampaignsLoading: false };
    }

    //Modals toggling reducers

    case invoicingProgramActions.TOGGLE_SUB_INVOICES_MODAL: {
      return { ...state, isSubInvoicesModalOpen: !state.isSubInvoicesModalOpen };
    }
    case invoicingProgramActions.TOGGLE_COLLECTION_MODAL: {
      return { ...state, isCollectionModalOpen: !state.isCollectionModalOpen };
    }
    case invoicingProgramActions.TOGGLE_PROVISION_MODAL: {
      return { ...state, isProvisionModalOpen: !state.isProvisionModalOpen };
    }
    case invoicingProgramActions.TOGGLE_REMINDER_MODAL: {
      return { ...state, isReminderModalOpen: !state.isReminderModalOpen };
    }
    case invoicingProgramActions.TOGGLE_SINGLE_MODAL: {
      return { ...state, isSingleModalOpen: !state.isSingleModalOpen };
    }
    case invoicingProgramActions.TOGGLE_ADD_EDIT_PROGRAM_MODAL: {
      return { ...state, isAddEditProgramModalOpen: !state.isAddEditProgramModalOpen };
    }
    case invoicingProgramActions.TOGGLE_INVOICE_SUM_MODAL: {
      return { ...state, isInvoiceSumModalOpen: !state.isInvoiceSumModalOpen };
    }
    case invoicingPlanActions.SELECT_INVOICE_PLAN_ROW: {
      return { ...state, selectedPlanRow: payload };
    }
    case invoicingProgramActions.SELECT_INVOICE_PROGRAM_ROW: {
      return { ...state, selectedProgramRow: payload };
    }

    case invoicingProgramActions.ADMIN_IS_LOADING_INVOICE_SUM: {
      return { ...state, invoiceSumLoading: true };
    }

    case invoicingProgramActions.ADMIN_SET_INVOICE_SUM: {
      return { ...state, invoiceSum: payload, invoiceSumLoading: false };
    }
    
    case invoicingPlanActions.TOGGLE_ADD_EDIT_PLAN_MODAL: {
      return { ...state, isAddEditPlanModalOpen: !state.isAddEditPlanModalOpen };
    }
   
    default:
      return state;
  }
};

export default invoicingReducer;
