import { postImage } from "@app/imageBank/imageBank.effect.js";
import { notify } from "@app/notification/notification.action.js";
import * as actionCreator from "@utils/actionCreator.js";
import { tr } from "@utils/translation.js";
import * as effect from "./invoicingPlan.effect.js";

const SLICE = "invoicing";

export const ADMIN_IS_LOADING_ALL_INVOICING_PLAN = `${SLICE}/IS_LOADING_ALL_INVOICING_PLAN`;
export const ADMIN_SET_ALL_INVOICING_PLAN = `${SLICE}/SET_ALL_INVOICING_PLAN`;
export const requestAllInvoicingPlan = () => async (dispatch) => {
  
  dispatch(actionCreator.createAction(ADMIN_IS_LOADING_ALL_INVOICING_PLAN));
  
  const response = await effect.fetchInvoicingPlan();
  if (response.error) {
    dispatch(actionCreator.createAction(ADMIN_SET_ALL_INVOICING_PLAN, []));
    dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }
  dispatch(actionCreator.createAction(ADMIN_SET_ALL_INVOICING_PLAN, response));
};
export const ADMIN_IS_LOADING_ALL_AREAS = `${SLICE}/IS_LOADING_ALL_AREAS`;
export const ADMIN_SET_ALL_AREAS = `${SLICE}/SET_ALL_AREAS`;
export const requestAllAreas = (program) => async (dispatch) => {

  // dispatch(actionCreator.createAction(ADMIN_IS_LOADING_ALL_INVOICING_PLAN));

  const response = await effect.fetchAllAreas(program);
  if (response.error) {
    dispatch(actionCreator.createAction(ADMIN_SET_ALL_AREAS, []));
    dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }
  dispatch(actionCreator.createAction(ADMIN_SET_ALL_AREAS, response));
};
export const ADMIN_IS_LOADING_ALL_COMMUPAPS = `${SLICE}/IS_LOADING_ALL_COMMUPAPS`;
export const ADMIN_SET_ALL_COMMUPAPS = `${SLICE}/SET_ALL_COMMUPAPS`;
export const requestAllCommupaps = (program) => async (dispatch) => {

  // dispatch(actionCreator.createAction(ADMIN_IS_LOADING_ALL_INVOICING_PLAN));

  const response = await effect.fetchAllCommupaps(program);
  if (response.error) {
    dispatch(actionCreator.createAction(ADMIN_SET_ALL_COMMUPAPS, []));
    dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }
  dispatch(actionCreator.createAction(ADMIN_SET_ALL_COMMUPAPS, response));
};

export const addInvoicingPlan = (invoicingPlan, callback = () => { }) => async (dispatch) => {
  const response = await effect.postInvoicingPlan(invoicingPlan);
  if (response.error) {
    dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }

  callback();
  dispatch(notify(tr("invoicingPlanAdded"), "success"));
  dispatch(requestAllInvoicingPlan());
};

export const addInvoicingCollect = (invoicingCollect, refetch = undefined, callback = () => { }) => async (dispatch) => {
  const response = await effect.postInvoiceCollect(invoicingCollect);
  if (response.error) {
    dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }
  if(refetch == true){
    dispatch(requestInvoiceCollect(invoicingCollect));
  }
  callback();
  // dispatch(notify(tr("invoicingCollectAdded"), "success"));
};


export const ADMIN_INVOICING_PLAN_EDITED = `${SLICE}/INVOICING_PLAN_EDITED`;
export const editInvoicingPlan= (invoicingPlan, callback = () => { }) => async (dispatch) => {

  const response = await effect.putInvoicingPlan(invoicingPlan);
  if (response.error) {
    dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }
  callback();
  dispatch(notify(tr("invoicingPlanEdited"), "success"));
  console.log({invoicingPlan})
  dispatch(actionCreator.createAction(ADMIN_INVOICING_PLAN_EDITED, invoicingPlan));

};

export const ADMIN_SET_REMOVING_INVOICING_PLAN= `${SLICE}/SET_REMOVING_INVOICING_PLAN`;
export const ADMIN_INVOICING_PLAN_REMOVED = `${SLICE}/INVOICING_PLAN_REMOVED`;
export const removeInvoicingPlan = (invoicingPlan, callback = () => { }) => async (dispatch) => {

  dispatch(actionCreator.createAction(ADMIN_SET_REMOVING_INVOICING_PLAN, true));

  const response = await effect.deleteInvoicingPlan(invoicingPlan);
  if (response.error) {
    dispatch(notify(tr("dbConnectFailure"), "error"));
    dispatch(actionCreator.createAction(ADMIN_SET_REMOVING_INVOICING_PLAN, false));
    return;
  }
  callback();
  dispatch(notify(tr("invoicingPlanRemoved"), "warning"));
  dispatch(actionCreator.createAction(ADMIN_SET_REMOVING_INVOICING_PLAN, false));
  dispatch(actionCreator.createAction(ADMIN_INVOICING_PLAN_REMOVED, invoicingPlan));

  dispatch(requestAllInvoicingPlan());

};

export const INVOICING_CAMPAIGNS_IS_LOADING_ALL_CAMPAIGNS = `${SLICE}/IS_LOADING_ALL_CAMPAIGNS`;
export const INVOICING_CAMPAIGNS_SET_ALL_CAMPAIGNS = `${SLICE}/SET_ALL_CAMPAIGNS`;
export const requestCampaigns = (paperCode) => async (dispatch) => {

  dispatch(actionCreator.createAction(INVOICING_CAMPAIGNS_IS_LOADING_ALL_CAMPAIGNS));

  const response = await effect.fetchCampaigns(paperCode);
  console.log({response})
  if (response.error) {
    dispatch(actionCreator.createAction(INVOICING_CAMPAIGNS_SET_ALL_CAMPAIGNS, []));
    // dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }
  dispatch(actionCreator.createAction(INVOICING_CAMPAIGNS_SET_ALL_CAMPAIGNS, response));
};
export const INVOICING_COLLECT_IS_LOADING_ALL_COLLECT = `${SLICE}/IS_LOADING_ALL_COLLECT`;
export const INVOICING_COLLECT_SET_ALL_COLLECT = `${SLICE}/SET_ALL_COLLECT`;
export const requestInvoiceCollect = (invoiceParams) => async (dispatch) => {

  dispatch(actionCreator.createAction(INVOICING_COLLECT_IS_LOADING_ALL_COLLECT));

  const response = await effect.fetchInvoiceCollect(invoiceParams);
  if (response.error) {
    if(response.error.errors == 'Invoice coll control not found'){
      const collPayload = {
        monetplanno: invoiceParams.monetplanno,
        invdate: invoiceParams.invdate,
        papercode: invoiceParams.papercode,
        inv_collect: "Y",
        inv_campaign: "N",
        inv_discharge: "N",
      }
      dispatch(addInvoicingCollect(collPayload, true));
    }
    else{
      dispatch(actionCreator.createAction(INVOICING_COLLECT_SET_ALL_COLLECT, []));
    }
    // dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }
  console.log('dispatching .............................',response)
  dispatch(actionCreator.createAction(INVOICING_COLLECT_SET_ALL_COLLECT, response));
};
export const SELECT_INVOICE_PLAN_ROW = `${SLICE}/SELECT_INVOICE_PLAN_ROW`;
export const selectInvoicePlanRow = (row) => async (dispatch) => {
  dispatch(actionCreator.createAction(SELECT_INVOICE_PLAN_ROW,row));
};

export const TOGGLE_ADD_EDIT_PLAN_MODAL = `${SLICE}/TOGGLE_ADD_EDIT_PLAN_MODAL`;

export const toggleAddEditPlanModal = (isOpen) => async (dispatch) => {
  dispatch(actionCreator.createAction(TOGGLE_ADD_EDIT_PLAN_MODAL,isOpen));
};




