/* eslint-disable react/prop-types */
import React from "react";
import { Input } from "reactstrap";

/**
 * Reusable TextInput component. Used to collect string type user input.
 * Use prop `[type]` to control input type. Suitable values are for example 'text',
 * 'textarea', 'password' and 'email'.
 *
 * Usage example:
 * ```
 * <TextInput
 *   onChange={(text) => this._handleTextInput(text)}
 *   type='textarea'
 *   rows='5'
 *   value={this.state.textInput}
 *   placeholder='Feedback'
 * />
 * ```
 */
const TextInput = (props) => {
  const { input, meta, ...rest } = props;

  return <Input {...input} innerRef={props.inputref} {...rest}  />;
};

export default TextInput;

/**
 * The component OptimizedTextInput is meant to be used with redux-form. It
 * optimizes the TextInput by stripping unnecessary redux-form Field callbacks.
 *
 * It DOESN'T trigger onChange on every keypress, which makes redux-form faster
 * and more responsive, especially with larger forms. It changes the redux-form
 * values onBlur and when enter is pressed, i.e. the form submitted.
 *
 * NOTE: There can be some unwanted side effects because many callbacks are
 * disabled, so use with caution and test forms properly before committing!
 */
// class OptimizedTextInput extends React.Component {
//   // Listen enter key presses and call redux-form onChange function on key press.
//   _onKeyDown(ev, onChange) {
//     if (ev.key === 'Enter' && ev.shiftKey === false) {
//       onChange(ev.target.value);
//     }
//   }
//
//   constructor(props) {
//     super(props);
//     this.onKeyDown = (ev) => { this._onKeyDown(ev, props.input.onChange); };
//   }
//
//   render() {
//     // Take only required redux-form functions from 'input' prop
//     const { input: { onBlur, onFocus }, meta, ...rest } = this.props;
//
//     return (
//       <Input
//         onKeyDown={ this.onKeyDown }
//         onBlur={ onBlur }
//         onFocus={ onFocus }
//         { ...rest }
//       />
//     );
//   }
// }

// export { OptimizedTextInput };
