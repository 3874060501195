import React from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { Button, Row, Col, Label, ListGroup, ListGroupItem } from "reactstrap";

import { tr } from "@utils/translation.js";

import { Container, ContentContainer } from "../ui/layout.jsx";
import { SubmitButton } from "../ui/buttons.jsx";
import Loading from "../ui/Loading.jsx";
import Modal from "../ui/Modal.jsx";
import { FormInput } from "@views/components/form/inputs.jsx";

const Email = (props) => {
  const { handleSubmit, submitting, invalid } = props;

  return (
    <Modal
      isOpen={props.isOpen}
      toggle={props.closeRequest}
      title={tr("email")}
      className="single-tab-modal"
    >
      <form onSubmit={handleSubmit} autoComplete="off">
        <Loading loading={props.loading} />
        <ContentContainer className="modal-content-container">
          <Container>
            <Row>
              <Col sm={12}>
                <Label>{`${tr("recipients")} (${tr("separateWithComma")})`}</Label>
                <Field
                  name="recipients"
                  type="text"
                  placeholder="email@example.com"
                  component={FormInput}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <Label>{tr("subject")}</Label>
                <Field
                  name="subject"
                  type="text"
                  placeholder={tr("emailTitle")}
                  component={FormInput}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <Label>{tr("emailBody")}</Label>
                <Field
                  name="body"
                  type="textarea"
                  rows={15}
                  placeholder={tr("message")}
                  component={FormInput}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <Label>{tr("fileUpload")}</Label>
                <br />
                <div className="file-upload-container">
                  <Button className="file-upload-button" onClick={props.onFileUploadButtonClick}>
                    {tr("addFile")}
                  </Button>
                  <input
                    name="fileUpload"
                    type="file"
                    className="file-upload-input"
                    onChange={props.onFileUpload}
                    ref={props.fileUploadRef}
                    multiple
                  />
                  <ListGroup>
                    {props.fileList.map((file, index) => (
                      <Row className="no-gutters" key={index}>
                        <Col sm={10}>
                          <ListGroupItem>{file.name}</ListGroupItem>
                        </Col>
                        <Col sm={2}>
                          <ListGroupItem
                            className="text-center file-upload-remove"
                            onClick={props.onFileRemove}
                            data-id={index}
                            tag="button"
                            action
                          >
                            X
                          </ListGroupItem>
                        </Col>
                      </Row>
                    ))}
                  </ListGroup>
                </div>
              </Col>
            </Row>
          </Container>
        </ContentContainer>
        <div className="modal-footer">
          <Button color="default" onClick={props.closeRequest} className="no-margin">
            {tr("cancel")}
          </Button>
          <SubmitButton submitting={submitting} text={tr("sendEmail")} disabled={!!invalid} />
        </div>
      </form>
    </Modal>
  );
};

Email.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  fileList: PropTypes.array.isRequired,
  fileUploadRef: PropTypes.func.isRequired,
  onFileUpload: PropTypes.func.isRequired,
  onFileUploadButtonClick: PropTypes.func.isRequired,
  onFileRemove: PropTypes.func.isRequired,
  closeRequest: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  invalid: PropTypes.bool,
};

export default Email;
