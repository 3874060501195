/* eslint-disable react/prop-types */
import React from "react";
import { Nav, NavLink, Row, Col } from "reactstrap";

import { tr } from "@utils/translation.js";

import { IconTile, IconSmall, IconButton, ActiveDot } from "./icons.jsx";

/** Main container to be used in every content page. */
export const ContentContainer = (props) => {
  const { children, className: classes = "", ...rest } = props;
  return (
    <div className={`content-container ${classes}`} {...rest}>
      {children}
    </div>
  );
};

/** Usage `<Container>` or `<Container white>` for white background. */
export const Container = (props) => {
  const { children, className: classes = "", white = false, ...rest } = props;
  return (
    <div
      className={`container-fluid ${white ? "bg-white kayak-borders" : ""} ${classes}`}
      {...rest}
    >
      {children}
    </div>
  );
};

/** Full width white background container with shadow on bottom. For searches etc. */
export const ContentHeaderContainer = (props) => {
  const { children, className: classes = "", ...rest } = props;
  return (
    <div className={`container-fluid header-container ${classes}`} {...rest}>
      {children}
    </div>
  );
};

/** White background bordered container. */
export const HeaderRow = (props) => {
  const { children, className: classes = "", ...rest } = props;
  return (
    <Row className={`header-row ${classes}`} {...rest}>
      {children}
    </Row>
  );
};

/** Information box with title, hr and contents. */
export const InfoBox = (props) => {
  return (
    <div className="info-box" id={props.id}>
      <strong className="no-margin">{props.title.toUpperCase()}</strong>
      <hr className="info-box-hr hr-active" />
      {props.children}
    </div>
  );
};

/** Individual row in the InfoBox. */
export const InfoBoxRow = (props) => {
  const { className = "", label, children } = props;
  return (
    <Row className={className}>
      <Col sm={5} className="info-box-row-left">
        {label}
      </Col>
      <Col sm={7} className="info-box-row-right">
        {children}
      </Col>
    </Row>
  );
};

export const InfoBoxRowNumber = (props) => {
  const { className = "", label, children } = props;
  return (
    <Row className={className}>
      <Col sm={5} className="info-box-row-left">
        {label}
      </Col>
      <Col sm={4} className="info-box-row-number-left">
        {children}
      </Col>
    </Row>
  );
};

/** `<HeaderRowWithIcons icons={icons} />` */
export const HeaderRowWithIcons = (props) => {
  const { icons = [], className: classes = "", ...rest } = props;
  return (
    <HeaderRow className={classes} {...rest}>
      {icons.map((icon, index) => (
        <IconButton key={index} icon={icon.icon} color={icon.color} offset={icon.offset}>
          {icon.title}
        </IconButton>
      ))}
    </HeaderRow>
  );
};

/** Header block with icon at the right side-
 * Usage:
 * ```
 * <HeaderWithIconButton icon='ion-ios-add-circle' color='green'>Header text</HeaderWithIconButton>
 * ```
 */
export const HeaderWithIconButton = (props) => {
  const { children, className: classes = "", icon, color, ...rest } = props;
  return (
    <HeaderRow className={classes} {...rest}>
      <Col xs={11} className="no-padding">
        <h2 className="no-margin" style={{ lineHeight: "1.6rem" }}>
          {children}
        </h2>
      </Col>
      <IconSmall icon={icon} color={color} className="no-padding" style={{ float: "right" }} />
    </HeaderRow>
  );
};

/** Controlled tabs component to be used with Reactstrap `<Tabcontent>`.
 * ```
 * tabs = [ { id: '1', title: 'Title 1' }, { id: '2', title: 'Title 2'} ];
 *
 * <TabNavHeader tabs={this.tabs} active={activeTab} toggle={this.toggle} />
 * ```
 */
export const TabNavHeader = (props) => {
  const { active: activeTab, toggle, tabs = [], numbered = false, errors = {}, ...rest } = props;
  const tabNames = typeof tabs === "function" ? tabs() : tabs;

  return (
    <Nav tabs {...rest}>
      {tabNames.map((tab, index) => {
        const { disabled = false } = tab;
        // Flag to determine whether tab has errors or not
        let hasErrors = false;

        // If error object has keys it means that current tab has errors -> change flag
        if (errors[tab.name] !== undefined) {
          if (Object.keys(errors[tab.name]).length !== 0) {
            hasErrors = true;
          }
        }

        return (
          <NavLink
            key={index}
            data-id={tab.id}
            disabled={disabled}
            className={activeTab === tab.id ? "active" : ""}
            onClick={toggle}
          >
            {hasErrors && <ActiveDot />}
            {numbered && `${index + 1}.`}
            {tab.indicatorDataExists === "*" && (
              <div style={{ display: "inline-block" }} className="notification-data-exists"></div>
            )}
            {tr(tab.title)}
          </NavLink>
        );
      })}
    </Nav>
  );
};

/** Row of icons with colored background with a small whitespace in between.
 * ```
 * tiles = [ {color: 'green', icon: 'icon', title: 'Title1'}, {color: 'purple', icon: 'icon', title: 'Tilausmuutos'} ];
 *
 * <IconTileRow tiles={this.tiles} />
 * ```
 */
export const IconTileRow = (props) => {
  const { tiles = [], ...rest } = props;
  return (
    <Row {...rest}>
      <Col xs={12}>
        {tiles.map((t) => (
          <IconTile key={t.title} color={t.color} icon={t.icon}>
            {t.title}
          </IconTile>
        ))}
      </Col>
    </Row>
  );
};
