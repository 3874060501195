import { notify } from "@app/notification/notification.action.js";
import * as effect from "./users.effect.js";
import * as actionCreator from "@utils/actionCreator.js";
import { tr } from "@utils/translation.js";

const SLICE = "adminUsers";

// Not used at the moment, here for example purposes and possible future use
/*
export const ADMIN_USERS_INITIALIZED = `${SLICE}/INITIALIZED`;
export const init = () => async (dispatch) => {
  await Promise.all([dispatch(requestAllUsers()), dispatch(requestAllGroups())]);

  dispatch(actionCreator.createAction(ADMIN_USERS_INITIALIZED));
};
*/

export const ADMIN_USERS_IS_LOADING_USERS = `${SLICE}/IS_LOADING_USERS`;
export const ADMIN_USERS_SET_ALL_USERS = `${SLICE}/SET_ALL_USERS`;
export const requestAllUsers = () => async (dispatch) => {
  dispatch(actionCreator.createAction(ADMIN_USERS_IS_LOADING_USERS));

  const response = await effect.fetchAllUsers();
  if (response.error) {
    dispatch(actionCreator.createAction(ADMIN_USERS_SET_ALL_USERS, []));
    dispatch(notify(tr("dbConnectFailure"), "error"));

    return;
  }

  dispatch(actionCreator.createAction(ADMIN_USERS_SET_ALL_USERS, response));
};

export const ADMIN_USERS_SET_USER_PROPERTIES = `${SLICE}/SET_USER_PROPERTIES`;
export const ADMIN_USERS_IS_LOADING_USER_PROPERTIES = `${SLICE}/IS_LOADING_USER_PROPERTIES`;
export const ADMIN_USERS_LOADING_USER_PROPERTIES_FAILED = `${SLICE}/LOADING_USER_PROPERTIES_FAILED`;
export const requestUserProperties = (userId, callback = () => {}) => async (dispatch) => {
  dispatch(actionCreator.createAction(ADMIN_USERS_SET_USER_PROPERTIES, null));
  dispatch(actionCreator.createAction(ADMIN_USERS_IS_LOADING_USER_PROPERTIES));

  const response = await effect.fetchUserProperties(userId);
  if (response.error) {
    dispatch(notify(tr("dbConnectFailure"), "error"));
    dispatch(actionCreator.createAction(ADMIN_USERS_LOADING_USER_PROPERTIES_FAILED));

    return;
  }

  dispatch(actionCreator.createAction(ADMIN_USERS_SET_USER_PROPERTIES, response));
  callback();
};

export const addUserToProvider = (userData, callback = () => {}, callbackOnError) => async (
  dispatch
) => {
  const user = {
    userId: userData.userid ? userData.userid : userData.userId,
    userName: userData.username ? userData.username : userData.userName,
    mark: userData.mark,
    emailAddress: userData.emailaddress ? userData.emailaddress : userData.emailAddress,
    groups: userData.groups,
    password: userData.passwd ? userData.passwd : userData.password ? userData.password : "",
  };

  const providerResponse = await effect.postUser(user, true);
  if (providerResponse.error) {
    dispatch(notify(tr("authorizeUserInPublisherError"), "error"));
    callbackOnError && callback();
    return;
  }

  dispatch(notify(tr("authorizeUserInPublisherSuccess"), "success"));

  dispatch(
    validateUserInProvider(userData.userid, () =>
      dispatch(
        actionCreator.createAction(ADMIN_USERS_SET_VALIDATE_USER_PROVIDER, {
          userId: userData.userid,
          isValid: true,
        })
      )
    )
  );

  callback();
};

export const createUser = (newUser, callback = () => {}) => async (dispatch) => {
  const groups = newUser.groups || [];

  const response = await effect.postUser(newUser);
  if (response.error) {
    dispatch(notify(tr("dbConnectFailure"), "error"));

    return;
  }

  dispatch(
    addUserToProvider(newUser, () =>
      dispatch(
        actionCreator.createAction(ADMIN_USERS_SET_VALIDATE_USER_PROVIDER, {
          userId: newUser.userid,
          isValid: true,
        })
      )
    )
  );

  callback();
  dispatch(notify(tr("newUserCreated"), "success"));
  groups.length && dispatch(updateUserUserOfGroup(newUser.userId, [], groups));
  dispatch(requestAllUsers());
};

export const ADMIN_USERS_SET_REMOVING_USER = `${SLICE}/SET_REMOVING_USER`;
export const ADMIN_USERS_USER_REMOVED = `${SLICE}/USER_REMOVED`;
export const removeUser = (user, callback = () => {}) => async (dispatch) => {
  dispatch(actionCreator.createAction(ADMIN_USERS_SET_REMOVING_USER, true));

  const response = await effect.deleteUser(user.USERID);
  if (response.error) {
    dispatch(notify(tr("dbConnectFailure"), "error"));
    dispatch(actionCreator.createAction(ADMIN_USERS_SET_REMOVING_USER, false));

    return;
  }

  callback();
  dispatch(notify(tr("userRemoved"), "warning"));
  dispatch(actionCreator.createAction(ADMIN_USERS_SET_REMOVING_USER, false));
  dispatch(actionCreator.createAction(ADMIN_USERS_USER_REMOVED, user));
};

export const ADMIN_USERS_IS_LOADING_GROUPS = `${SLICE}/IS_LOADING_GROUPS`;
export const ADMIN_USERS_SET_ALL_GROUPS = `${SLICE}/SET_ALL_GROUPS`;
export const requestAllGroups = () => async (dispatch) => {
  dispatch(actionCreator.createAction(ADMIN_USERS_IS_LOADING_GROUPS));

  const response = await effect.fetchAllGroups();
  if (response.error) {
    dispatch(notify(tr("dbConnectFailure"), "error"));

    return;
  }

  dispatch(actionCreator.createAction(ADMIN_USERS_SET_ALL_GROUPS, response));
};

/** Both creates & edits groups */
export const saveGroup = (newGroup, callback = () => {}) => async (dispatch) => {
  const users = newGroup.users || [];

  const response = await effect.postGroup(newGroup);
  if (response.error) {
    dispatch(notify(tr("dbConnectFailure"), "error"));

    return;
  }

  callback();
  dispatch(notify(tr("newGroupCreated"), "success"));
  users.length && dispatch(updateGroupUserOfGroup(newGroup.groupId, [], users));
  dispatch(requestAllGroups());
};

export const ADMIN_USERS_SET_REMOVING_GROUP = `${SLICE}/SET_REMOVING_GROUP`;
export const ADMIN_USERS_GROUP_REMOVED = `${SLICE}/GROUP_REMOVED`;
export const removeGroup = (group, callback = () => {}) => async (dispatch) => {
  dispatch(actionCreator.createAction(ADMIN_USERS_SET_REMOVING_GROUP, true));

  const response = await effect.deleteGroup(group.GROUPID);
  if (response.error) {
    dispatch(notify(tr("groupCouldNotBeDeleted"), "error"));
    dispatch(actionCreator.createAction(ADMIN_USERS_SET_REMOVING_GROUP, false));

    return;
  }

  callback();
  dispatch(notify(tr("groupRemoved"), "warning"));
  dispatch(actionCreator.createAction(ADMIN_USERS_SET_REMOVING_GROUP, false));
  dispatch(actionCreator.createAction(ADMIN_USERS_GROUP_REMOVED, group));
};

export const ADMIN_USERS_UPDATING_USER_USER_OF_GROUPS = `${SLICE}/UPDATING_USER_USER_OF_GROUPS`;
export const updateUserUserOfGroup = (userId, oldGroups, newGroups) => async (dispatch) => {
  dispatch(actionCreator.createAction(ADMIN_USERS_UPDATING_USER_USER_OF_GROUPS, true));

  // Filter out groups to be removed and to be added
  let oldSet = oldGroups.filter((a) => !newGroups.find((b) => b.value === a.value));
  oldSet = oldSet.map((a) => ({ group_id: a.value, user_id: userId }));

  let newSet = newGroups.filter((a) => !oldGroups.find((b) => b.value === a.value));
  newSet = newSet.map((a) => ({ group_id: a.value, user_id: userId }));

  await Promise.all([
    ...oldSet.map((g) => effect.removeUserUserOfGroup(g)),
    ...newSet.map((g) => effect.insertUserUserOfGroup(g)),
  ]);

  dispatch(notify(tr("userUserGroupsUpdated"), "success"));
  dispatch(
    requestUserProperties(userId, () =>
      dispatch(actionCreator.createAction(ADMIN_USERS_UPDATING_USER_USER_OF_GROUPS, false))
    )
  );
};

export const ADMIN_USERS_UPDATING_GROUP_USER_OF_GROUPS = `${SLICE}/UPDATING_GROUP_USER_OF_GROUPS`;
export const updateGroupUserOfGroup = (groupId, oldUsers, newUsers) => async (dispatch) => {
  dispatch(actionCreator.createAction(ADMIN_USERS_UPDATING_GROUP_USER_OF_GROUPS, true));

  // Filter out users to be removed and to be added
  let oldSet = oldUsers.filter((a) => !newUsers.find((b) => b.value === a.value));
  oldSet = oldSet.map((a) => ({ group_id: groupId, user_id: a.value }));

  let newSet = newUsers.filter((a) => !oldUsers.find((b) => b.value === a.value));
  newSet = newSet.map((a) => ({ group_id: groupId, user_id: a.value }));

  await Promise.all([
    ...oldSet.map((g) => effect.removeUserUserOfGroup(g)),
    ...newSet.map((g) => effect.insertUserUserOfGroup(g)),
  ]);

  dispatch(notify(tr("userUserGroupsUpdated"), "success"));
};

export const ADMIN_USERS_SET_VALIDATE_USER_PROVIDER = `${SLICE}/SET_VALIDATE_USER_PROVIDER`;
export const ADMIN_USERS_IS_LOADING_VALIDATE_USER_PROVIDER = `${SLICE}/IS_LOADING_VALIDATE_USER_PROVIDER`;
export const ADMIN_USERS_LOADING_VALIDATE_USER_PROVIDER_FAILED = `${SLICE}/LOADING_VALIDATE_USER_PROVIDER_FAILED`;
export const validateUserInProvider = (userId, callback = () => {}) => async (dispatch) => {
  dispatch(actionCreator.createAction(ADMIN_USERS_SET_VALIDATE_USER_PROVIDER, null));
  dispatch(actionCreator.createAction(ADMIN_USERS_IS_LOADING_VALIDATE_USER_PROVIDER));

  const response = await effect.validateUserInProvider(userId);
  if (response.error) {
    dispatch(notify(tr("dbConnectFailure"), "error"));
    dispatch(actionCreator.createAction(ADMIN_USERS_LOADING_VALIDATE_USER_PROVIDER_FAILED));

    return;
  }

  dispatch(
    actionCreator.createAction(ADMIN_USERS_SET_VALIDATE_USER_PROVIDER, {
      userId: userId,
      isValid: response,
    })
  );
  callback();
};
