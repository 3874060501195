import { removeNullValues, resolveFieldValueFromObject } from "@utils/common.js";
import { Fetcher, fetchWithRetry } from "@utils/fetcher.js";
import apiUrl from "@utils/url.js";

export async function fetchClassificationOptions(codegroupno, papercode) {
  const url = apiUrl(`parameters/${codegroupno}/${papercode}`);

  return fetchWithRetry("getJson", [url])
    .then((classification = []) => {
      return classification;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function fetchBusinessUnitIds() {
  const url = apiUrl(`businessunit`);

  return fetchWithRetry("getJson", [url])
    .then((businessUnitIds = []) => {
      return businessUnitIds;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

//Products effetcs

export async function fetchAllProducts(paperCode) {
  paperCode = typeof paperCode === "object" ? paperCode.papercode : paperCode;
  let url = paperCode ? `product/${paperCode}` : `product`;
  url = apiUrl(url);

  return fetchWithRetry("getJson", [url])
    .then((prodcuts = []) => {
      return prodcuts;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function postProducts(product) {
  const url = apiUrl(`product`);

  let payload = {
    productno: product.productno,
    productname: product.productname,
    mo_act: product.mo_act,
    tu_act: product.tu_act,
    we_act: product.we_act,
    th_act: product.th_act,
    fr_act: product.fr_act,
    sa_act: product.sa_act,
    su_act: product.su_act,
    deltype: product.deltype ? product.deltype : "",
    papercode: resolveFieldValueFromObject(product.papercode),
    periodic: resolveFieldValueFromObject(product.periodic),
    vatcode: resolveFieldValueFromObject(product.vatcode),
    producttype: resolveFieldValueFromObject(product.producttype),
    isspecial: product.isspecial ? true : false,
    isdefault: product.isdefault ? true : false,
    isissuecalendar: product.isissuecalendar ? true : false,
    bundleno: product.bundleno ? product.bundleno : 0,
    printproduct: product.printproduct ? product.printproduct : "",
    printproductno: product.printproductno ? product.printproductno : "",
    shortproduct: product.shortproduct ? product.shortproduct : "",
    abcid: product.abcid ? product.abcid : 0,
    textid: product.textid ? product.textid : "",
    issncode: product.issncode ? product.issncode : 0,
    url: product.url ? product.url : "",
    imageid: product.imageid ? product.imageid : 0,
    textid: product.textid ? product.textid : 0,
    isusedaymatrix: product.isusedaymatrix ? true : false,
    pricingtype: product.pricingtype,
    descriptivetextid: product.descriptivetextid ? product.descriptivetextid : 0,

  };

  return Fetcher.post(url, payload)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function putProducts(product) {
  const url = apiUrl(`/product/${product.papercode}/${product.productno}`);

  let payload = {
    productno: product.productno,
    productname: product.productname,
    mo_act: product.mo_act,
    tu_act: product.tu_act,
    we_act: product.we_act,
    th_act: product.th_act,
    fr_act: product.fr_act,
    sa_act: product.sa_act,
    su_act: product.su_act,
    deltype: product.deltype ? product.deltype : "",
    bundleno: product.bundleno ? product.bundleno : 0,
    printproduct: product.printproduct ? product.printproduct : "",
    printproductno: product.printproductno ? product.printproductno : "",
    shortproduct: product.shortproduct ? product.shortproduct : "",
    abcid: product.abcid ? product.abcid : 0,
    textid: product.textid ? product.textid : "",
    issncode: product.issncode ? product.issncode : 0,
    url: product.url ? product.url : "",
    imageid: product.imageid ? product.imageid : 0,
    textid: product.textid ? product.textid : 0,
    isusedaymatrix: product.isusedaymatrix ? true : false,
    papercode: resolveFieldValueFromObject(product.papercode),
    periodic: resolveFieldValueFromObject(product.periodic),
    vatcode: resolveFieldValueFromObject(product.vatcode),
    producttype: resolveFieldValueFromObject(product.producttype),
    isspecial: product.isspecial ? true : false,
    isdefault: product.isdefault ? true : false,
    isissuecalendar: product.isissuecalendar ? true : false,
    pricingtype: product.pricingtype,
  };

  return Fetcher.put(url, payload)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function deleteProduct(product) {
  const url = apiUrl(`product/${product.papercode}/${product.productno}`);

  return Fetcher.delete(url)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function fetchDigiProductType(codegroupno, papercode) {
  const url = apiUrl(`parameters/${codegroupno}/${papercode}`);

  return fetchWithRetry("getJson", [url])
    .then((digiProductTypeOptions = []) => {
      return digiProductTypeOptions;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function fetchVatCodes() {
  const url = apiUrl(`prices/vat-types`);

  return fetchWithRetry("getJson", [url])
    .then((vatCodes = []) => {
      return vatCodes;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function fetchCreditCategory(codegroupno, papercode) {
  const url = apiUrl(`parameters/${codegroupno}/${papercode}`);

  return fetchWithRetry("getJson", [url])
    .then((creditCategoryOptions = []) => {
      return creditCategoryOptions;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}