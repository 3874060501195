import { removeNullValues, resolveFieldValueFromObject } from "@utils/common.js";
import { Fetcher, fetchWithRetry } from "@utils/fetcher.js";
import apiUrl from "@utils/url.js";

export async function fetchPriceRow(priceList) {
  const url = apiUrl(`pricerow/${priceList.pricelistno}/${priceList.subskind}`);

  return fetchWithRetry("getJson", [url])
    .then((priceRows = []) => {
      return priceRows;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function postPriceRow(priceRow) {
  const url = apiUrl(`pricerow`);

  let payload = {
    pricelistno: priceRow.pricelistno,
    subs_kind: priceRow.subskind,
    period_mm: priceRow.period_mm,
    period_amount: priceRow.periodamount,
    issue_qty: priceRow.issueqty,
    papercode: resolveFieldValueFromObject(priceRow.papercode),
    productno: resolveFieldValueFromObject(priceRow.productno),
    vatcode: priceRow.vatcode,
    circulation_id: priceRow.circulationid,
  };
  return Fetcher.post(url, payload)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function putPriceRow(priceRow) {
  const url = apiUrl(`/pricerow`);
  let payload = {
    pricelistno: priceRow.pricelistno,
    subs_kind: priceRow.subskind,
    period_mm: priceRow.period_mm,
    period_amount: priceRow.periodamount,
    issue_qty: priceRow.issueqty,
    papercode: resolveFieldValueFromObject(priceRow.papercode),
    productno: resolveFieldValueFromObject(priceRow.productno),
    vatcode: priceRow.vatcode ? priceRow.vatcode : "" ,
    circulation_id: priceRow.circulationid,
  };

  return Fetcher.put(url, payload)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function deletePriceRow(priceRow) {
  const url = apiUrl(
    `/pricerow/${priceRow.pricelistno}/${priceRow.papercode}/${priceRow.productno}/${priceRow.subskind}/${priceRow.period_mm}/${priceRow.issueqty}`);

  return Fetcher.delete(url)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}