import { notify } from "@app/notification/notification.action.js";
import * as effect from "./productTexts.effect.js";
import * as actionCreator from "@utils/actionCreator.js";
import { tr } from "@utils/translation.js";

const SLICE = "productTexts";

export const ADMIN_PRODUCT_IS_LOADING_PRODUCT_TEXTS = `${SLICE}/IS_LOADING_PRODUCT_TEXTS`;
export const ADMIN_PRODUCT_SET_ALL_PRODUCT_TEXTS = `${SLICE}/SET_ALL_PRODUCT_TEXTS`;
export const requestProductTextsById = (descriptiveTextId) => async (dispatch) => {
  dispatch(actionCreator.createAction(ADMIN_PRODUCT_IS_LOADING_PRODUCT_TEXTS));

  let response = await effect.fetchProductTextById(descriptiveTextId);
  if (response.error) {
    dispatch(actionCreator.createAction(ADMIN_PRODUCT_SET_ALL_PRODUCT_TEXTS, []));
    return;
  }

  dispatch(actionCreator.createAction(ADMIN_PRODUCT_SET_ALL_PRODUCT_TEXTS, response));
};

export const ADMIN_PRODUCT_PRODUCT_TEXTS_EDITED = `${SLICE}/PRODUCT_TEXTS_EDITED`;
export const editProductText = (product, callback = () => { }) => async (dispatch) => {
  
  let texts = [];
  Object.entries(product).forEach(([key, value], index) => {
    let _key=key.split("_")[1];
    if (key &&
      key.indexOf("descriptiveText") !== -1 &&
      product["descriptiveText_" +_key] !== product["oldDescriptiveText_" + _key]
    ) {
      texts.push({ key: _key, value: value });
    }
  })

  const promises = texts.map((text, index) => {
    let productText={
      textnumber : text.key,
      descriptiveText : text.value ? text.value : "",
      descriptiveTextId:product.descriptivetextid,
    }
    return effect.putProductText(productText);
  });
  await Promise.all(promises);
  return product.descriptivetextid;
};