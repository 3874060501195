import { postImage } from "@app/imageBank/imageBank.effect.js";
import { notify } from "@app/notification/notification.action.js";
import * as actionCreator from "@utils/actionCreator.js";
import { tr } from "@utils/translation.js";
import * as effect from "./campaign.effect.js";
import store from "@app/store.js";

const SLICE = "campaigns";

export const ADMIN_IS_LOADING_ALL_CAMPAIGNS = `${SLICE}/IS_LOADING_ALL_CAMPAIGNS`;
export const ADMIN_SET_ALL_COPRODUCTS = `${SLICE}/SET_ALL_COPRODUCTS`;
export const ADMIN_IS_LOADING_ALL_PRODUCT_PACKAGES = `${SLICE}/IS_LOADING_ALL_PRODUCT_PACKAGES`;
export const ADMIN_SET_ALL_CAMPAIGNS = `${SLICE}/SET_ALL_CAMPAIGNS`;
export const ADMIN_SET_CAMPAIGNS = `${SLICE}/SET_CAMPAIGNS`;
export const ADMIN_IS_LOADING_ALL_GOODS_AND_SERVICES = `${SLICE}/IS_LOADING_ALL_GOODS_AND_SERVICES`;
export const ADMIN_SET_ALL_GOODS_AND_SERVICES = `${SLICE}/SET_ALL_GOODS_AND_SERVICES`;
export const ADMIN_SET_ALL_CAMPAIGN_GROUPS = `${SLICE}/SET_ALL_CAMPAIGN_GROUPS`;
export const ADMIN_SET_ALL_PRODUCT_PACKAGES = `${SLICE}/SET_ALL_PRODUCT_PACKAGES`;
export const ADMIN_SET_ALL_PAPERS = `${SLICE}/SET_ALL_PAPERS`;
export const ADMIN_SET_SINGLE_PACKAGE_PRODUCT = `${SLICE}/SET_SINGLE_PACKAGE_PRODUCT`;
export const ADMIN_SET_SINGLE_CAMPAIGN = `${SLICE}/SET_SINGLE_CAMPAIGN`;
export const ADMIN_SET_SEARCH_CAMPAIGNS = `${SLICE}/SET_SEARCH_CAMPAIGNS`;
export const ADMIN_LOADING_SEARCH_CAMPAIGNS = `${SLICE}/LOADING_SEARCH_CAMPAIGNS`;
export const ADMIN_SET_REMOVING_CAMPAIGN= `${SLICE}/SET_REMOVING_CAMPAIGN`;
export const ADMIN_SET_REMOVING_CAMPAIGN_COPRODUCT= `${SLICE}/SET_REMOVING_CAMPAIGN_COPRODUCT`;
export const ADMIN_CAMPAIGN_REMOVED = `${SLICE}/CAMPAIGN_REMOVED`;
export const ADMIN_CAMPAIGN_COPRODUCT_REMOVED = `${SLICE}/CAMPAIGN_COPRODUCT_REMOVED`;
export const ADMIN_CLEAR_PREVIOUS_CAMPAIGNS = `${SLICE}/CLEAR_PREVIOUS_CAMPAIGNS`;

const returnResponse = (res, msg, callback = () => {}, dispatch = () => {}, campno = undefined, error) => {
  if (res.error || (res.status && res.status != 200) || (res.rest_status && res.rest_status != 200)) {
    let message;
    if(res.body){
      let body
      if(typeof res.body == "string"){
        body = JSON.parse(res.body)
        message = body.details ? tr("-", undefined, {id: body.details.split(":")[0], fallback: error}) : body.message;
      }
      else{
        message = body.details ? tr("-", undefined, {id: body.details.split(":")[0], fallback: error}) : body.message;
      }
    }
    dispatch(notify(message || error, "error"));
    return;
  }
  if((res && (res.status && res.status == 200)) || (typeof res == "string" && JSON.parse(res).response == "OK")){
    callback();
    dispatch(notify(tr(msg), "success"));
    if(msg == "campaignAdded" || msg == "campaignEdited"){
      if(store.getState().campaign.searchParams){
        dispatch(searchCampaign(store.getState().campaign.searchParams, true));
      }
      else{
        dispatch(requestAllCampaignsList(`?offset=0&limit=1000`))
      }
    }
    else{
      dispatch(requestAllGoodsAndServices(campno))
    }
    return
  }
}

export const requestAllCampaignsList = (params) => async (dispatch) => {
  dispatch(actionCreator.createAction(ADMIN_IS_LOADING_ALL_CAMPAIGNS));
  const response = await effect.fetchCampaignsList(params);
  if (response.error) {
    dispatch(actionCreator.createAction(ADMIN_SET_ALL_CAMPAIGNS, []));
    dispatch(notify(response.error ? response.error.message : "Fetching campaigns failed", "error"));
    return;
  }
  dispatch(actionCreator.createAction(ADMIN_SET_ALL_CAMPAIGNS, response));
};

export const requestSingleCampaign = (campno) => async (dispatch) => {
  
//   const response = await effect.fetchCampaignsList();
const response = await effect.fetchSingleCampaign(campno);
  if (response.error) {
    dispatch(notify("Fetching campaign failed", "error"));
    return;
  }
  dispatch(actionCreator.createAction(ADMIN_SET_SINGLE_CAMPAIGN, response));
};

export const ADMIN_IS_LOADING_ALL_PREVIOUS_CAMPAIGNS = `${SLICE}/IS_LOADING_ALL_PREVIOUS_CAMPAIGNS`;
export const ADMIN_SET_ALL_PREVIOUS_CAMPAIGNS = `${SLICE}/SET_ALL_PREVIOUS_CAMPAIGNS`;
export const requestAllPreviousCampaignsList = (campno) => async (dispatch) => {
  dispatch(actionCreator.createAction(ADMIN_IS_LOADING_ALL_PREVIOUS_CAMPAIGNS));
  
//   const response = await effect.fetchCampaignsList();
  const response = await effect.fetchPreviousCampaignsList(campno);
  if (response.error) {
    dispatch(actionCreator.createAction(ADMIN_SET_ALL_PREVIOUS_CAMPAIGNS, []));
    let message = response.error.error || response.error.message || response.error.data[0].removecampaign[0].serrormessage;
    // dispatch(message, "error");
    return;
  }
  dispatch(actionCreator.createAction(ADMIN_SET_ALL_PREVIOUS_CAMPAIGNS, response));
};
export const requestAllCoproducts = () => async (dispatch) => {
  // dispatch(actionCreator.createAction(ADMIN_IS_LOADING_ALL_PREVIOUS_CAMPAIGNS));
  
//   const response = await effect.fetchCampaignsList();
  const response = await effect.fetchCoproducts();
  if (response.error) {
    dispatch(actionCreator.createAction(ADMIN_SET_ALL_COPRODUCTS, []));
    // let message = response.error.error || response.error.message || response.error.data[0].removecampaign[0].serrormessage;
    // dispatch(message, "error");
    return;
  }
  dispatch(actionCreator.createAction(ADMIN_SET_ALL_COPRODUCTS, response));
};

export const addCampaign = (campaign, callback = () => { }) => async (dispatch) => {
  const response = await effect.createCampaign(campaign);
  returnResponse(response, "campaignAdded", callback, dispatch, "Create campaign failed")
};

export const addCampaignCoproduct = (campaigncoproduct, campno, callback = () => { }) => async (dispatch) => {
  const response = await effect.createCampaignCoproduct(campaigncoproduct, campno); 
  returnResponse(response, "campaignCoproductAdded", callback, dispatch, campno, "Create campaign co product failed")
};

export const ADMIN_CAMPAIGN_EDITED = `${SLICE}/CAMPAIGN_EDITED`;
export const editCampaign = (campaign, callback = () => { }) => async (dispatch) => {

  const response = await effect.editCampaign(campaign);
  returnResponse(response, "campaignEdited", callback, dispatch, "Update campaign failed")
  // if (response.error || (response.data[0] && response.data[0].updatecampaign[0] && response.data[0].updatecampaign[0] && response.data[0].updatecampaign[0].serrormessage)) {
  //   let message = response.data[0].updatecampaign[0].serrormessage;
  //   dispatch(notify(message || "Internal Server Error", "error"));
  //   return;
  // }
  // callback();
  // dispatch(notify(tr("campaignEdited"), "success"));
  // dispatch(actionCreator.createAction(ADMIN_CAMPAIGN_EDITED, campaign));
  // dispatch(searchCampaign(store.getState().campaign.searchParams, true));
};

export const ADMIN_CAMPAIGN_COPRODUCT_EDITED = `${SLICE}/CAMPAIGN_COPRODUCT_EDITED`;
export const editCampaignCoproduct = (campaign, campno, callback = () => { }) => async (dispatch) => {

  const response = await effect.editCampaignCoproduct(campaign, campno);
  if (response.error) {
    dispatch(notify("Update campaign co product failed", "error"));
    return;
  }
  callback();
  dispatch(notify(tr("campaignEdited"), "success"));
  dispatch(requestAllGoodsAndServices(campno))
  dispatch(actionCreator.createAction(ADMIN_CAMPAIGN_COPRODUCT_EDITED, campaign));

};

export const SELECT_CAMPAIGN_ROW = `${SLICE}/SELECT_CAMPAIGN_ROW`;
export const selectCampaignRow = (row) => async (dispatch) => {
  dispatch(actionCreator.createAction(SELECT_CAMPAIGN_ROW,row));
};

export const TOGGLE_ADD_EDIT_CAMPAIGN_MODAL = `${SLICE}/TOGGLE_ADD_EDIT_CAMPAIGN_MODAL`;

export const toggleCampaignModal = (isOpen) => async (dispatch) => {
  dispatch(actionCreator.createAction(TOGGLE_ADD_EDIT_CAMPAIGN_MODAL,isOpen));
};
export const TOGGLE_ADD_EDIT_GOODS_AND_SERVICES = `${SLICE}/TOGGLE_ADD_EDIT_GOODS_AND_SERVICES`;

export const toggleGoodsServicesModal = (isOpen) => async (dispatch) => {
  dispatch(actionCreator.createAction(TOGGLE_ADD_EDIT_GOODS_AND_SERVICES,isOpen));
};

export const requestAllGoodsAndServices = (campid) => async (dispatch) => {
  
  dispatch(actionCreator.createAction(ADMIN_IS_LOADING_ALL_GOODS_AND_SERVICES));
  
//   const response = await effect.fetchCampaignsList();
  const response = await effect.fetchGoodsServicesList(campid)
  if (response.error) {
    dispatch(actionCreator.createAction(ADMIN_SET_ALL_GOODS_AND_SERVICES, []));
    // dispatch(notify(response.error ? response.error.message : "Internal Server Error", "error"));
    return;
  }
  dispatch(actionCreator.createAction(ADMIN_SET_ALL_GOODS_AND_SERVICES, response));
};
export const requestAllCampaignGroups = () => async (dispatch) => {
  
  
  const response = await effect.fetchCampaignsGroups();
  if (response.error) {
    dispatch(actionCreator.createAction(ADMIN_SET_ALL_CAMPAIGN_GROUPS, []));
    // dispatch(notify(response.error ? response.error.message : "Internal Server Error", "error"));
    return;
  }
  dispatch(actionCreator.createAction(ADMIN_SET_ALL_CAMPAIGN_GROUPS, response));
};
export const requestAllProductPackages = () => async (dispatch) => {
  dispatch(actionCreator.createAction(ADMIN_IS_LOADING_ALL_PRODUCT_PACKAGES));
  
  const response = await effect.fetchProductPackages();
  if (response.error) {
    dispatch(actionCreator.createAction(ADMIN_SET_ALL_PRODUCT_PACKAGES, []));
    dispatch(notify("Fetching product packages failed", "error"));
    return;
  }
  dispatch(actionCreator.createAction(ADMIN_SET_ALL_PRODUCT_PACKAGES, response));
};
export const requestAllPapers = () => async (dispatch) => {
  
  
  const response = await effect.fetchAllPapers();
  if (response.error) {
    dispatch(actionCreator.createAction(ADMIN_SET_ALL_PAPERS, []));
    dispatch(notify("Fetching papers failed", "error"));
    return;
  }
  dispatch(actionCreator.createAction(ADMIN_SET_ALL_PAPERS, response));
};
export const requestPackageProduct = (id) => async (dispatch) => {
  
  
  const response = await effect.fetchSinglePackageProduct(id);
  if (response.error) {
    dispatch(actionCreator.createAction(ADMIN_SET_SINGLE_PACKAGE_PRODUCT, []));
    dispatch(notify("Fetching package product failed", "error"));
    return;
  }
  dispatch(actionCreator.createAction(ADMIN_SET_SINGLE_PACKAGE_PRODUCT, response));
};

export const searchCampaign = (params, list) => async (dispatch) => {
  if(list){
    dispatch(actionCreator.createAction(ADMIN_LOADING_SEARCH_CAMPAIGNS));
  }

  const response = await effect.searchCampaign(params);
  if (response.error) {
    if(list){
      dispatch(actionCreator.createAction(ADMIN_SET_SEARCH_CAMPAIGNS, []));
    }
    else{
      dispatch(actionCreator.createAction(ADMIN_SET_CAMPAIGNS, []));
    }
    dispatch(notify("Searching campaigns failed", "error"));
    return;
  }
  if(list){
    dispatch(actionCreator.createAction(ADMIN_SET_SEARCH_CAMPAIGNS, [...response, {params}]));
  }
  else{
    dispatch(actionCreator.createAction(ADMIN_SET_CAMPAIGNS, response));
  }
};

export const emptyPreviousCampaigns = () => async (dispatch) => {
  
  dispatch(actionCreator.createAction(ADMIN_CLEAR_PREVIOUS_CAMPAIGNS));
};


export const removeCampaign = (campno, callback = () => { }) => async (dispatch) => {
  dispatch(actionCreator.createAction(ADMIN_SET_REMOVING_CAMPAIGN, true));

  const response = await effect.deleteCampaign(campno);
  if (response.error) {
    let message = response.error.error || response.error.data[0].removecampaign[0].serrormessage;
    dispatch(notify(message, "error"));
    dispatch(actionCreator.createAction(ADMIN_SET_REMOVING_CAMPAIGN, false));
    return;
  }
  if(response.status == 200){
    callback();
    dispatch(notify(tr("campaignRemoved"), "warning"));
    dispatch(actionCreator.createAction(ADMIN_SET_REMOVING_CAMPAIGN, false));
    dispatch(actionCreator.createAction(ADMIN_CAMPAIGN_REMOVED, campno));
    dispatch(requestAllCampaignsList(`?offset=0&limit=1000`));
  }

};

export const removeCampaignCoproduct = (campno, papercode, productcode, callback = () => { }) => async (dispatch) => {
  dispatch(actionCreator.createAction(ADMIN_SET_REMOVING_CAMPAIGN_COPRODUCT, true));

  const response = await effect.deleteCampaignCoproduct(campno, papercode, productcode);
  if (response.error) {
    let message = response.error.error || response.error.data[0].removecampaign[0].serrormessage;
    dispatch(notify(message, "error"));
    dispatch(actionCreator.createAction(ADMIN_SET_REMOVING_CAMPAIGN_COPRODUCT, false));
    return;
  }
  if(response.status == 200){
    callback();
    dispatch(notify(tr("campaignCoprodRemoved"), "warning"));
    dispatch(actionCreator.createAction(ADMIN_SET_REMOVING_CAMPAIGN_COPRODUCT, false));
    dispatch(actionCreator.createAction(ADMIN_CAMPAIGN_COPRODUCT_REMOVED, campno));
    dispatch(requestAllGoodsAndServices(campno));
  }

};