import { Fetcher, fetchWithRetry } from "@utils/fetcher.js";
import apiUrl from "@utils/url.js";

export async function fetchAllReportsList() {
  const url = apiUrl("reports");
  const payload = {
  };

  return Fetcher.get(url)
    .then((reportsList = []) => {
      return reportsList;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function fetchAllReportsReqList() {
  const url = apiUrl("reports/findreprequest");
  const payload = {
  };

  return Fetcher.post(url, payload)
    .then((reportsReqList = []) => {
      return reportsReqList;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function deleteReport(report) {
  const url = apiUrl(`reports/${report.repno}`);
  const payload = {
    paramkey: report.paramkey,
    reprequestkey: Number(report.reprequest_key),
  };
  return Fetcher.delete(url,payload)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function putReport(report) {
  const url = apiUrl(`reports/${report.repno}`);
  const payload = {
    startdate: report.startdate,
    paramkey: report.paramkey,
    reprequestkey: report.reprequest_key,
    nextrun: report.nextrun,
    reprequest1: report.reprequest1,
    reprequest2: report.reprequest2,
    reprequest3: report.reprequest3,
    runon_mo:report.runon_mo,
    runon_tu:report.runon_tu,
    runon_we:report.runon_we,
    runon_th:report.runon_th,
    runon_fr:report.runon_fr,
    runon_sa:report.runon_sa,
    runon_su:report.runon_su,
    shiftdates:report.shiftDates,
    runtime_start:report.runtime_start,
    runtime_end:report.runtime_end,
    email:"",
    outformat:"" ,
  
  };

  return Fetcher.put(url, payload)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}


export async function postReport(report) {
  const url = apiUrl(`reports`);
  const payload = {
    repno:report.repno,
    startdate: report.startdate,
    paramkey: "",
    nextrun: report.nextrun,
    reprequest1: report.reprequest1,
    reprequest2: report.reprequest2,
    reprequest3: report.reprequest3,
    runon_mo:report.runon_mo,
    runon_tu:report.runon_tu,
    runon_we:report.runon_we,
    runon_th:report.runon_th,
    runon_fr:report.runon_fr,
    runon_sa:report.runon_sa,
    runon_su:report.runon_su,
    shiftdates:report.shiftdates,
    runtime_start:report.runtime_start,
    runtime_end:report.runtime_end,
    email:report.email,
    outformat:report.outformat && report.outformat.value ? report.outformat.value :"" ,
  };

  return Fetcher.post(url, payload)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function fetchPaperTranslations() {
  const url = apiUrl("translations/textno/52:3099:30484",false);
  return Fetcher.get(url)
    .then((reportsList = []) => {
      return reportsList;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}













