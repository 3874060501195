import { Fetcher, fetchWithRetry } from "@utils/fetcher.js";
import apiUrl from "@utils/url.js";

export async function fetchProductTextById(id) {
  const url = apiUrl(`producttexts/${id}`);
  
  return fetchWithRetry("getJson", [url])
    .then((productTexts = []) => {
      return productTexts;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function postProductText(descriptiveTextNo, descriptivetext, descriptivetextid) {
  let productText = {
    textnumber: descriptiveTextNo,
    descriptivetext: descriptivetext ? descriptivetext : "",
  }
  if (descriptivetextid) {
    productText.descriptivetextid = descriptivetextid;
  }
  const url = apiUrl(`producttexts`);
  return Fetcher.post(url, productText)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function putProductText(productText) {
  const url = apiUrl(`producttexts/${productText.descriptiveTextId}/${productText.textnumber}`);

  let payload = {
    descriptivetext: productText.descriptiveText ? productText.descriptiveText : "",
  }

  return Fetcher.put(url, payload)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

