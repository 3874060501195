import * as actions from "./productTexts.action.js";

const defaultState = {
  productTexts:[],
  productTextsLoading:false,
};

const adminReducer = (state = defaultState, action) => {
  const { type, payload } = action;

  switch (type) {
    case actions.ADMIN_PRODUCT_IS_LOADING_PRODUCT_TEXTS: {
      return { ...state, productTexts: [], productTextsLoading: true };
    }
    case actions.ADMIN_PRODUCT_SET_ALL_PRODUCT_TEXTS: {
      return { ...state, productTexts: payload, productTextsLoading: false };
    }
    default:
      return state;
  }
};

export default adminReducer;
