import * as actions from "../actions/actionTypes.js";

const defaultState = {
  deliveryInformation: [{}, {}, {}, {}, {}, {}, {}], // Each object represents weekday
  selectedWeekday: {},
  displayAddressConfirmModal: false,
  eventPoolData: [],
  addressFocusData: [],
  pendingFocusChanges: [],
  focusCheck: {
    active: false,
    subsno: 0,
    extno: 0,
    doorno: 0,
    paperCode: "",
    addressType: "", // Build 3.6.4.2 (ERP #12382)
  },
  changeFocus: {
    modalOpen: false,
    subsno: 0,
    extno: 0,
    doorno: 0,
    focusno: 0,
    addressType: "", // Build 3.6.4.2 (ERP #12382)
  },
  countries: [],
  deliveryChanges: [],
  customerAddresses: [],
  customerAllAddresses: [],
  deliveryAddress: {},
  doorDeliveries: [],
  servicedDoorDeliveries: [],
  selectedDeliveryToDoorAddress: {},
  deliveryToDoorType: undefined,
  labelMachines: [],
  shouldReloadDeliveryInformation: false,
  messageOpen: false, // Build 3.6.3.4 (Issue #31348)
  messages: [], // Build 3.6.3.4 (Issue #31348)
  messageTitle: "warning",
  addressNotInDIOpen: false, // #33092
  basicAddressExists: true, // #33092
  holidayAddressExists: true, // #33092
  splitAddressExists: true, // #33092
  invoicingAddressExists: true, // #33092
  customerAddressExists: true, // #33092
  basicAddressCheckComplete: true, // #33092
  holidayAddressCheckComplete: true, // #33092
  splitAddressCheckComplete: true, // #33092
  invoicingAddressCheckComplete: true, // #33092
  customerAddressCheckComplete: true, // #33092
  emptyAddresses: [], // #10159
};

const addressReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actions.SHOULD_DISPLAY_ADDRESS_CONFIRM_MODAL: {
      return { ...state, displayAddressConfirmModal: action.flag };
    }

    case actions.LOAD_DELIVERY_INFORMATION: {
      return { ...state, deliveryInformation: action.deliveryInformation };
    }

    case actions.LOAD_EMPTY_DELIVERY_INFORMATION: {
      return { ...state, deliveryInformation: defaultState.deliveryInformation };
    }

    case actions.SET_SELECTED_WEEKDAY: {
      return { ...state, selectedWeekday: action.weekday };
    }

    case actions.FOCUS_CHECK: {
      const focusCheck = {
        ...state.focusCheck,
        active: !state.focusCheck.active,
        subsno: action.subsno,
        extno: action.extno,
        doorno: action.doorno,
        paperCode: action.paperCode,
        addressType: action.addressType, // Build 3.6.4.2 (ERP #12382)
      };
      return { ...state, focusCheck };
    }

    case actions.SHOW_CHANGE_FOCUS_MODAL: {
      const changeFocus = {
        ...state.changeFocus,
        modalOpen: true,
        doorno: action.doorno,
        subsno: action.subsno,
        extno: action.extno,
        addressType: action.addressType, // Build 3.6.4.2 (ERP #12382)
      };
      return { ...state, changeFocus };
    }

    // Also resets the change focus modal's state
    case actions.CLOSE_CHANGE_FOCUS_MODAL: {
      const changeFocus = { ...defaultState.changeFocus, focusno: action.focusno };
      return { ...state, changeFocus };
    }

    case actions.LOAD_EVENT_POOL_DATA: {
      const eventPoolDataExpanded = [...state.eventPoolData, ...action.eventPoolData];
      return { ...state, eventPoolData: eventPoolDataExpanded };
    }

    case actions.RESET_EVENT_POOL_DATA: {
      return { ...state, eventPoolData: defaultState.eventPoolData };
    }

    case actions.LOAD_COUNTRIES: {
      return { ...state, countries: action.countries };
    }

    case actions.LOAD_EMPTY_ADDRESSES: {
      return { ...state, emptyAddresses: action.emptyAddresses };
    }

    case actions.LOAD_DELIVERY_CHANGES: {
      return { ...state, deliveryChanges: action.deliveryChanges };
    }

    case actions.LOAD_CUSTOMER_ADDRESSES: {
      return { ...state, customerAddresses: action.customerAddresses };
    }

    case actions.LOAD_CUSTOMER_ALL_ADDRESSES: {
      return { ...state, customerAllAddresses: action.allAddresses };
    }

    case actions.LOAD_DELIVERY_ADDRESS: {
      return { ...state, deliveryAddress: action.deliveryAddress };
    }

    case actions.LOAD_DOOR_DELIVERIES: {
      return { ...state, doorDeliveries: action.doorDeliveries };
    }

    case actions.LOAD_SERVICED_DOOR_DELIVERIES: {
      return { ...state, servicedDoorDeliveries: action.servicedDoorDeliveries };
    }

    case actions.ADD_ADDRESS_TO_SERVICED_DOOR_DELIVERIES: {
      // Check if address doesn't alreadt exist in servicedDoorDeliveries and add it if not found
      let exists = false;
      state.servicedDoorDeliveries.forEach((address) => {
        if (address.doorno === action.address.doorno) {
          exists = true;
        }
      });

      if (exists) {
        return { ...state };
      } else {
        const servicedDoorDeliveries = [
          ...state.servicedDoorDeliveries,
          {
            ...action.address,
            street1: action.address.streetname,
          },
        ];
        return { ...state, servicedDoorDeliveries: servicedDoorDeliveries };
      }
    }

    case actions.SELECT_DELIVERY_TO_DOOR_ADDRESS: {
      return { ...state, selectedDeliveryToDoorAddress: action.address };
    }

    case actions.DELETE_SELECTED_DELIVERY_TO_DOOR_ADDRESS: {
      // eslint-disable-next-line array-callback-return
      const newServicedDoorDeliveries = state.servicedDoorDeliveries.filter((address) => {
        if (address.doorno !== state.selectedDeliveryToDoorAddress.doorno) {
          return address;
        }
      });
      return {
        ...state,
        servicedDoorDeliveries: newServicedDoorDeliveries,
        selectedDeliveryToDoorAddress: defaultState.selectedDeliveryToDoorAddress,
      };
    }

    case actions.SET_DELIVERY_TO_DOOR_TYPE: {
      return { ...state, deliveryToDoorType: action.deliveryToDoorType };
    }

    case actions.LOAD_LABEL_MACHINES: {
      return { ...state, labelMachines: action.labelMachines };
    }

    case actions.LOAD_ADDRESS_FOCUS_DATA: {
      return { ...state, addressFocusData: action.focusData };
    }

    case actions.LOAD_PENDING_FOCUS_CHANGES: {
      return { ...state, pendingFocusChanges: action.focusData };
    }

    case actions.SHOULD_RELOAD_DELIVERY_INFORMATION: {
      return { ...state, shouldReloadDeliveryInformation: action.flag };
    }

    // Build 3.6.3.4 (Issue #31348)
    case actions.SET_MESSAGE_OPEN: {
      return {
        ...state,
        messageOpen: action.flag,
        messages: action.messages,
        messageTitle: action.messageTitle,
      };
    }

    // #33092 ...
    case actions.SET_ADDRESS_NOT_IN_DI_OPEN: {
      return { ...state, addressNotInDIOpen: action.flag };
    }

    case actions.SET_BASIC_ADDRESS_EXITS: {
      return { ...state, basicAddressExists: action.flag };
    }

    case actions.SET_HOLIDAY_ADDRESS_EXITS: {
      return { ...state, holidayAddressExists: action.flag };
    }

    case actions.SET_SPLIT_ADDRESS_EXITS: {
      return { ...state, splitAddressExists: action.flag };
    }

    case actions.SET_INVOICING_ADDRESS_EXITS: {
      return { ...state, invoicingAddressExists: action.flag };
    }

    case actions.SET_CUSTOMER_ADDRESS_EXITS: {
      return { ...state, customerAddressExists: action.flag };
    }

    case actions.SET_BASIC_ADDRESS_CHECK_COMPLETE: {
      return { ...state, basicAddressCheckComplete: action.flag };
    }

    case actions.SET_HOLIDAY_ADDRESS_CHECK_COMPLETE: {
      return { ...state, holidayAddressCheckComplete: action.flag };
    }

    case actions.SET_SPLIT_ADDRESS_CHECK_COMPLETE: {
      return { ...state, splitAddressCheckComplete: action.flag };
    }

    case actions.SET_INVOICING_ADDRESS_CHECK_COMPLETE: {
      return { ...state, invoicingAddressCheckComplete: action.flag };
    }

    case actions.SET_CUSTOMER_ADDRESS_CHECK_COMPLETE: {
      return { ...state, customerAddressCheckComplete: action.flag };
    }
    // ... #33092

    default:
      return state;
  }
};

export default addressReducer;
