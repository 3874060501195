import { resolveFieldValueFromObject } from "@utils/common.js";
import { Fetcher, fetchWithRetry } from "@utils/fetcher.js";
import { year1800ToMoment, year2078toMoment } from "@utils/locale.js";
import apiUrl from "@utils/url.js";

export async function fetchPriceList() {
  const url = apiUrl(`pricelist`);

  return fetchWithRetry("getJson", [url])
    .then((priceList = []) => {
      return priceList;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function postPriceList(priceList) {
  const url = apiUrl(`pricelist`);

  let payload = {
    packageid: resolveFieldValueFromObject(priceList.packageid),
    pricegroup: resolveFieldValueFromObject(priceList.pricegroup),
    pricingtype: resolveFieldValueFromObject(priceList.pricingtype),
    stand_itemqty: priceList.stand_itemqty,
    per_itemqty: priceList.per_itemqty,
    pricestartdate: priceList.pricestartdate ? priceList.pricestartdate : year1800ToMoment(),
    priceenddate: priceList.priceenddate ? priceList.priceenddate : year2078toMoment(),
    vatincluded: priceList.vatincluded,
  };
  
  return Fetcher.post(url, payload)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function putPriceList(priceList) {
  const url = apiUrl(`/pricelist`);
  let payload = {
    pricelistno: priceList.pricelistno,
    papercode: resolveFieldValueFromObject(priceList.papercode),
    packageid: resolveFieldValueFromObject(priceList.packageid),
    pricegroup: resolveFieldValueFromObject(priceList.pricegroup),
    pricingtype: resolveFieldValueFromObject(priceList.pricingtype),
    stand_itemqty: priceList.stand_itemqty,
    per_itemqty: priceList.per_itemqty,
    pricestartdate: priceList.pricestartdate,
    priceenddate: priceList.priceenddate,
    vatincluded: priceList.vatincluded,
  };

  return Fetcher.put(url, payload)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function deletePriceList(priceList) {
  const url = apiUrl(`/pricelist/${priceList.pricelistno}`);

  return Fetcher.delete(url)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}