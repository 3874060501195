import moment from "moment";

import store from "@app/store.js";
import {
  momentToDateString,
  parseDate,
  parseDateTime,
  parseString,
  year1800ToMoment,
  year2078toMoment
} from "./locale.js";
import { tr } from "./translation.js";
import { formatCurrency } from "./validate.js";

/*
 * Generic functions to convert Kayak's numeric strings to human readable strings
 */

export const dateFormatter = (value) => {
  if (value) {
    return parseDate(value);
  } else {
    return "";
  }
};

export const dateWithoutMinDate = (value) => {
  if (value && moment(value).isAfter(year1800ToMoment())) {
    return parseDate(value);
  } else {
    return "";
  }
};

export const dateWithoutMaxDate = (value) => {
  if (value && moment(value).isBefore(year2078toMoment())) {
    return parseDate(value);
  } else {
    return "";
  }
};

export const dateTimeFormatter = (value) => {
  if (value) {
    return parseDateTime(value);
  } else {
    return "";
  }
};

export const dateTimeWithoutMinDate = (value) => {
  if (value && moment(value).isAfter(year1800ToMoment())) {
    return parseDateTime(value);
  } else {
    return "";
  }
};

export const dateTimeWithoutMaxDate = (value) => {
  let date = value && (value.indexOf("000") === -1 ? value + ".000Z" : value);
  if (date && moment(date).isBefore(year2078toMoment())) {
    return parseDateTime(value);
  } else {
    return "";
  }
};

export const numberWithoutZero = (value) => {
  if (value && value > 0) {
    return value;
  } else {
    return null;
  }
};

export const stringWithoutZero = (value) => {
  if (value && value !== "0") {
    return value;
  } else {
    return null;
  }
};

export const customerStateFormatter = (type) => {
  switch (type) {
    case "00":
      return tr("potential");
    case "01":
      return tr("active");
    case "03":
      return tr("passive");
    case "04":
      return tr("mergedHidden");
    default:
      return "";
  }
};

export const reportStateFormatter = (type) => {
  switch (type) {
    case "A":
      return tr("request");
    case "R":
      return tr("finished");
    case "00":
      return tr("running");
    case "F":
      return tr("failed");
    case "P":
      return tr("pause");
    default:
      return "";
  }
};

export const customerTypeFormatter = (type) => {
  switch (type) {
    case "01":
      return tr("person");
    case "02":
      return tr("company");
    case "03":
      return tr("association");
    case "07":
      return tr("education");
    case "08":
      return tr("internal");
    default:
      return "";
  }
};

export const balanceFormatter = (type) => {
  switch (type) {
    case "0":
      return tr("customerHasUnpaidSubscriptions");
    case "1":
      return tr("customerHasOpenInvoices");
    case "2":
      return tr("paymentsAreEven");
    default:
      return "";
  }
};

export const subscriptionTypeFormatter = (type) => {
  switch (type) {
    // Standing (automatic renewal)
    case "01":
      return tr("standing");
    // Periodic
    case "02":
      return tr("periodic");
    // Single copy subscription
    case "03":
      return tr("singleCopy");
    // Advertiser's check copy
    case "04":
      return tr("controlCopy");
    // Employee's free copy
    case "05":
      return tr("freeCopy");
    // Sample copy
    case "06":
      return tr("sample");
    default:
      return "";
  }
};

/**
 * Rough formatter for subscription state. Doesn't consider cases e.g. when subscription has ended unpaid.
 * Use more powerful subscriptionStateFormatterWithSub which tries to consider various edge cases.
 */
export const subscriptionStateFormatter = (state) => {
  switch (state) {
    // '00', Waiting to become active
    case "00":
      return tr("waitingToActivate");
    // '01', Active
    case "01":
      return tr("activeSubs");
    // '02', Sleeping
    case "02":
      return tr("suspended");
    // '03', Ended
    case "03":
      return tr("ended");
    // '04', Ended without payment - invoice sent
    case "04":
      return tr("unpaidInvoiced");
    // '05', Cancelled
    case "05":
      return tr("cancelled");
    // '06', Ended unpaid - now paid
    case "06":
      return tr("debtPaid");
    // '20', Waiting to become active
    case "20":
      return tr("restarted");
    // '30', Waiting to become active
    case "30":
      return tr("waitingToActivate");
    // '40', Extension - waiting to be paid
    case "40":
      return tr("extensionUnpaid");
  }
};

/**
 * Formatter for subscription state. Uses subscriptionStateFormatter and comparison logic in order to display
 * correct subscription state.
 * @param {object} sub
 */
export const subscriptionStateFormatterWithSub = (sub) => {
  // Unfortunately simply using subsstate is not enough in order to print correct subsstate
  // to user. Instead old kayak has complex comparison logic build in which has to be used here.
  switch (sub.subsstate) {
    case "03":
      // If sub has unpbreakdate set
      if (moment(sub.unpbreakdate).isAfter(year1800ToMoment())) {
        return tr("unpaidEnded");
      }
      // If sub has extension
      else if (sub.extsubsexists === "Y") {
        return tr("renewed");
      } else {
        return subscriptionStateFormatter(sub.subsstate);
      }
    default:
      return subscriptionStateFormatter(sub.subsstate);
  }
};

/**
 * Sorts subscriptions based on their desired state.
 */
export const subscriptionStateSort = (subscriptions) => {
  const states00 = [];
  const states01 = [];
  const states02 = [];
  const states03 = [];
  const states04 = [];
  const states05 = [];
  const states06 = [];
  const states20 = [];
  const states30 = [];
  const states40 = [];
  const rest = [];

  subscriptions.forEach((sub) => {
    switch (sub.subsstate) {
      case "00":
        states00.push(sub);
        break;
      case "01":
        states01.push(sub);
        break;
      case "02":
        states02.push(sub);
        break;
      case "03":
        states03.push(sub);
        break;
      case "04":
        states04.push(sub);
        break;
      case "05":
        states05.push(sub);
        break;
      case "06":
        states06.push(sub);
        break;
      case "20":
        states20.push(sub);
        break;
      case "30":
        states30.push(sub);
        break;
      case "40":
        states40.push(sub);
        break;
      default:
        rest.push(sub);
        break;
    }
  });

  return [].concat(
    states01,
    states00,
    states40,
    states02,
    states03,
    states04,
    states05,
    states06,
    states20,
    states30,
    rest
  );
};

export const feedbackTypeFormatter = (type) => {
  switch (type) {
    case "0":
      return tr("negative");
    case "1":
      return tr("positive");
    case "2":
      return tr("neutral");
    case "3":
      return tr("hint");
    default:
      return "";
  }
};

export const yesNoFormatter = (value) => {
  switch (value) {
    case "N":
      return tr("no");
    case "Y":
      return tr("yes");
    case false:
      return tr("no");
    case true:
      return tr("yes");
    case "W":
      return tr("no"); // No case in Ledger advance payment
    default:
      return "";
  }
};

/** `price_at_start` found in invoice arguments has inverse yes/no: 'N' means 'Yes' and 'Y means 'No'. */
export const yesNoFormatterInvert = (value) => {
  switch (value) {
    case "N":
      return tr("yes");
    case "Y":
      return tr("no");
    default:
      return "";
  }
};

export const tempAddressStateFormatter = (state) => {
  switch (state) {
    case "00":
      return tr("addressStatePending");
    case "01":
      return tr("addressStateActive");
    case "02":
      return tr("addressStatePassive");
    case "03":
      return tr("addressStateEnded");
    default:
      return "";
  }
};

export const customerContactTypeFormatter = (value) => {
  switch (value) {
    // Normal - just save to the database
    case "01":
      return tr("normal");
    // Email - send email to the customer
    case "02":
      return tr("email");
    // SMS - send sms to the customer
    case "03":
      return tr("sms");
    // Mail - send snail mail to the customer
    case "04":
      return tr("mail");
    case "51":
      return tr("contactTypeCarma");
    case "52":
      return tr("contactTypeServicePlus");
    case "53":
      return tr("contactTypeAdobe");
    case "54":
      return tr("contactTypeApsis");
    case "55":
      return tr("contactTypeNeolane");
    default:
      return "";
  }
};

export const creditTypeFormatter = (value) => {
  switch (value) {
    case "01":
      return tr("countingTime");
    case "02":
      return tr("countingMoney");
    case "03":
      return tr("noCredit");
    default:
      return "";
  }
};

export const creditStatusFormatter = (value) => {
  switch (value) {
    case "Y":
      return tr("credited");
    case "N":
      return tr("notCredited");
    case "P":
      return tr("creditPrinted");
    default:
      return "";
  }
};

export const sleepTypeFormatter = (value) => {
  switch (value) {
    case "01":
      return tr("sleep");
    case "02":
      return tr("otherCrediting");
    case "03":
      return tr("donation");
    default:
      return "";
  }
};

export const feedbackStatusFormatter = (value) => {
  switch (value) {
    case "N":
      return tr("notHandled");
    case "Y":
      return tr("closed");
    case "U":
      return tr("noHandling");
    default:
      return "";
  }
};

export const deliveryTypeFormatter = (value) => {
  switch (value) {
    case "01":
      return tr("addressed");
    case "02":
      return tr("unaddressed");
    case "03":
      return tr("extra");
    case "04":
      return tr("singleCopies");
    default:
      return "";
  }
};

export const paymentStateFormatter = (value) => {
  switch (value) {
    case "00":
      return tr("open");
    case "01":
      return tr("partlyPaid");
    case "02":
      return tr("paid");
    case "03":
      return tr("reminder");
    case "04":
      return tr("unpaidCancelled");
    case "05":
      return tr("unpaidEnded");
    case "06":
      return tr("credited");
    case "07":
      return tr("movedToCreditLosses");
    default:
      return "";
  }
};

export const currencyFormatter = (value) => {
  if (value || value == 0) {
    return formatCurrency(value);
  } else {
    return "";
  }
};

export const invoiceItemTypeFormatter = (value) => {
  switch (value) {
    case "SUBS":
      return tr("subscription");
    case "SUBS_EXTRA_ITEMS":
      return tr("extraCharge");
    case "SUBSSLEEP":
      return tr("sleep");
    case "CREDITINVOICE":
      return tr("creditInvoice");
    default:
      return "";
  }
};

export const invoiceTypeFormatter = (value) => {
  const INVTYPE = store.getState().parameter.INVTYPE.ALL || {};

  return INVTYPE[value] || "";
};

export const invoiceClassFormatter = (value) => {
  const INVCLASS = store.getState().parameter.INVCLASS.ALL || {};

  return INVCLASS[value] || "";
};

export const paymentTypeFormatter = (ledgerLog) => {
  let txtType = "";

  switch (ledgerLog.type_code) {
    case "00":
      txtType = tr("payment"); // Manual payment
      break;
    case "01":
      if (store.getState().parameter.SYS_OPTION.ALL.codevalue === "S") {
        txtType = ledgerLog.officeno ? ledgerLog.officeno : "";
      } else {
        txtType = tr("electronicPayment");
      }
      break;
    case "02":
      txtType = tr("internalTransfer"); // Transferred payment
      break;
    case "03":
      txtType = tr("usedAdvancePayment"); // Advance payment
      break;
    default:
      txtType = tr("payment"); // Act like manual
  }

  if (ledgerLog.class === "02") {
    txtType = txtType + ", " + tr("expenses"); // Reference payment + expenses
  } else if (ledgerLog.class === "05") {
    txtType = txtType + ", " + tr("interest"); // Reference payment + interest
  } else if (ledgerLog.class === "06") {
    txtType = txtType + ", " + tr("cancellingFee"); // Reference payment  + cancelling fee
  }
  return txtType;
};

export const paymentClassFormatter = (value) => {
  const PAYMCODE = store.getState().parameter.PAYMCODE.ALL || {};

  return PAYMCODE[value] || "";
};

export const ledgerLogTypeFormatter = (log) => {
  // Displaying right category (type) depends what is currently selected transaction
  switch (log.eventtype_code) {
    case "PAYMENT":
      return paymentTypeFormatter(log);
    case "REPAYMENT":
      return log.type; // TODO, use code and translation once logic is known how to set correct translation
    case "INVOICE":
      return invoiceTypeFormatter(log.type_code);
    case "CREDIT":
      return "";
    case "CREDITINVOICE":
      return invoiceTypeFormatter(log.type_code);
    default:
      return log.type_code || "";
  }
};

export const ledgerLogClassFormatter = (log) => {
  // Displaying right classification (class) depends what is currently selected transaction
  switch (log.eventtype_code) {
    case "PAYMENT":
      return paymentClassFormatter(log.class);
    case "REPAYMENT":
      return "";
    case "INVOICE":
      return invoiceClassFormatter(log.class);
    case "CREDIT":
      return "";
    case "CREDITINVOICE":
      return invoiceClassFormatter(log.class);
    default:
      return log.class || "";
  }
};

export const formatItemQty = (count) => {
  switch (count) {
    case "36":
      return ["1", "2", "3", "4", "6", "12", "24", "36"];
    case "24":
      return ["1", "2", "3", "4", "6", "12", "24"];
    case "12":
      return ["1", "2", "3", "4", "6", "12"];
    case "6":
      return ["1", "2", "3", "4", "6"];
    case "4":
      return ["1", "2", "3", "4"];
    case "3":
      return ["1", "2", "3"];
    case "2":
      return ["1", "2"];
    default:
      return ["1", "2", "3", "4", "6", "12"];
  }
};

export const addressTypeFormatter = (address) => {
  switch (address) {
    case "Definitive":
      return tr("basicAddress");
    case "Splitted":
      return tr("splittedAddress");
    case "TemporaryCurrent":
      return tr("holidayAddress");
    case "Temporary":
      return tr("holidayAddress");
    case "Invoicing":
      return tr("invoicingAddress");
    case "Sleep":
      return tr("sleep");
    case "Basic":
      return tr("basicAddressStart");
    case "FocusingChange":
      return tr("focusingChangeStart");
    default:
      return "";
  }
};

export const allowedDeniedFormatter = (allowed) => {
  switch (allowed) {
    case true:
      return tr("allowed");
    case false:
      return tr("denied");
    default:
      return "";
  }
};

export const daysForDropdown = () => {
  return [
    { id: "", name: "" },
    { id: "01", name: "01" },
    { id: "02", name: "02" },
    { id: "03", name: "03" },
    { id: "04", name: "04" },
    { id: "05", name: "05" },
    { id: "06", name: "06" },
    { id: "07", name: "07" },
    { id: "08", name: "08" },
    { id: "09", name: "09" },
    { id: "10", name: "10" },
    { id: "11", name: "11" },
    { id: "12", name: "12" },
    { id: "13", name: "13" },
    { id: "14", name: "14" },
    { id: "15", name: "15" },
    { id: "16", name: "16" },
    { id: "17", name: "17" },
    { id: "18", name: "18" },
    { id: "19", name: "19" },
    { id: "20", name: "20" },
    { id: "21", name: "21" },
    { id: "22", name: "22" },
    { id: "23", name: "23" },
    { id: "24", name: "24" },
    { id: "25", name: "25" },
    { id: "26", name: "26" },
    { id: "27", name: "27" },
    { id: "28", name: "28" },
    { id: "29", name: "29" },
    { id: "30", name: "30" },
    { id: "31", name: "31" },
  ];
};

export const ledgerTransactionFormatter = (transaction) => {
  switch (transaction) {
    case "Alternative Invoice":
      return tr("alternativeInvoice");
    case "Invoice":
      return tr("invoice");
    case "    Credit Invoice":
      return tr("creditInvoice");
    case "Advance Payment":
      return tr("advancePayment");
    case "Payment on subs":
      return tr("paymentOnSubs");
    case "    Payment":
      return tr("payment");
    case "    Refund":
      return tr("refund");
    case "transaction":
      return tr("transaction");
    default:
      return "";
  }
};

export const contactInfoTypeFormatter = (type) => {
  const CONTACT_TYPES = store.getState().parameter.CONTACT_TYPES || {};

  return CONTACT_TYPES[type] || "";
};

export const contactInfoSourceFormatter = (source) => {
  const PHONECHECK_SOURCES = store.getState().parameter.PHONECHECK_SOURCES || {};

  return PHONECHECK_SOURCES[source] || "";
};

export const contactInfoStatusFormatter = (source) => {
  const PHONE_STATUS = store.getState().parameter.PHONE_STATUS || {};

  return PHONE_STATUS[source] || "";
};

export const invoiceModeFormatter = (value) => {
  const INVMODE = store.getState().parameter.INVMODE.ALL || {};
  const name = INVMODE[value] || "";

  return value + " - " + name;
};

export const salesmanPapersFormatter = (papercode) => {
  const salesGroupPapers = store.getState().customer.salesman.salesGroupPapers || [];
  // eslint-disable-next-line array-callback-return
  const paper = salesGroupPapers.find((paper) => {
    if (paper.paper_code === papercode) {
      return paper;
    }
  });

  if (paper) {
    return paper.name;
  }
  return "";
};

export const salesmanGroupsFormatter = (salesmanGroup, papercode) => {
  const salesGroups = store.getState().customer.salesman.salesGroups || [];
  if (salesGroups[papercode]) {
    // eslint-disable-next-line array-callback-return
    const group = salesGroups[papercode].find((group) => {
      if (salesmanGroup === group.group_no) {
        return group;
      }
    });

    if (group) {
      return `${group.group_no} - ${group.group_name}`;
    }
  }
  return "";
};

export const salesmanFeeGroupFormatter = (salesmanFeeGroup, papercode) => {
  const salesFeeGroups = store.getState().customer.salesman.salesFeeGroups || [];
  if (salesFeeGroups[papercode]) {
    // eslint-disable-next-line array-callback-return
    const group = salesFeeGroups[papercode].find((group) => {
      if (salesmanFeeGroup === group.group_no) {
        return group;
      }
    });

    if (group) {
      return `${group.group_no} - ${group.group_name}`;
    }
  }
  return "";
};

export const weekdayFormatter = (weekday) => {
  switch (weekday) {
    case "mon":
      return tr("mondayLong");
    case "tue":
      return tr("tuesdayLong");
    case "wed":
      return tr("wednesdayLong");
    case "thu":
      return tr("thursdayLong");
    case "fri":
      return tr("fridayLong");
    case "sat":
      return tr("saturdayLong");
    case "sun":
      return tr("sundayLong");
    default:
      return "";
  }
};

export const focusStateFormatter = (state) => {
  switch (state) {
    case "00":
      return tr("waitingToActivate");
    case "01":
      return tr("active");
    case "03":
      return tr("ended");
    default:
      return "";
  }
};

export const deliveryInfoFocusStateFormatter = (state) => {
  if (state && state !== "") {
    return tr("waitingToActivate") + " - " + momentToDateString(parseString(state));
  } else {
    return tr("active");
  }
};

/**
 * Formats long number/string to more human-readable format,
 * e.g. `123456789012` -> `12345 67890 12`.
 * @param {number} groupLength - length of each group, example above uses 5
 */
export const longStringFormatter = (groupLength = 5) => (string = "") => {
  // Create base regular expression
  const baseRegex = "(\\w{x})".replace(/x/, groupLength);
  const regex = new RegExp(baseRegex, "g");

  // Actual formatting for given long string
  return string.replace(regex, "$1 ").replace(/(^\s+|\s+$)/, "");
};

export const failedToRemoveSubsFormatter = (errorMsg) => {
  switch (errorMsg) {
    case "10251":
      return tr("subsCannotBeFound");
    case "10196":
      return tr("failedToDetectIfSubsCanBeRemoved");
    case "10198":
      return tr("activeSubsCannotBeRemoved");
    case "40571":
      return tr("subsCannotBeRemovedBecauseItHasPayment");
    case "40572":
      return tr("subsCannotBeRemovedBecauseItHasBeenCancelled");
    case "40869":
      return tr("subsCannotBeRemovedBecauseItHasBeenInvoiced");
    default:
      return tr("removingSubscriptionFailed");
  }
};

export const customerDeleteFormatter = (errorTrans, errorInfo) => {
  const errorMsg = `${tr("removingCustomerIsNotAllowed")}.`;

  switch (errorTrans) {
    case "337":
      return `${errorMsg} ${tr("customerHasOpenInvoices")} ${errorInfo}`;
    case "339":
      return `${tr("customerHasSubsStopRecentlyConfirm")} ${tr(
        "customerHasSubsStopRecently"
      )} ${errorInfo}`;
    case "340":
      return `${errorMsg} ${tr("customerIsAlsoSalesman")} ${errorInfo}`;
    case "341":
      return `${errorMsg} ${tr("customerHasExtraProdReg")} ${errorInfo}`;
    case "342":
      return `${errorMsg} ${tr("customerHasToDoorServiceReg")} ${errorInfo}`;
    case "3103":
      return `${errorMsg} ${tr("noCustomerInformationForNumber")} ${errorInfo}`;
    case "10322":
      return `${errorMsg} ${tr("lastCustomerSubsIsWaitingToBecomeActive")}: ${errorInfo}`;
    case "41501":
      return `${errorMsg} ${tr("customerIsBecomingNewPayerOfOtherSubs")}`;
    case "42951":
      return `${errorMsg} ${tr("customerHasOverpayment")}: ${errorInfo}`;
    case "43136":
      return `${errorMsg} ${tr("customerHasActiveSubs")}: ${errorInfo}`;
    default:
      return tr("cannotDeleteCustomer");
  }
};

export const customerPageModalTitle = (
  defaultText = "",
  showSubscription = true,
  showPackage = false
) => {
  const customer = store.getState().customer.selectedCustomer || {};
  const subs = store.getState().customer.subscription.selectedSubscription || {};

  let title = defaultText;
  if (customer.cusno) {
    if (title.trim() === "") {
      title = `${customer.cusno} ${customer.cusname}`;
    } else {
      title = `${title} - ${customer.cusno} ${customer.cusname}`;
    }
  }

  if (showSubscription && subs.order_subsno) {
    if (showPackage && subs.packageid && subs.packagename) {
      if (title.trim() === "") {
        title = `${subs.subsno} ${subs.packageid} - ${subs.packagename}`;
      } else {
        title = `${title}, ${subs.subsno} ${subs.packageid} - ${subs.packagename}`;
      }
    } else {
      if (title.trim() === "") {
        title = `${subs.subsno} ${subs.papercode} - ${subs.papername}`;
      } else {
        title = `${title}, ${subs.subsno} ${subs.papercode} - ${subs.papername}`;
      }
    }
  }

  return title;
};

export const capitalize = (string) => {
  if (!string) {
    return "";
  }

  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export const isEmptyString = (string) => {
  if (string === undefined) {
    return true;
  } else if (string.trim() === "") {
    return true;
  } else {
    return false;
  }
};

export const toEmptyString = (object) => {
  if (object === undefined || object === null || !object) {
    return "";
  } else {
    return object;
  }
};

export const getFormattedTime = (date, time) => {
  if (date && time) {
    var dateTime = moment(date).set({ hour: time.split(":")[0], minute: time.split(":")[1] });
    dateTime = moment(dateTime).format("YYYY-MM-DD[T]HH:mm:ss");
    return dateTime;
  }
};

export const extractTimeFromDate = (date) => {
  return date && date.slice(date.indexOf("T") + 1, date.indexOf("T") + 6);
};
