import { resolveFieldValueFromObject } from "@utils/common.js";
import * as productsActions from "./products.action.js";
import * as papercodeActions from "./papercode.action.js";
import * as productPackagesActions from "./productPackages.action.js";
import * as packageProductsActions from "./packageProducts.action";

let actions = { ...productsActions, ...papercodeActions, ...productPackagesActions, ...packageProductsActions }
const defaultState = {
  paperCodes: [],
  paperCodeLoading: false,
  productPackages: [],
  productPackagesLoading: false,
  removingPaperCode: false,
  products: null,
  productsLoading: false,
  classificationOptions: { classification1: [], classification2: [], classification3: [], businessUnitIds: [] },
  removingProduct: false,
  productOptions: { digiProductTypeOptions: [], vatCodes: [] },
  isLoadingPaperCodeOptions: false,
  packageProducts: [],
  packageProductsLoading: false,
  removingProductPackages: false,
  removingPackageProducts: false,
  productPackagesOptions: { creditCategoryOptions: [] },
  productsList: null,
  productsListLoading: false,
  paperCodeGroups:[],
  paperCodeGroupsLoading: false,
  paperGroupItems:[],
  paperGroupItemsLoading:false,
  paperCodesWithGroups:[],
  paperCodeWithGroupsLoading:false,
};

const adminReducer = (state = defaultState, action) => {
  const { type, payload } = action;

  switch (type) {

    // Paper Code reducers

    case actions.ADMIN_PRODUCT_IS_LOADING_PAPER_CODES: {
      return { ...state, paperCodes: [], paperCodeLoading: true };
    }
    case actions.ADMIN_PRODUCT_SET_ALL_PAPER_CODES: {
      return { ...state, paperCodes: payload, paperCodeLoading: false };
    }

    case actions.ADMIN_PRODUCT_IS_LOADING_PAPER_CODES_WITH_GROUPS: {
      return { ...state, paperCodes: [], paperCodeLoading: true };
    }
    case actions.ADMIN_PRODUCT_SET_ALL_PAPER_CODES_WITH_GROUPS: {
      return { ...state, paperCodesWithGroups: payload, paperCodeWithGroupsLoading: false };
    }

    case actions.ADMIN_PRODUCT_IS_LOADING_PAPER_CODE_GROUPS: {
      return { ...state, paperCodeGroups: [], paperCodeGroupsLoading: true };
    }
    case actions.ADMIN_PRODUCT_SET_ALL_PAPER_CODE_GROUPS: {
      return { ...state, paperCodeGroups: payload, paperCodeGroupsLoading: false };
    }

    case actions.ADMIN_PRODUCT_IS_LOADING_PAPER_GROUP_ITEMS: {
      return { ...state, paperGroupItems: [], paperGroupItemsLoading: true };
    }
    case actions.ADMIN_PRODUCT_SET_ALL_PAPER_GROUP_ITEMS: {
      return { ...state, paperGroupItems: payload, paperGroupItemsLoading: false };
    }

    case actions.ADMIN_PRODUCT_SET_REMOVING_PAPER_CODE: {
      return { ...state, removingPaperCode: payload || false };
    }

    case actions.ADMIN_PRODUCT_PAPER_CODE_REMOVED: {
      const paperCodes = state.paperCodes.filter((pg) => pg.papercode !== payload.papercode);
      return { ...state, paperCodes };
    }

    case actions.ADMIN_PRODUCT_PAPER_CODE_EDITED: {
      console.log("payload", payload)
      const paperCodes = state.paperCodes.map((p) => {
        debugger
        if (p.papercode === payload.papercode) {
          return {
            ...payload,
            weightfactor_available: payload.isweightfactor,
            focuspaper: resolveFieldValueFromObject(payload.focuspaper),
            businessunitid: resolveFieldValueFromObject(payload.businessunitid),
            paperclass1: resolveFieldValueFromObject(payload.paperclass1),
            paperclass2: resolveFieldValueFromObject(payload.paperclass2),
            paperclass3: resolveFieldValueFromObject(payload.paperclass3),
            paperclassification1: resolveFieldValueFromObject(payload.paperclass1),
            paperclassification2: resolveFieldValueFromObject(payload.paperclass2),
            paperclassification3: resolveFieldValueFromObject(payload.paperclass3),
          }
        }
        return p;
      });
      return { ...state, paperCodes };
    }

    case actions.ADMIN_PRODUCT_SET_CLASSIFICATION_OPTIONS: {
      return { ...state, classificationOptions: payload, isLoadingPaperCodeOptions: false };
    }

    case actions.ADMIN_PRODUCT_IS_LOADING_PAPER_CODE_OPTIONS: {
      return { ...state, isLoadingPaperCodeOptions: true };
    }

    //Products reducers

    case actions.ADMIN_PRODUCT_IS_LOADING_PRODUCTS: {
      return { ...state, productsLoading: true };
    }

    case actions.ADMIN_PRODUCT_SET_ALL_PRODUCTS: {
      return { ...state, products: payload, productsLoading: false };
    }

    case actions.ADMIN_PRODUCT_IS_LOADING_ALL_PRODUCTS_LIST: {
      return { ...state, productsListLoading: true };
    }

    case actions.ADMIN_PRODUCT_SET_ALL_PRODUCTS_LIST: {
      return { ...state, productsList: payload, productsListLoading: false };
    }

    case actions.ADMIN_PRODUCT_PRODUCT_EDITED: {
      const products = state.products.map((p) => {
        if (p.papercode === payload.papercode && p.productno === payload.productno) {
          return {
            ...payload,
            isspecial: payload.isspecial,
            periodic: resolveFieldValueFromObject(payload.periodic),
            vatcode: resolveFieldValueFromObject(payload.vatcode),
            producttype: resolveFieldValueFromObject(payload.producttype),

          }
        }
        return p;
      });
      return { ...state, products };
    }

    case actions.ADMIN_PRODUCT_SET_REMOVING_PRODUCT: {
      return { ...state, removingProduct: payload || false };
    }

    case actions.ADMIN_PRODUCT_PRODUCT_REMOVED: {
      const products = state.products.filter((p) => p.productno !== payload.productno);
      console.log("deletedPorductFilter", products)
      return { ...state, products };
    }

    case actions.ADMIN_PRODUCT_SET_PRODUCT_OPTIONS: {
      return { ...state, productOptions: payload };
    }

    //Product Packages reducers

    case actions.ADMIN_PRODUCT_IS_LOADING_PRODUCT_PACKAGES: {
      return { ...state, productPackages: [], productPackagesLoading: true };
    }

    case actions.ADMIN_PRODUCT_SET_ALL_PRODUCT_PACKAGES: {
      return { ...state, productPackages: payload, productPackagesLoading: false };
    }

    case actions.ADMIN_PRODUCT_IS_LOADING_PACKAGE_PRODUCTS: {
      return { ...state, packageProducts: [], packageProductsLoading: true };
    }
    case actions.ADMIN_PRODUCT_SET_ALL_PACKAGE_PRODUCTS: {
      return { ...state, packageProducts: payload, packageProductsLoading: false };
    }

    case actions.ADMIN_PRODUCT_SET_REMOVING_PRODUCT_PACKAGES: {
      return { ...state, removingProductPackages: payload || false };
    }

    case actions.ADMIN_PRODUCT_PRODUCT_PACKAGES_REMOVED: {
      const productPackages = state.productPackages.filter((p) => p.packageid !== payload.packageid);
      return { ...state, productPackages };
    }

    case actions.ADMIN_PRODUCT_PRODUCT_PACKAGES_EDITED: {
      const productPackages = state.productPackages.map((p) => {
        if (p.packageid === payload.packageid) {
          return {
            ...payload,
            creditcategory: resolveFieldValueFromObject(payload.creditcategory),
          }
        }
        return p;
      });
      return { ...state, productPackages };
    }

    // Package Products reducers

    case actions.ADMIN_PRODUCT_SET_REMOVING_PACKAGE_PRODUCTS: {
      return { ...state, removingPackageProducts: payload || false };
    }

    case actions.ADMIN_PRODUCT_PACKAGE_PRODUCTS_REMOVED: {
      const packageProducts = state.packageProducts.filter((pp) => {
        if (pp.packageid === payload.packageid
          && pp.papercode === payload.papercode
          && pp.productno === payload.productno) {
          return false;
        }
        return true;
      });
      return { ...state, packageProducts };
    }

    case actions.ADMIN_PRODUCT_SET_PRODUCT_PACKAGES_OPTIONS: {
      return { ...state, productPackagesOptions: payload };
    }

    default:
      return state;
  }
};

export default adminReducer;
