import * as actions from "./email.action.js";

const defaultState = {
  active: false,
  recipients: [],
  subject: "",
  body: [],
};

const emailReducer = (state = defaultState, action) => {
  const { type, payload } = action;

  switch (type) {
    case actions.NEW_EMAIL: {
      const recipients = Array.isArray(payload.recipients)
        ? payload.recipients
        : [payload.recipients];
      const body = Array.isArray(payload.body) ? payload.body : [payload.body];

      return { ...state, active: true, subject: payload.subject, recipients, body };
    }

    case actions.CLEAR_EMAIL:
      return { ...defaultState };

    case actions.CLOSE_EMAIL:
      return { ...state, active: false };

    default:
      return { ...state };
  }
};

export default emailReducer;
