import * as actions from "./imageBank.action.js";

const defaultState = {
  image: null,
  imageLoading: false,
};

const adminReducer = (state = defaultState, action) => {
  const { type, payload } = action;

  switch (type) {
    case actions.IMAGE_BANK_IS_LOADING_IMAGE: {
      return { ...state, image: null, imageLoading: true };
    }

    case actions.IMAGE_BANK_SET_IMAGE: {
      return { ...state, image: payload, imageLoading: false };
    }

    case actions.IMAGE_BANK_CLEAR_IMAGE: {
      return { ...state, image: null, imageLoading: false };
    }

    default:
      return state;
  }
};

export default adminReducer;
