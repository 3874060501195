import { createSelector } from "reselect";

export const selectTranslations = (state) => state.translation.available;
export const selectCurrent = (state) => state.translation.current;

export const selectLocalized = createSelector(selectTranslations, (translations) => {
  const localized = Object.keys(translations)
    .sort()
    .map((langCode) => translations[langCode]);

  return localized;
});
