import * as actions from "./user.action.js";

const defaultState = {
  authenticated: null,
  defaults: {},
  parrowUserSettings: { invoiceLimit: 60 },
  properties: {},
  publishers: { current: "", publishers: {} },
  settings: {
    tables: {
      visibility: {}, // Hidden columns for tables, e.g. { 'customer': [0, 5, 9] }
    },
  },
  DENIED_MODULE_NAMES: new Set([]),
  DENIED_PAPER_GROUPS: [],
  DENIED_MODULES: [],
  forceResetPassword: false, // Build 4.1.1.0 (ERP #12017)
  paperCodes:[]
};

const userReducer = (state = defaultState, action) => {
  const { type, payload } = action;

  switch (type) {
    case actions.SET_AUTHENTICATED: {
      // Storing array of denied_module_names received from API as a Set
      const DENIED_MODULE_NAMES = new Set(payload.DENIED_MODULE_NAMES);

      return {
        ...state,
        authenticated: payload.authenticated,
        isAdmin: payload.isAdmin,
        DENIED_MODULE_NAMES: DENIED_MODULE_NAMES,
        DENIED_PAPER_GROUPS: payload.DENIED_PAPER_GROUPS,
        DENIED_MODULES: payload.DENIED_MODULES,
      };
    }

    case actions.SET_USER_INFO: {
      return { ...state, properties: payload };
    }

    case actions.SET_USER_SETTINGS: {
      let settings = payload;
      // Settings must be an object with content, otherwise defaultState is used
      if (settings === null || typeof settings !== "object" || Object.keys(settings).length === 0) {
        settings = { ...defaultState.settings };
      }

      return { ...state, settings };
    }

    case actions.SET_PUBLISHERS: {
      return { ...state, publishers: payload };
    }

    case actions.CHANGE_PUBLISHER: {
      const publishers = { ...state.publishers, current: payload };

      return { ...state, publishers };
    }

    case actions.SET_TABLE_COLUMN_VISIBILITY: {
      const visibility = { ...state.settings.tables.visibility };
      visibility[payload.tableName] = payload.hiddenColumns;

      return {
        ...state,
        settings: {
          ...state.settings,
          tables: {
            ...state.settings.tables,
            visibility,
          },
        },
      };
    }

    case actions.SET_USER_DEFAULTS: {
      return { ...state, defaults: payload };
    }

    case actions.SET_PARROW_USER_SETTINGS: {
      return { ...state, parrowUserSettings: payload };
    }

    // Build 4.1.1.0 (ERP #12017)
    case actions.SET_FORCE_RESET_PASSWORD: {
      return { ...state, forceResetPassword: payload };
    }

    case actions.SET_PAPER_CODES: {
      return { ...state, paperCodes: payload };
    }

    default:
      return state;
  }
};

export default userReducer;
