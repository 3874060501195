import React from "react";
import PropTypes from "prop-types";

import "@assets/styles/loading.scss";

/**
 * Display a translucent loading animation.
 * Has 'position: absolute' css rule set so will by default take the whole page
 * unless parent element has 'position: relative' rule set to it, which will
 * contain the loading animation inside the parent element.
 *
 * Usage:
 * ```
 * <Loading loading={boolean} />
 * ```
 */
const Loading = ({ loading, style = {}, transparent = false }) => {
  if (!loading) {
    return null;
  }

  const bg = transparent ? { background: "transparent" } : {};
  const styles = { ...style, ...bg };

  return (
    <div className="loading" style={styles}>
      <div className="sk-three-bounce ">
        <div className="sk-child sk-bounce1"></div>
        <div className="sk-child sk-bounce2"></div>
        <div className="sk-child sk-bounce3"></div>
      </div>
    </div>
  );
};

Loading.propTypes = {
  loading: PropTypes.bool.isRequired,
  style: PropTypes.object,
  transparent: PropTypes.bool,
};

/**
 * LoadingWithContainer wraps the loading indicator with <div> element with min-height.
 * This is useful, for example, when a window doesn't render anything else than
 * the Loading component, which ensures that the Loading is kept inside the window.
 */
const LoadingWithContainer = (props) => {
  return (
    <div className="loading-container">
      <Loading {...props} />
    </div>
  );
};

export default Loading;
export { LoadingWithContainer };
