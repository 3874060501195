import { removeNullValues, resolveFieldValueFromObject } from "@utils/common.js";
import { Fetcher, fetchWithRetry } from "@utils/fetcher.js";
import apiUrl from "@utils/url.js";

export async function fetchAllPackageProducts(productPackage) {
  const url = apiUrl(`package/products/${productPackage.packageId}`);

  return fetchWithRetry("getJson", [url])
    .then((packageProducts = []) => {
      return packageProducts;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function postPackageProducts(packageProducts) {
  const url = apiUrl(`package/products/${packageProducts.packageid}`);

  const payload = {
    papercode: resolveFieldValueFromObject(packageProducts.papercode),
    productno: resolveFieldValueFromObject(packageProducts.productno),
    mainproduct: packageProducts.mainproduct,
    invoicingproduct: packageProducts.invoicingproduct,
    discount_as_mainproduct: packageProducts.discount_as_mainproduct,
    standing_digiprod_sleeprule: resolveFieldValueFromObject(packageProducts.standing_digiprod_sleeprule),
    periodic_digiprod_sleeprule: resolveFieldValueFromObject(packageProducts.periodic_digiprod_sleeprule),
  };
  return Fetcher.post(url, payload)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function deletePackageProducts(packageProducts) {
  const url = apiUrl(`package/products/${packageProducts.packageid}/${packageProducts.papercode}/${packageProducts.productno}`);

  return Fetcher.delete(url)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function putPackageProducts(packageProducts) {
  let product = resolveFieldValueFromObject(packageProducts.productno);
  const url = apiUrl(`package/products/${packageProducts.packageid}/${packageProducts.papercode}/${product}`);
  let payload = {
    papercode: resolveFieldValueFromObject(packageProducts.papercode),
    productno: product,
    mainproduct: packageProducts.mainproduct,
    invoicingproduct: packageProducts.invoicingproduct,
    standing_digiprod_sleeprule: resolveFieldValueFromObject(packageProducts.standing_digiprod_sleeprule),
    periodic_digiprod_sleeprule: resolveFieldValueFromObject(packageProducts.periodic_digiprod_sleeprule),
    discount_as_mainproduct: packageProducts.discount_as_mainproduct,
  };

  return Fetcher.put(url, payload)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}
