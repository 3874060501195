import * as actions from "../actions/actionTypes.js";

const defaultState = {
  api_version:"",
  client_version:""
};

const versionReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actions.UPDATE_VERSION_INFORMATION: {
      return { ...state, api_version: action.versionInformation.api_version, client_version: action.versionInformation.client_version };
    }
    default:
      return state;
  }
};

export default versionReducer;
