import { Fetcher, fetchWithRetry } from "@utils/fetcher.js";
import apiUrl from "@utils/url.js";

export async function fetchAllParameters(row) {
  const url = apiUrl("parameters/" + row.codegroupno + "/" + row.papercode);

  return fetchWithRetry("getJson", [url])
    .then((parameters = []) => {
      return parameters;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function fetchParameter(row) {
  const url = apiUrl("parameters/" + row.codegroupno + "/" + row.papercode + "/" + row.codeno);

  return fetchWithRetry("getJson", [url])
    .then((parameter = {}) => {
      return parameter;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function fetchAllParametersGroup() {
  const url = apiUrl("parameters/groups");

  return fetchWithRetry("getJson", [url])
    .then((parameters = []) => {
      return parameters;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function deleteParamsGroup(paramsGroup) {
  const url = apiUrl(`parameters/${paramsGroup.codegroupno}/${paramsGroup.papercode}`);

  return Fetcher.delete(url)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function deleteParameter(parameter) {
  console.log("parameter=>effect", parameter)

  const url = apiUrl(`parameters/${parameter.codegroupno}/${parameter.papercode}/${parameter.codeno}`);

  return Fetcher.delete(url)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function postParamsGroup(paramsGroup) {
  const url = apiUrl(`parameters/${paramsGroup.codegroupno}/${paramsGroup.papercode.value}`);
  const payload = {
    codegroupname: paramsGroup.codegroupname,
    notes: paramsGroup.notes,
  };

  return Fetcher.post(url, payload)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function putParamsGroup(paramsGroup) {
  const url = apiUrl(`parameters/${paramsGroup.codegroupno}/${paramsGroup.papercode}`);
  const payload = {
    codegroupname: paramsGroup.codegroupname,
    notes: paramsGroup.notes,
  };

  return Fetcher.put(url, payload)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}



export async function postParameters(parameters) {
  const url = apiUrl(`parameters/${parameters.codegroupno}/${parameters.papercode}/${parameters.codeno}`);
  const payload = {
    codevalue: parameters.codevalue,
    description: parameters.description,
    startdate: parameters.startdate,
    enddate: parameters.enddate,
  };

  return Fetcher.post(url, payload)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}


export async function putParameter(parameter, isCodeNoEdited) {
  var _url = "";
  const payload = {}
  if (isCodeNoEdited) {
    _url =
      `parameters/id/${parameter.codegroupno}/${parameter.oldCodeNo}`;
    payload.newcodeno = parameter.codeno
  }
  else {
    _url = `parameters/${parameter.codegroupno}/${parameter.papercode}/${parameter.codeno}`
    payload.codevalue = parameter.codevalue
    payload.description = parameter.description
    payload.startdate = parameter.startdate
    payload.enddate = parameter.enddate

  }

  const url = apiUrl(_url);
  return Fetcher.put(url, payload)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}


export async function fetchAllPaperCodes(row) {
  const url = apiUrl("subscriptions/papers");
  console.log("SET_ALL_PAPER_CODES effect start")

  return fetchWithRetry("getJson", [url])
    .then((paperCodes = []) => {
      console.log("SET_ALL_PAPER_CODES effect", paperCodes)

      return paperCodes;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}









