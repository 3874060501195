import { applyMiddleware, compose, createStore } from "redux";
import { routerMiddleware, connectRouter } from "connected-react-router";
import { createLogger } from "redux-logger";
import thunk from "redux-thunk";

import { history } from "./history.js";
import appReducer from "./rootReducer.js";

// Middleware for intercepting and dispatching navigation actions
const middleware = [routerMiddleware(history)];
// Add redux-thunk to same middleware
middleware.push(thunk);
// Check for undefined because tests don't have environment variables
if (typeof process.env !== "undefined") {
  if (process.env.NODE_ENV === "development") {
    const logger = createLogger({
      collapsed: true,
    });

    // Add logger middleware for development environment
    middleware.push(logger);
  }
}

function configureStore(preloadedState) {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(
    connectRouter(history)(appReducer),
    preloadedState,
    composeEnhancers(applyMiddleware(...middleware))
  );

  return store;
}

const store = configureStore();

export default store;
