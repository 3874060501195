import Configuration from "../../../../config.js"; // outside "src"

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Col, Row } from "reactstrap";

import { tr } from "@utils/translation.js";

import PublisherMenu from "./PublisherMenu.jsx";
import HeaderMenu from "./HeaderMenu.jsx";
import HistoryList from "./HistoryList.jsx";

const logo = require("@assets/images/kayak-logo-bg.png");

const Header = (props) => {
  return (
    <Row id="page-header" className="no-margin">
      <Col sm={3} className="no-padding">
        <Row>
          <Col sm={6}>
            <div onClick={props.onLogoClick} className="header-image-container">
              <img className="header-image" src={logo} />
            </div>
          </Col>
          {/* #29638, add version */}
          <Col sm={6}>
            <span className="header-button version">
              <i />
              <i />
              {tr("version") + " " + props.versionProps.versionInformation.api_version || tr("version") + " " + Configuration.api_version}
              <div>{tr("client") + " " + props.versionProps.versionInformation.client_version || tr("client") + " " + Configuration.client_version}</div>
            </span>
          </Col>
        </Row>
      </Col>
      <Col sm={3}>
        {
          /* Don't show publishers menu when there is only one publisher Issue #27312 */
          Object.keys(props.publisherMenuProps.publishers).length > 1 ? (
            <PublisherMenu {...props.publisherMenuProps} />
          ) : (
            <span className="header-button">
              <i />
              <i />
              {Object.keys(props.publisherMenuProps.publishers)[0]}{" "}
            </span>
          )
        }
      </Col>
      <Col sm={6} id="header-button-group" className="no-padding">
        <HistoryList {...props.historySelectionProps} />
        <HeaderMenu {...props.headerMenuProps} />
      </Col>
    </Row>
  );
};

Header.propTypes = {
  onLogoClick: PropTypes.func.isRequired,
  publisherMenuProps: PropTypes.object.isRequired,
  headerMenuProps: PropTypes.object.isRequired,
  historySelectionProps: PropTypes.object.isRequired,
  versionProps: PropTypes.object.isRequired,
};

export default Header;
