import { postImage, putImage } from "@app/imageBank/imageBank.effect.js";
import { notify } from "@app/notification/notification.action.js";
import * as actionCreator from "@utils/actionCreator.js";
import { tr } from "@utils/translation.js";
import * as effect from "./productPackages.effect.js";
import { editProductText } from "@app/productTexts/productTexts.action.js";
import { addProductTexts } from "@utils/productTexts.js";

const SLICE = "products";

//Product Packages actions

export const ADMIN_PRODUCT_IS_LOADING_PRODUCT_PACKAGES = `${SLICE}/IS_LOADING_PRODUCT_PACKAGES`;
export const ADMIN_PRODUCT_SET_ALL_PRODUCT_PACKAGES = `${SLICE}/SET_ALL_PRODUCT_PACKAGES`;
export const requestAllProductPackages = () => async (dispatch) => {

  dispatch(actionCreator.createAction(ADMIN_PRODUCT_IS_LOADING_PRODUCT_PACKAGES));

  const response = await effect.fetchAllProductPackages();
  if (response.error) {
    dispatch(actionCreator.createAction(ADMIN_PRODUCT_SET_ALL_PRODUCT_PACKAGES, []));
    dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }
  dispatch(actionCreator.createAction(ADMIN_PRODUCT_SET_ALL_PRODUCT_PACKAGES, response));
};

export const addProductPackages = (productPackage, callback = () => { }) => async (dispatch) => {

  let descriptivetextid = await addProductTexts(productPackage);
  if (descriptivetextid) {
    productPackage.descriptivetextid = descriptivetextid;
    productPackage.textid = descriptivetextid;
  }

  if (productPackage.imageid) {
    let imgUploadRes = await postImage(productPackage.imageid);
    imgUploadRes = await imgUploadRes.json();
    productPackage.imageid = imgUploadRes.imageid;
  }

  const response = await effect.postProductPackages(productPackage);
  if (response.error) {
    dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }

  callback();
  dispatch(notify(tr("productPackagesAdded"), "success"));
  dispatch(requestAllProductPackages());
};

export const ADMIN_PRODUCT_PRODUCT_PACKAGES_EDITED = `${SLICE}/PRODUCT_PACKAGES_EDITED`;
export const editProductPackages = (productPackages, callback = () => { }) => async (dispatch) => {

  let imgUploadRes;
  let isDefaultImage = productPackages.imageid === null || productPackages.imageid.id === 0 || productPackages.imageid.id === null;
  if (isDefaultImage) {
    imgUploadRes = await postImage(productPackages.imageid);
  }
  else {
    imgUploadRes = await putImage(productPackages.imageid);
  }

  try {
    imgUploadRes = await imgUploadRes.json();
    productPackages.imageid = isDefaultImage ? imgUploadRes.imageid : productPackages.imageid.id;
  }
  catch (e) { }

  if (!productPackages.descriptivetextid || productPackages.descriptivetextid == 0) {
    let descriptivetextid = await addProductTexts(productPackages);
    if (descriptivetextid) {
      productPackages.descriptivetextid = descriptivetextid;
      productPackages.textid = descriptivetextid;
    }
  }
  else {
    dispatch(editProductText(productPackages));
  }

  const response = await effect.putProductPackages(productPackages);
  if (response.error) {
    dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }
  callback();
  dispatch(notify(tr("productPackagesEdited"), "success"));
  dispatch(actionCreator.createAction(ADMIN_PRODUCT_PRODUCT_PACKAGES_EDITED, productPackages));
};

export const ADMIN_PRODUCT_SET_REMOVING_PRODUCT_PACKAGES = `${SLICE}/SET_REMOVING_PRODUCT_PACKAGES`;
export const ADMIN_PRODUCT_PRODUCT_PACKAGES_REMOVED = `${SLICE}/PRODUCT_PACKAGES_REMOVED`;
export const removeProductPackages = (prodcutPackage, callback = () => { }) => async (dispatch) => {

  dispatch(actionCreator.createAction(ADMIN_PRODUCT_SET_REMOVING_PRODUCT_PACKAGES, true));

  const response = await effect.deleteProductPackages(prodcutPackage);
  if (response.error) {
    dispatch(notify(tr("dbConnectFailure"), "error"));
    dispatch(actionCreator.createAction(ADMIN_PRODUCT_SET_REMOVING_PRODUCT_PACKAGES, false));
    return;
  }
  callback();
  dispatch(notify(tr("productPackagesRemoved"), "warning"));
  dispatch(actionCreator.createAction(ADMIN_PRODUCT_SET_REMOVING_PRODUCT_PACKAGES, false));
  dispatch(actionCreator.createAction(ADMIN_PRODUCT_PRODUCT_PACKAGES_REMOVED, prodcutPackage));
};

