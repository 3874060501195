import moment from 'moment';

export const removeNullValues = (obj) => {
    return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != "" && v != null));
}

export const resolveFileBase64 = file => {
    return new Promise(resolve => {
        let fileInfo;
        let base64 = "";
        let reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => {
            var img = new Image;
            img.src = reader.result;
            img.onload = function () {
                base64 = reader.result;
                console.log(base64);
                resolve({ success: true, base64, width: img.width, height: img.height });
            };

        };
        console.log(fileInfo);
    });
};

export const getValueFromOptions = (array, field) => {
    if (!array) {
        array = [];
    }
    return array.find(element => element.value === field);
}

export const getMultipleValueFromOptions = (array, field) => {
    if (!array) {
        array = [];
    }
    return array.filter(a=> field.find(f=>f.value == a.value))
}

export const resolveFieldValueFromObject = (field) => {
    return field && typeof field === "object" ? field.value : field ? field : "";
}

export const isTrue = (field) => {
    return field && (field === "Y" || field === true) ? true : false;
}

export const removeDuplicateFromArrayOfObjectsByField = (array,field) => {

    const newMap = new Map();
    array.forEach((item) => newMap.set(item[field], item));
    return [...newMap.values()]
}
export const findClosestDateFromToday = (dates) => {
    // Find the closest date from today
const closestDate = dates.reduce((closest, currentDate) => {
    const currentMoment = moment(currentDate);
    const closestMoment = moment(closest);
    const today = moment();
  
    const currentDiff = Math.abs(currentMoment.diff(today));
    const closestDiff = Math.abs(closestMoment.diff(today));
  
    if (currentDiff < closestDiff) {
      return currentDate;
    } else {
      return closest;
    }
  }, dates[0]);

  return closestDate
}

