import { postImage } from "@app/imageBank/imageBank.effect.js";
import { notify } from "@app/notification/notification.action.js";
import * as actionCreator from "@utils/actionCreator.js";
import { tr } from "@utils/translation.js";
import * as effect from "../subsType/subsType.effect.js";

const SLICE = "price";

export const ADMIN_PRICE_IS_LOADING_ALL_SUBS_TYPE = `${SLICE}/IS_LOADING_ALL_SUBS_TYPE`;
export const ADMIN_PRICE_SET_ALL_SUBS_TYPE = `${SLICE}/SET_ALL_SUBS_TYPE`;
export const requestAllSubsType = () => async (dispatch) => {

  dispatch(actionCreator.createAction(ADMIN_PRICE_IS_LOADING_ALL_SUBS_TYPE));

  const response = await effect.fetchSubsTypes();
  if (response.error) {
    dispatch(actionCreator.createAction(ADMIN_PRICE_SET_ALL_SUBS_TYPE, []));
    dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }
  dispatch(actionCreator.createAction(ADMIN_PRICE_SET_ALL_SUBS_TYPE, response));
};

export const addSubsType = (subsType, callback = () => { }) => async (dispatch) => {
  const response = await effect.postSubsType(subsType);
  if (response.error) {
    dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }

  callback();
  dispatch(notify(tr("subsTypeAdded"), "success"));
  dispatch(requestAllSubsType());
};


export const ADMIN_PRICE_SUBS_TYPE_EDITED = `${SLICE}/SUBS_TYPE_EDITED`;
export const editSubsType= (subsType, callback = () => { }) => async (dispatch) => {

  const response = await effect.putSubsType(subsType);
  if (response.error) {
    dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }
  callback();
  dispatch(notify(tr("subsTypeEdited"), "success"));
  console.log({subsType})
  dispatch(actionCreator.createAction(ADMIN_PRICE_SUBS_TYPE_EDITED, subsType));

};

export const ADMIN_PRICE_SET_REMOVING_SUBS_TYPE= `${SLICE}/SET_REMOVING_SUBS_TYPE`;
export const ADMIN_PRICE_SUBS_TYPE_REMOVED = `${SLICE}/SUBS_TYPE_REMOVED`;
export const removeSubsType = (subsType, callback = () => { }) => async (dispatch) => {

  dispatch(actionCreator.createAction(ADMIN_PRICE_SET_REMOVING_SUBS_TYPE, true));

  const response = await effect.deleteSubsType(subsType);
  if (response.error) {
    dispatch(notify(tr("dbConnectFailure"), "error"));
    dispatch(actionCreator.createAction(ADMIN_PRICE_SET_REMOVING_SUBS_TYPE, false));
    return;
  }
  callback();
  dispatch(notify(tr("subsTypeRemoved"), "warning"));
  dispatch(actionCreator.createAction(ADMIN_PRICE_SET_REMOVING_SUBS_TYPE, false));
  dispatch(actionCreator.createAction(ADMIN_PRICE_SUBS_TYPE_REMOVED, subsType));
};


