import * as actions from "./notification.action.js";
import { generateKey } from "@utils/random.js";

const notificationsReducer = (state = { items: [] }, action) => {
  const { type, payload } = action;

  switch (type) {
    case actions.NEW_NOTIFICATION: {
      const items = state.items.slice();
      items.push({ message: payload.message, type: payload.messageType, id: generateKey() });

      return { ...state, items: items };
    }

    case actions.DISMISS_NOTIFICATION: {
      const newItems = state.items.slice();
      newItems.splice(payload, 1);

      return { ...state, items: newItems };
    }

    default:
      return { ...state };
  }
};

export default notificationsReducer;
