import { notify } from "@app/notification/notification.action.js";
import * as effect from "./reports.effect";
import * as actionCreator from "@utils/actionCreator.js";
import { tr } from "@utils/translation.js";

const SLICE = "reports";

export const REPORTS_IS_LOADING_ALL_REPORTS_REQ = `${SLICE}/IS_LOADING_ALL_REPORTS_REQ`;
export const REPORTS_SET_ALL_REPORTS_REQ = `${SLICE}/SET_ALL_REPORTS_REQ`;
export const requestAllReportsReqList = () => async (dispatch) => {
  dispatch(actionCreator.createAction(REPORTS_IS_LOADING_ALL_REPORTS_REQ));
  var response = await effect.fetchAllReportsReqList();
  if (response.error) {
    dispatch(actionCreator.createAction(REPORTS_SET_ALL_REPORTS_REQ, []));
    dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }
  response = await response.json();
  dispatch(actionCreator.createAction(REPORTS_SET_ALL_REPORTS_REQ, response));
};

export const REPORTS_IS_LOADING_ALL_REPORTS = `${SLICE}/IS_LOADING_ALL_REPORTS`;
export const REPORTS_SET_ALL_REPORTS = `${SLICE}/SET_ALL_REPORTS`;
export const requestAllReportsList = () => async (dispatch) => {
  dispatch(actionCreator.createAction(REPORTS_IS_LOADING_ALL_REPORTS));
  var response = await effect.fetchAllReportsList();
  if (response.error) {
    dispatch(actionCreator.createAction(REPORTS_SET_ALL_REPORTS, []));
    dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }
  response = await response.json();
  dispatch(actionCreator.createAction(REPORTS_SET_ALL_REPORTS, response));
};



export const REPORTS_SET_REMOVING_REPORT = `${SLICE}/SET_REMOVING_REPORT`;
export const REPORTS_REPORT_REMOVED = `${SLICE}/REPORT_REMOVED`;
export const removeReport = (report, callback = () => { }) => async (dispatch) => {
  dispatch(actionCreator.createAction(REPORTS_SET_REMOVING_REPORT, true));

  const response = await effect.deleteReport(report);
  if (response.error) {
    dispatch(notify(tr("dbConnectFailure"), "error"));
    dispatch(actionCreator.createAction(REPORTS_SET_REMOVING_REPORT, false));
    return;
  }

  callback();
  dispatch(notify(tr("reportRemoved"), "warning"));
  dispatch(actionCreator.createAction(REPORTS_SET_REMOVING_REPORT, false));
  // dispatch(actionCreator.createAction(REPORTS_REPORT_REMOVED, report));
  dispatch(requestAllReportsReqList());
};


export const editReport = (report, callback = () => { }) => async (dispatch) => {

  const response = await effect.putReport(report);
  if (response.error) {
    dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }

  callback();
  dispatch(notify(tr("reportEdited"), "success"));
  dispatch(requestAllReportsReqList());
};



export const addReport = (report, callback = () => { }) => async (dispatch) => {

  const response = await effect.postReport(report);
  if (response.error) {
    dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }

  callback();
  dispatch(notify(tr("reportAdded"), "success"));
  dispatch(requestAllReportsReqList());
};

export const REPORTS_IS_LOADING_PAPER_TRANSLATIONS = `${SLICE}/IS_LOADING_PAPER_TRANSLATIONS`;
export const REPORTS_SET_PAPER_TRANSLATIONS = `${SLICE}/SET_PAPER_TRANSLATIONS`;
export const requestPaperTranslations = () => async (dispatch) => {
  dispatch(actionCreator.createAction(REPORTS_IS_LOADING_PAPER_TRANSLATIONS));
  var response = await effect.fetchPaperTranslations();
  if (response.error) {
    dispatch(actionCreator.createAction(REPORTS_SET_PAPER_TRANSLATIONS, []));
    dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }
  response = await response.json();
  dispatch(actionCreator.createAction(REPORTS_SET_PAPER_TRANSLATIONS, response));
};




