import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { SubmissionError } from "redux-form";
import { Button } from "reactstrap";

import { setUserSettings } from "@app/user/user.action.js";
import { notify } from "@app/notification/notification.action.js";
import { Fetcher } from "@utils/fetcher";
import apiUrl from "@utils/url.js";
import { setUserData } from "@utils/user.js";
import { tr } from "@utils/translation.js";

import { Container, ContentContainer } from "../ui/layout.jsx";
import Modal from "../ui/Modal.jsx";
import Loading from "../ui/Loading.jsx";
import ConfirmationModal from "../ui/ConfirmationModal.jsx";
import LanguageSelect from "../general/LanguageSelect.jsx";
import TextModeSelection from "../general/TextModeSelect.jsx";
import ChangePasswordForm from "../general/ChangePasswordForm.jsx";
import ChangeEmailForm from "./ChangeEmailForm.jsx";
import LocaleSelection from "./LocaleSelection.jsx";
import store from "../../../app/store.js";

/**
 * Modal to group all possible user customizable settings,
 * such as language selection or password change.
 */
class SettingsModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      confirmModalOpen: false,
    };

    this._userSettingsReset = this._userSettingsReset.bind(this);
    this._confirm = this._confirm.bind(this);
    this._toggleConfirm = this._toggleConfirm.bind(this);
  }

  static propTypes = {
    isOpen: PropTypes.bool,
    toggle: PropTypes.func,
    // state
    isProcessingTranslations: PropTypes.bool,
    // action dispatcher
    dispatch: PropTypes.func,
  };

  static defaultProps = { isProcessingTranslations: false };

  _handlePasswordChangeSubmit(values) {
    // Make POST request to change password
    const url = apiUrl("users/change-password", false);

    const requestBody = {
      current_password: values.oldPassword,
      new_password: values.newPassword,
    };

    return Fetcher.rawFetch(url, "POST", requestBody)
      .then((response) => {
        switch (response.status) {
          case 200:
          case 204:
            store.dispatch(notify(tr("passwordUpdated"), "success"));
            return;

          case 401:
            store.dispatch(notify(tr("wrongOldPassword"), "warning"));
            throw new SubmissionError();

          case 409:
            this.props.dispatch(notify(tr("newPasswordSameAsOldPassword"), "warning"));
            throw new SubmissionError();

          default:
            this.props.dispatch(notify(tr("passwordUpdateFailed"), "error"));
            throw new SubmissionError();
        }
      })
      .catch((error) => {
        throw new SubmissionError(error);
      });
  }

  _handleEmailChangeSubmit(values) {
    // Request to change email
    const url = apiUrl("users/request-email-change", false);

    return Fetcher.post(url, values)
      .then((response) => {
        if (response.status === 204) {
          this.props.dispatch(notify(tr("emailChangeRequestSend"), "success"));
        }
      })
      .catch((error) => {
        if (error.status === 401) {
          this.props.dispatch(notify(tr("unauthorized"), "warning"));
        } else {
          this.props.dispatch(notify(tr("emailChangeRequestFailed"), "error"));
        }
        throw new SubmissionError(error);
      });
  }

  _confirm(value) {
    if (value === "confirmed") {
      this._userSettingsReset();
    }

    this._toggleConfirm();
  }

  _toggleConfirm() {
    this.setState((prevState) => ({ confirmModalOpen: !prevState.confirmModalOpen }));
  }

  _userSettingsReset() {
    setUserData("settings", "{}");

    this.props.dispatch(setUserSettings(null));
    this.props.dispatch(notify(tr("settingsCleared"), "success"));
  }

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
        title={tr("settings")}
        className="single-tab-modal"
      >
        <ContentContainer className="modal-content-container">
          <Container>
            <Loading loading={this.props.isProcessingTranslations} />

            <h3>{tr("changeLanguage")}</h3>
            <div className="sub-content">
              <LanguageSelect />
            </div>

            <h3>{tr("locale")}</h3>
            <div className="sub-content">
              <LocaleSelection />
            </div>

            <ChangePasswordForm onSubmit={this._handlePasswordChangeSubmit} />

            <ChangeEmailForm onSubmit={this._handleEmailChangeSubmit} />

            <h3>{tr("textmode")}</h3>
            <div className="sub-content">
              <TextModeSelection />
            </div>

            <div className="sub-content">
              <h3>{tr("clearSettings")}</h3>
              <p>{tr("clearSettingsExplanation")}</p>
              <Button color="danger" className="float-right" onClick={this._toggleConfirm}>
                {tr("clearSettings").toUpperCase()}
              </Button>
            </div>

            <ConfirmationModal
              isOpen={this.state.confirmModalOpen}
              toggle={this._confirm}
              title={tr("confirm")}
              question={`${tr("clearSettings")}?`}
              confirmButtonText={tr("yes")}
              discardButtonText={tr("cancel")}
            />
          </Container>
        </ContentContainer>

        <div className="modal-footer">
          <Button color="default" onClick={this.props.toggle} className="no-margin">
            {tr("close")}
          </Button>
        </div>
      </Modal>
    );
  }
}

SettingsModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  // state
  isProcessingTranslations: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    // translation
    isProcessingTranslations: state.translation.processing,
  };
};

export default connect(mapStateToProps)(SettingsModal);
