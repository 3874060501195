import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";

import { setAuth } from "@app/user/user.action.js";
import { notify } from "@app/notification/notification.action.js";
import { initAvailableTranslations, initTranslation, tr } from "@utils/translation.js";
import { initLocales, initUserLocale } from "@utils/locale.js";
import { validate } from "@utils/auth.js";
import { initPublishers, getUserProperties, getUserSettings, getPaperCodes } from "@utils/user.js";

import Loading from "./components/ui/Loading.jsx";
import GlobalNotification from "./components/notification/index.jsx";
import ProtectedRoute from "./ProtectedRoute.jsx";
import MainContainer from "./components/Main.jsx";

import "@assets/styles/app.scss";

const Login = React.lazy(() => import("./loginPage/index.jsx"));

const AppError = () => {
  // TODO: move these to tranlations
  const errorTitle = "Oops! Something went wrong...";
  const errorMessage = "Try refreshing the page. If that doesn't help, contact the administrator.";

  return (
    <div className="error-view-container">
      <div className="error-view">
        <h2>{errorTitle}</h2>
        <span>{errorMessage}</span>
      </div>
    </div>
  );
};

class App extends Component {
  state = {
    errorState: false,
    initialized: false,
    publishersReady: false,
    settingsReady: false,
    translationReady: false,
    userReady: false,
    paperCodesReady: false,
  };

  _continueIfReady() {
    const ready =
      this.state.userReady &&
      this.state.settingsReady &&
      this.state.translationReady &&
      this.state.publishersReady;
    //  &&
    // this.state.paperCodesReady;

    if (ready) {
      this.setState({ initialized: true });
    }
  }

  _setUserReady() {
    this.setState({ userReady: true }, () => {
      this._continueIfReady();
    });
  }

  _setSettingsReady() {
    this.setState({ settingsReady: true }, () => {
      this._continueIfReady();
    });
  }

  _setPublishersReady(success) {
    if (success) {
      this.setState({ publishersReady: true }, () => {
        this._continueIfReady();
      });
    }
  }

  _setPaperCodesReady(success) {
    if (success) {
      this.setState({ paperCodesReady: true }, () => {
        this._continueIfReady();
      });
    }
  }

  componentDidMount() {
    // initialize application's locales (and set a default locale)
    initLocales();

    // validate authentication status by polling the server
    validate()
      .then((data) => {
        const authenticated = data.authenticated;

        // initialize available translations
        initAvailableTranslations(() => {
          // after available translations are known, set an appropriate translation
          initTranslation(authenticated, () => {
            this.setState({ translationReady: true }, () => {
              this._continueIfReady();
            });
          });
        });

        if (authenticated) {
          initUserLocale();
          getUserProperties(this._setUserReady.bind(this));
          getUserSettings(this._setSettingsReady.bind(this));
          // Build 4.1.2.0(ERP #12346): add userid
          initPublishers(this._setPublishersReady.bind(this), data.userid);
          // getPaperCodes(this._setPaperCodesReady.bind(this));
        } else {
          this.setState({
            publishersReady: true,
            settingsReady: true,
            userReady: true,
            // paperCodesReady:true,
          });
          this._continueIfReady();
        }
        this.props.dispatch(setAuth(data));
      })
      .catch(() => {
        this.props.dispatch(notify(tr("dbConnectFailure"), "error"));
      });
  }

  /*
   * Show a generic "Something went wrong" error message when unrecoverable error occurs.
   * Default behaviour would be to show a blank page, which is not helpful for a normal user
   */
  componentDidCatch() {
    this.setState({ errorState: true });
  }

  render() {
    return this.state.errorState ? (
      <AppError />
    ) : (
      <div className="page-container">
        <GlobalNotification />

        {!this.state.initialized ? (
          <Loading loading={true} />
        ) : (
          <React.Suspense fallback={<Loading loading={true} />}>
            <Switch>
              <Route path="/login" component={Login} />
              <Route path="/reset-password" component={Login} />
              <Route path="/confirm-email" component={Login} />
              <ProtectedRoute component={MainContainer} />
            </Switch>
          </React.Suspense>
        )}
      </div>
    );
  }
}

App.propTypes = {
  // dispatcher
  dispatch: PropTypes.func,
};

export default withRouter(connect()(App));
