import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { SubmissionError } from "redux-form";

import { notify } from "@app/notification/notification.action.js";
import { Fetcher } from "@utils/fetcher";
import apiUrl from "@utils/url.js";
import { tr } from "@utils/translation.js";

import { Container, ContentContainer } from "../ui/layout.jsx";
import Modal from "../ui/Modal.jsx";
import Loading from "../ui/Loading.jsx";
import ChangePasswordForm from "../general/ChangePasswordForm.jsx";
import store from "../../../app/store.js";

/**
 * Modal to group all possible user customizable settings,
 * such as language selection or password change.
 */
class ChangePasswordModal extends React.Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    toggle: PropTypes.func,
    // state
    isProcessingTranslations: PropTypes.bool,
  };

  static defaultProps = { isProcessingTranslations: false };

  _handlePasswordChangeSubmit(values) {
    // Make POST request to change password
    const url = apiUrl("users/change-password", false);

    const requestBody = {
      current_password: values.oldPassword,
      new_password: values.newPassword,
    };

    return Fetcher.rawFetch(url, "POST", requestBody)
      .then((response) => {
        switch (response.status) {
          case 200:
          case 204:
            store.dispatch(notify(tr("passwordUpdated"), "success"));
            this.props.toggle();
            return;

          case 401:
            store.dispatch(notify(tr("wrongOldPassword"), "warning"));
            throw new SubmissionError();

          case 409:
            store.dispatch(notify(tr("newPasswordSameAsOldPassword"), "warning"));
            throw new SubmissionError();

          default:
            store.dispatch(notify(tr("passwordUpdateFailed"), "error"));
            throw new SubmissionError();
        }
      })
      .catch((error) => {
        console.log(error);
        throw new SubmissionError(error);
      });
  }

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={null}
        title={tr("newPassword")}
        className="single-tab-modal"
      >
        <ContentContainer className="modal-content-container">
          <Container>
            <Loading loading={this.props.isProcessingTranslations} />

            <ChangePasswordForm
              onSubmit={this._handlePasswordChangeSubmit.bind(this)}
              hideHeader={true}
            />
          </Container>
        </ContentContainer>
      </Modal>
    );
  }
}

ChangePasswordModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  // state
  isProcessingTranslations: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    // translation
    isProcessingTranslations: state.translation.processing,
  };
};

export default connect(mapStateToProps)(ChangePasswordModal);
