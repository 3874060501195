import { Fetcher, fetchWithRetry } from "@utils/fetcher.js";
import apiUrl from "@utils/url.js";

export async function fetchAllUsers() {
  const url = apiUrl("users/all-users", false);

  return fetchWithRetry("getJson", [url])
    .then((users = {}) => {
      return users;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function fetchUserProperties(id) {
  const url = apiUrl(`users/properties/${id}`, false);

  return fetchWithRetry("getJson", [url])
    .then((user = {}) => {
      return user;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function postUser(userData, isUserToProvider = false) {
  const url = apiUrl("users", isUserToProvider);
  const user = {
    user_id: userData.userId,
    password: userData.password,
    user_name: userData.userName,
    mark: userData.mark,
    email_address: userData.emailAddress,
    activated: true,
    chgpwd_nextlogin: userData.forcePwChange ? "Y" : "N", // Build 4.1.1.0 (ERP #12017)
  };

  return Fetcher.post(url, { user })
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function deleteUser(id) {
  const url = apiUrl(`users/${id}`, false);

  return Fetcher.delete(url)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function fetchAllGroups() {
  const url = apiUrl("users/user-groups", false);

  return fetchWithRetry("getJson", [url])
    .then((groups = {}) => {
      return groups;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function postGroup(groupData) {
  const url = apiUrl("users/user-groups", false);
  const group = {
    group_id: groupData.groupId,
    group_name: groupData.groupName,
  };

  return Fetcher.post(url, { group })
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.error(error);
    });
}

export async function deleteGroup(id) {
  const url = apiUrl(`users/user-groups/${id}`, false);

  return Fetcher.delete(url)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function insertUserUserOfGroup(group) {
  const url = apiUrl(`users/user-groups/user-of-group`, false);

  return Fetcher.post(url, { group }).catch((error) => {
    console.error(error);
  });
}

export async function removeUserUserOfGroup(group) {
  const url = apiUrl(`users/user-groups/delete-user-of-group`, false);

  return Fetcher.post(url, { group }).catch((error) => {
    console.error(error);
  });
}

export async function validateUserInProvider(id) {
  const url = apiUrl(`users/${id}/valid`, true);

  return fetchWithRetry("getJson", [url])
    .then((user = {}) => {
      return user;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}
