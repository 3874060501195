import React from "react";
import PropTypes from "prop-types";

import { Input as ReactstrapInput } from "reactstrap";
import { isValidTime,isAllowedTimeChar } from "@utils/validate.js";

/**
 * Reusable TimeInput component. Used to collect 24h format time (hh:mm).
 * Component will automatically add colon separator (:) after first two
 * digits have been entered by the user. When input loses focus, final
 * validation will be done to verify that user has entered appropriate
 * input. If input is not valid, input will be automatically cleared.
 *
 * Usage example redux-form:
 * ```
 * <Field
 *   name='start'
 *   component={FormInput}
 *   type='time'
 *   label='Start time'
 * />
 * ```
 *
 * Usage example alone:
 * ```
 * <TimeInput
 *   onChange={this.handleTime}
 *   value={this.state.time}
 *   placeholder='Time field'
 * />
 * ```
 */
class TimeInput extends React.Component {
  state = { time: this.props.value || "" };

  _handleTimeChange = (e) => {
    if (!isAllowedTimeChar(e.target.value)  ) {
      return;
    }
    let input = e.target.value;
    const onChange = this.props.input ? this.props.input.onChange : this.props.onChange; // redux-form check

    // Having state also evaluated, it is possible to clear the input field by user
    if (input.length === 2 && this.state.time.length === 1) {
      input = input + ":";
      this.setState({ time: input });
      onChange(input);
    } else {
      this.setState({ time: input });
      onChange(input);
    }
  };

  // Check that given input is in correct format, otherwise clear the time input
  _handleBlur = (e) => {
    const input = e.target.value;
    const onChange = this.props.input ? this.props.input.onChange : this.props.onChange; // redux-form check

    if (isValidTime(input)) {
      this.setState({ time: input });
      onChange(input);
    } else {
      this.setState({ time: "" });
      onChange("");
    }
  };

  render = () => {
    const { input, onChange, ...rest } = this.props;

    return (
      <ReactstrapInput
        {...rest}
        value={input ? input.value : this.props.value} // redux-form has input.value prop instead of value
        onChange={this._handleTimeChange}
        onBlur={this._handleBlur}
        type="text"
      />
    );
  };
}

TimeInput.propTypes = {
  value: PropTypes.string,
  input: PropTypes.string,
  onChange: PropTypes.func,
};

export default TimeInput;
