import { postImage } from "@app/imageBank/imageBank.effect.js";
import { notify } from "@app/notification/notification.action.js";
import * as actionCreator from "@utils/actionCreator.js";
import { tr } from "@utils/translation.js";
import * as effect from "../priceGroup/priceGroup.effect.js";

const SLICE = "price";

export const ADMIN_PRICE_IS_LOADING_ALL_PRICE_GROUPS = `${SLICE}/IS_LOADING_ALL_PRICE_GROUPS`;
export const ADMIN_PRICE_SET_ALL_PRICE_GROUPS = `${SLICE}/SET_ALL_PRICE_GROUPS`;
export const requestAllPriceGroups = () => async (dispatch) => {

  dispatch(actionCreator.createAction(ADMIN_PRICE_IS_LOADING_ALL_PRICE_GROUPS));

  const response = await effect.fetchPriceGroups();
  if (response.error) {
    dispatch(actionCreator.createAction(ADMIN_PRICE_SET_ALL_PRICE_GROUPS, []));
    dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }
  dispatch(actionCreator.createAction(ADMIN_PRICE_SET_ALL_PRICE_GROUPS, response));
};

export const addPriceGroup = (priceGroup, callback = () => { }) => async (dispatch) => {

  const response = await effect.postPriceGroup(priceGroup);
  if (response.error) {
    dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }

  callback();
  dispatch(notify(tr("priceGroupAdded"), "success"));
  dispatch(requestAllPriceGroups());
};


export const ADMIN_PRICE_PRICE_GROUP_EDITED = `${SLICE}/PRICE_GROUP_EDITED`;
export const editPriceGroup = (priceGroup, callback = () => { }) => async (dispatch, getState) => {

  const response = await effect.putPriceGroup(priceGroup);
  if (response.error) {
    dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }
  callback();
  dispatch(notify(tr("priceGroupEdited"), "success"));
  dispatch(actionCreator.createAction(ADMIN_PRICE_PRICE_GROUP_EDITED, priceGroup));
  dispatch(requestAllPriceGroups());

};

export const ADMIN_PRICE_SET_REMOVING_PRICE_GROUP = `${SLICE}/SET_REMOVING_PRICE_GROUP`;
export const ADMIN_PRICE_PRICE_GROUP_REMOVED = `${SLICE}/PRICE_GROUP_REMOVED`;

export const removePriceGroup = (priceGroup, callback = () => { }) => async (dispatch) => {

  dispatch(actionCreator.createAction(ADMIN_PRICE_SET_REMOVING_PRICE_GROUP, true));

  const response = await effect.deletePriceGroup(priceGroup);
  if (response.error) {
    dispatch(notify(tr("dbConnectFailure"), "error"));
    dispatch(actionCreator.createAction(ADMIN_PRICE_SET_REMOVING_PRICE_GROUP, false));
    return;
  }
  callback();
  dispatch(notify(tr("priceGroupRemoved"), "warning"));
  dispatch(actionCreator.createAction(ADMIN_PRICE_SET_REMOVING_PRICE_GROUP, false));
  dispatch(actionCreator.createAction(ADMIN_PRICE_PRICE_GROUP_REMOVED, priceGroup));
  dispatch(requestAllPriceGroups());

};


