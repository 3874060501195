import React from "react";
import { DropdownToggle, DropdownMenu, DropdownItem, Dropdown } from "reactstrap";
import PropTypes from "prop-types";

import { tr } from "@utils/translation.js";

import SettingsModal from "../settings/SettingsModal.jsx";
import ChangePasswordModal from "../settings/ChangePasswordModal.jsx";
import store from "../../../app/store.js";
import { setForceResetPassword } from "@app/user/user.action.js";

/*
 * Dropdown menu component used in the top header.
 */
class HeaderMenu extends React.Component {
  _toggleMenu() {
    this.setState({ menuOpen: !this.state.menuOpen });
  }

  _toggleModal() {
    this.setState({ modalOpen: !this.state.modalOpen });
  }

  constructor(props) {
    super(props);
    this.state = { menuOpen: false, modalOpen: false, forceChgPwdModalOpen: false };
  }

  componentDidUpdate(prevProps, prevState) {
    // Build 4.1.1.0 (ERP #12017):
    if (this.props.chgPwdOpen !== prevProps.chgPwdOpen) {
      this.setState({ forceChgPwdModalOpen: this.props.chgPwdOpen });
    }
  }

  componentDidMount() {
    // Build 4.1.1.0 (ERP #12017)
    if (this.state.forceChgPwdModalOpen !== this.props.chgPwdOpen) {
      this.setState({ forceChgPwdModalOpen: this.props.chgPwdOpen });
    }
  }

  // Build 4.1.1.0 (ERP #12017)
  _toggleChgPassword() {
    store.dispatch(setForceResetPassword(false));
  }

  render() {
    return (
      <div
        id="header-menu"
        className={this.state.menuOpen ? "header-button active" : "header-button"}
      >
        <SettingsModal isOpen={this.state.modalOpen} toggle={this._toggleModal.bind(this)} />
        {/* Build 4.1.1.0 (ERP #12017): add modal to change password here */}
        <ChangePasswordModal
          isOpen={this.state.forceChgPwdModalOpen}
          toggle={this._toggleChgPassword.bind(this)}
        />
        <Dropdown nav isOpen={this.state.menuOpen} toggle={this._toggleMenu.bind(this)}>
          <DropdownToggle nav caret>
            <span>
              <i className="icon ion-md-settings pr-2"></i>
              {this.props.user.username}
            </span>
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={this._toggleModal.bind(this)}>{tr("settings")}</DropdownItem>
            <DropdownItem divider />
            <DropdownItem onClick={this.props.logout}>{tr("logout")}</DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  }
}

HeaderMenu.propTypes = {
  logout: PropTypes.func.isRequired, // A function to call on logout
  user: PropTypes.object.isRequired, // An object containing user information
  chgPwdOpen: PropTypes.bool.isRequired,
};

export default HeaderMenu;
