import React from "react";
import PropTypes from "prop-types";
import Draggable from "react-draggable";
import { Modal as ReactstrapModal, ModalBody, ModalHeader } from "reactstrap";

/**
 * Reusable Modal component that can be used to build context specific modals.
 *
 * Usage example:
 * ```
 * <Modal
 *   isOpen={this.props.isOpen}
 *   toggle={this.props.toggle}
 *   title='Title'
 *   classes='modal-lg'
 * >
 *   Content...
 * </Modal>
 * ```
 */
class Modal extends React.Component {
  // modals `onClosed()` gets called after transitioning out (animation ends)
  _onClosed() {
    this.setState({ mounted: false });
  }

  constructor(props) {
    super(props);

    /*
     * Modal component will keep a track of its own "mounted" state. This is because
     * Reactstrap Modal's inner component is always mounted when its parent component
     * is mounted, which causes a Modal to always call render even though it's not visible.
     *
     * This Reactstrap behaviour caused issues in the past, but now is should work properly.
     * However, because preventing further rendering when not mounted potentially improves
     * performance, this workaround is left intact.
     */
    this.state = { mounted: false };

    this._onClosed = this._onClosed.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.isOpen && !prevProps.isOpen) {
      this.setState({ mounted: true });
    }
  }

  render() {
    if (!this.state.mounted) {
      return null;
    }

    const { toggle, title, children, backdrop = "static", ...rest } = this.props;

    return (
      <Draggable cancel=".enable-user-select">
        <ReactstrapModal {...rest} toggle={toggle} onClosed={this._onClosed} backdrop={backdrop}>
          <ModalHeader toggle={toggle}>
            <span className="enable-user-select">{title}</span>
          </ModalHeader>
          <div className="enable-user-select">
            <ModalBody>{children}</ModalBody>
          </div>
        </ReactstrapModal>
      </Draggable>
    );
  }
}

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired, // flag to determine is modal open or not
  // Build 3.6.4.2 (ERP #12017): remove toggle.isRequired, sometimes we don't need X mark
  toggle: PropTypes.func, // toggle modal (giving this prop adds X mark on modal header)
  title: PropTypes.string.isRequired, // title text displayed on modal's header
  // bootstrap backdrop which can be disable if needed by setting this prop to be false
  backdrop: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(["static"])]),
  classes: PropTypes.string, // pass in css classes for example modal-lg
  children: PropTypes.node,
};

export default Modal;
