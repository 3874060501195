import { postImage } from "@app/imageBank/imageBank.effect.js";
import { notify } from "@app/notification/notification.action.js";
import * as actionCreator from "@utils/actionCreator.js";
import { tr } from "@utils/translation.js";
import * as effect from "./priceList.effect.js";

const SLICE = "price";

export const ADMIN_PRICE_IS_LOADING_ALL_PRICE_LIST = `${SLICE}/IS_LOADING_ALL_PRICE_LIST`;
export const ADMIN_PRICE_SET_ALL_PRICE_LIST = `${SLICE}/SET_ALL_PRICE_LIST`;
export const requestPriceList = () => async (dispatch) => {

  dispatch(actionCreator.createAction(ADMIN_PRICE_IS_LOADING_ALL_PRICE_LIST));

  const response = await effect.fetchPriceList();
  if (response.error) {
    dispatch(actionCreator.createAction(ADMIN_PRICE_SET_ALL_PRICE_LIST, []));
    dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }
  dispatch(actionCreator.createAction(ADMIN_PRICE_SET_ALL_PRICE_LIST, response));
};

export const addPriceList = (priceList, callback = () => { }) => async (dispatch) => {

  const response = await effect.postPriceList(priceList);
  if (response.error) {
    dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }

  callback();
  dispatch(notify(tr("priceListAdded"), "success"));
  dispatch(requestPriceList());
};


export const ADMIN_PRICE_PRICE_LIST_EDITED = `${SLICE}/PRICE_LIST_EDITED`;
export const editPriceList = (priceList, callback = () => { }) => async (dispatch, getState) => {

  const response = await effect.putPriceList(priceList);
  if (response.error) {
    dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }
  callback();
  dispatch(notify(tr("priceListEdited"), "success"));
  dispatch(actionCreator.createAction(ADMIN_PRICE_PRICE_LIST_EDITED, priceList));
  dispatch(requestPriceList());

};

export const ADMIN_PRICE_SET_REMOVING_PRICE_LIST = `${SLICE}/SET_REMOVING_PRICE_LIST`;
export const ADMIN_PRICE_PRICE_LIST_REMOVED = `${SLICE}/PRICE_LIST_REMOVED`;
export const removePriceList = (priceList, callback = () => { }) => async (dispatch) => {
  dispatch(actionCreator.createAction(ADMIN_PRICE_SET_REMOVING_PRICE_LIST, true));

  const response = await effect.deletePriceList(priceList);
  if (response.error) {
    dispatch(notify(tr("dbConnectFailure"), "error"));
    dispatch(actionCreator.createAction(ADMIN_PRICE_SET_REMOVING_PRICE_LIST, false));
    return;
  }
  callback();
  dispatch(notify(tr("priceListRemoved"), "warning"));
  dispatch(actionCreator.createAction(ADMIN_PRICE_SET_REMOVING_PRICE_LIST, false));
  dispatch(actionCreator.createAction(ADMIN_PRICE_PRICE_LIST_REMOVED, priceList));
};