import { postImage } from "@app/imageBank/imageBank.effect.js";
import { notify } from "@app/notification/notification.action.js";
import * as actionCreator from "@utils/actionCreator.js";
import { tr } from "@utils/translation.js";
import * as effect from "./packageProducts.effect.js";
import * as productEffect from "./products.effect.js";

const SLICE = "products";

// Package Products actions

export const ADMIN_PRODUCT_IS_LOADING_ALL_PRODUCTS_LIST = `${SLICE}/IS_LOADING_ALL_PRODUCTS_LIST`;
export const ADMIN_PRODUCT_SET_ALL_PRODUCTS_LIST = `${SLICE}/SET_ALL_PRODUCTS_LIST`;
export const ADMIN_PRODUCT_IS_LOADING_PACKAGE_PRODUCTS = `${SLICE}/IS_LOADING_PACKAGE_PRODUCTS`;
export const ADMIN_PRODUCT_SET_ALL_PACKAGE_PRODUCTS = `${SLICE}/SET_ALL_PACKAGE_PRODUCTS`;
export const requestAllPackageProducts = (productPackage) => async (dispatch) => {

  dispatch(actionCreator.createAction(ADMIN_PRODUCT_IS_LOADING_PACKAGE_PRODUCTS));

  const productsList = await productEffect.fetchAllProducts();
  if (productsList.error) {
    dispatch(actionCreator.createAction(ADMIN_PRODUCT_SET_ALL_PRODUCTS_LIST, []));
    return;
  }
  dispatch(actionCreator.createAction(ADMIN_PRODUCT_SET_ALL_PRODUCTS_LIST, productsList));

  const response = await effect.fetchAllPackageProducts(productPackage);
  if (response.error) {
    dispatch(actionCreator.createAction(ADMIN_PRODUCT_SET_ALL_PACKAGE_PRODUCTS, []));
    return;
  }
  dispatch(actionCreator.createAction(ADMIN_PRODUCT_SET_ALL_PACKAGE_PRODUCTS, response));
};

export const addPackageProducts = (packageProducts, callback = () => { }) => async (dispatch) => {

  const response = await effect.postPackageProducts(packageProducts);
  if (response.error) {
    dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }
  callback();
  dispatch(notify(tr("packageProductsAdded"), "success"));
  packageProducts.packageId = packageProducts.packageid;
  dispatch(requestAllPackageProducts(packageProducts));
}

export const ADMIN_PRODUCT_SET_REMOVING_PACKAGE_PRODUCTS = `${SLICE}/SET_REMOVING_PACKAGE_PRODUCTS`;
export const ADMIN_PRODUCT_PACKAGE_PRODUCTS_REMOVED = `${SLICE}/PACKAGE_PRODUCTS_REMOVED`;
export const removePackageProducts = (packageProducts, callback = () => { }) => async (dispatch) => {

  dispatch(actionCreator.createAction(ADMIN_PRODUCT_SET_REMOVING_PACKAGE_PRODUCTS, true));

  const response = await effect.deletePackageProducts(packageProducts);
  if (response.error) {
    dispatch(notify(tr("dbConnectFailure"), "error"));
    dispatch(actionCreator.createAction(ADMIN_PRODUCT_SET_REMOVING_PACKAGE_PRODUCTS, false));
    return;
  }

  callback();
  dispatch(notify(tr("packageProductsRemoved"), "warning"));
  dispatch(actionCreator.createAction(ADMIN_PRODUCT_SET_REMOVING_PACKAGE_PRODUCTS, false));
  dispatch(actionCreator.createAction(ADMIN_PRODUCT_PACKAGE_PRODUCTS_REMOVED, packageProducts));
};

export const ADMIN_PRODUCT_PACKAGE_PRODUCTS_EDITED = `${SLICE}/PRODUCT_PACKAGE_PRODUCTS_EDITED`;
export const editPackageProducts = (packageProducts, callback = () => { }) => async (dispatch) => {
  console.log("packageidHAS", packageProducts)
  const response = await effect.putPackageProducts(packageProducts);
  if (response.error) {
    dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }
  callback();
  dispatch(notify(tr("productPackagesEdited"), "success"));
  packageProducts.packageId = packageProducts.packageid;
  dispatch(requestAllPackageProducts(packageProducts));

};


