import { removeNullValues, resolveFieldValueFromObject } from "@utils/common.js";
import { Fetcher, fetchWithRetry } from "@utils/fetcher.js";
import apiUrl from "@utils/url.js";


export async function fetchCampaignsList(params) {
  const url = apiUrl(`active-campaigns${params}`);

  return fetchWithRetry("getJson", [url])
    .then((campaigns = []) => {
      return campaigns;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}
export async function createCampaign(campaign) {
  const url = apiUrl(`campaigns/campaign`);
  let payload = campaign;

  return Fetcher.post(url, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}
export async function createCampaignCoproduct(campaigncoproduct, campno) {
  const url = apiUrl(`campaign-co-product/${campno}`);
  let payload = campaigncoproduct;

  return Fetcher.post(url, payload)
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}
export async function fetchCampaignsGroups() {
  const url = apiUrl(`campaigns-group`);

  return fetchWithRetry("getJson", [url])
    .then((campaignGroups = []) => {
      return campaignGroups;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}
export async function searchCampaign(params) {
  const url = apiUrl(`search-campaigns${params}`);

  return fetchWithRetry("getJson", [url])
    .then((campaigns = []) => {
      return campaigns;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}
export async function fetchPreviousCampaignsList(campno) {
  const url = apiUrl(`previous-campaigns/${campno}`);

  return fetchWithRetry("getJson", [url])
    .then((previousCampaigns = []) => {
      return previousCampaigns;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}
export async function fetchCoproducts() {
  const url = apiUrl(`/subscriptions/extra-products?subsCusNo=0&paperCode=ALL&orderDate=01%2F01%2F1800`);

  return fetchWithRetry("getJson", [url])
    .then((previousCampaigns = []) => {
      return previousCampaigns;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}
export async function fetchGoodsServicesList(campid) {
  const url = apiUrl(`campaign-co-products/${campid}`);

  return fetchWithRetry("getJson", [url])
    .then((GoodsServices = []) => {
      return GoodsServices;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}
export async function fetchProductPackages() {
  const url = apiUrl(`package`);

  return fetchWithRetry("getJson", [url])
    .then((ProductPackages = []) => {
      return ProductPackages;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}
export async function fetchAllPapers() {
  const url = apiUrl(`papers`);

  return fetchWithRetry("getJson", [url])
    .then((ProductPackages = []) => {
      return ProductPackages;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}
export async function fetchSinglePackageProduct(packageid) {
  const url = apiUrl(`packageproducts/${packageid}`);

  return fetchWithRetry("getJson", [url])
    .then((PackageProduct = []) => {
      return PackageProduct;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function fetchSingleCampaign(campno) {
  const url = apiUrl(`campaigns/${campno}`);

  return fetchWithRetry("getJson", [url])
    .then((campaign = []) => {
      return campaign;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function deleteCampaign(campno) {
  const url = apiUrl(`campaign/${campno}`);

  return Fetcher.delete(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
export async function deleteCampaignCoproduct(campno, papercode, productcode) {
  const url = apiUrl(`campaign-co-product/${campno}/${papercode}/${productcode}`);

  return Fetcher.delete(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export async function editCampaign(campaign) {
  const url = apiUrl(`campaigns/campaign`);
  let payload = campaign;

  return Fetcher.put(url, payload)
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function editCampaignCoproduct(campaign, campno) {
  const url = apiUrl(`campaign-co-product/${campno}`);
  let payload = campaign;

  return Fetcher.put(url, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}