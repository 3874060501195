import * as actions from "../actions/actionTypes.js";

const defaultState = {
  productChangePreview: null,
  productChangeValid: false,
  stagedProductChange: {},
  targetGroups: [{ id: "", name: "-" }],
  publicationDays: [], // Build 4.1.0.0 (ERP #10140)
  publishingDaysDate: [], // Build 4.1.0.0 (ERP #10140)
  dynamicProducts: [], // Build 4.1.1.0 (ERP #11124)
  salesmanList: [], // Build 4.1.1.0 (ERP #11124)
};

const subscriptionReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actions.SUBS_SET_TARGET_GROUPS: {
      const targetGroups = action.payload.map((group) => ({
        ...group,
        id: group.codeno,
        name: `${group.codeno} ${group.codevalue}`,
      }));
      return { ...state, targetGroups: [...state.targetGroups, ...targetGroups] };
    }
    case actions._SUBS_SET_STAGED_PRODUCT_CHANGE: {
      return { ...state, stagedProductChange: action.payload };
    }
    case actions._SUBS_SET_CHANGED_PRODUCT_PREVIEW: {
      return { ...state, productChangePreview: action.payload };
    }
    case actions._SUBS_SET_PRODUCT_CHANGE_VALID: {
      return { ...state, productChangeValid: true };
    }
    // Build 4.1.2.0 (ERP #10512)
    case actions._SUBS_CLEAR_PRODUCT_CHANGE_PREVIEW: {
      return {
        ...state,
        stagedProductChange: {},
        productChangePreview: {},
        productChangeValid: false,
      };
    }
    // Build 4.1.0.0 (ERP #10140)
    case actions.SET_PUBLICATION_DAYS: {
      return { ...state, publicationDays: action.publicationDays };
    }
    // Build 4.1.0.0 (ERP #10140)
    case actions.SET_PUBLISHINGDAYSDATE: {
      return { ...state, publishingDaysDate: action.publishingDaysDate };
    }
    // Build 4.1.1.0 (ERP #11124)
    case actions.LOAD_AVAILABLE_DYNAMIC_PRODUCTS: {
      return { ...state, dynamicProducts: action.dynamicProducts };
    }
    // Build 4.1.1.0 (ERP #11124)
    case actions.SET_SALESMANLIST: {
      return { ...state, salesmanList: action.salesmanList };
    }
    default:
      return state;
  }
};

export default subscriptionReducer;
