import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import * as translation from "@utils/translation.js";

import Dropdown from "../ui/Dropdown.jsx";

/*
 * TextModeSelection renders a dropdown with available textmode as items.
 * It uses Redux store and translation utilities to get available translation and
 * to initiate translation shown format(translation only, translation with textno, textno) change event.
 *
 * Usage:
 * ```
 * <TextModeSelection />
 * ```
 */
class TextModeSelection extends React.Component {
  _textModeChange(textModeText) {
    const textMode = this._textModeCode(textModeText);
    if (this.props.current !== textMode) {
      translation.switchTextMode(textMode);
    }
  }

  _textModeCode(textModeText) {
    for (const code in this.props.textModes) {
      if (this.props.textModes[code] === textModeText) {
        return code;
      }
    }
  }

  render() {
    return (
      <Dropdown
        className="language-selection"
        sendSelection={this._textModeChange.bind(this)}
        items={this.props.textModeTexts}
        placeholder={this.props.textModes[this.props.current]}
      />
    );
  }
}

TextModeSelection.propTypes = {
  current: PropTypes.string.isRequired,
  textModes: PropTypes.object.isRequired,
  textModeTexts: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => {
  const current = state.translation.translationDebugMode;
  const textModes = state.translation.translationDebugModes;
  const textModeTexts = Object.keys(textModes).map((code) => {
    return textModes[code];
  });

  return { current, textModes, textModeTexts };
};

export default connect(mapStateToProps)(TextModeSelection);
