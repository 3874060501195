import React from "react";
/* import { connect } from 'react-redux';
import PropTypes from 'prop-types'; */

import NewReportModal from "./NewReportModal.jsx";
import { ContentContainer } from "@views/components/ui/layout.jsx";
import { SubmitButton } from "@views/components/ui/buttons.jsx";

class DummyContainer extends React.Component {
  constructor(props) {
    super(props);

    this.title = "A very dummy container";
    this.state = {
      loading: false,
      newReportModalOpen: false,
    };
  }

  _newReportModalToggle() {
    this.setState({ newReportModalOpen: !this.state.newReportModalOpen });
  }

  render() {
    return (
      <ContentContainer id="dummy-temp-container">
        <NewReportModal
          isOpen={this.state.newReportModalOpen}
          toggle={this._newReportModalToggle.bind(this)}
          title="NEW REPORT"
        />
        <SubmitButton
          color="primary"
          onClick={this._newReportModalToggle.bind(this)}
          text={"New Report"}
        />
      </ContentContainer>
    );
  }
}

export default DummyContainer;
