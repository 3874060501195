import * as actionCreator from "@utils/actionCreator.js";

const SLICE = "email";

export const NEW_EMAIL = `${SLICE}/NEW_EMAIL`;
export const newEmail = (recipients = [], subject = "", body = "") => {
  return actionCreator.createAction(NEW_EMAIL, { recipients, subject, body });
};

export const CLEAR_EMAIL = `${SLICE}/CLEAR_EMAIL`;
export const clearEmail = () => {
  return actionCreator.createAction(CLEAR_EMAIL);
};

export const CLOSE_EMAIL = `${SLICE}/CLOSE_EMAIL`;
export const closeEmail = () => {
  return actionCreator.createAction(CLOSE_EMAIL);
};
