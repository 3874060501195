import * as actions from "./users.action.js";

const defaultState = {
  initialized: false,
  // Users
  users: [],
  usersLoading: false,
  userProperties: null,
  userPropertiesLoading: false,
  removingUser: false,
  // Groups
  groups: [],
  groupsLoading: false,
  removingGroup: false,
  updatingUserUserOfGroups: false,
  isUserInProvider: null,
  isUserInProviderLoading: false,
};

const adminReducer = (state = defaultState, action) => {
  const { type, payload } = action;

  switch (type) {
    case actions.ADMIN_USERS_INITIALIZED: {
      return { ...state, initialized: true };
    }

    /**
     * Users
     */

    case actions.ADMIN_USERS_IS_LOADING_USERS: {
      return { ...state, users: [], usersLoading: true };
    }

    case actions.ADMIN_USERS_SET_ALL_USERS: {
      return { ...state, users: payload, usersLoading: false };
    }

    case actions.ADMIN_USERS_IS_LOADING_USER_PROPERTIES: {
      return { ...state, userPropertiesLoading: true };
    }

    case actions.ADMIN_USERS_SET_USER_PROPERTIES: {
      return { ...state, userProperties: payload, userPropertiesLoading: false };
    }

    case actions.ADMIN_USERS_LOADING_USER_PROPERTIES_FAILED: {
      return { ...state, userProperties: null, userPropertiesLoading: false };
    }

    case actions.ADMIN_USERS_SET_REMOVING_USER: {
      return { ...state, removingUser: payload || false };
    }

    case actions.ADMIN_USERS_USER_REMOVED: {
      const users = state.users.filter((u) => u.USERID !== payload.USERID);
      return { ...state, users };
    }

    /**
     * Groups
     */

    case actions.ADMIN_USERS_IS_LOADING_GROUPS: {
      return { ...state, groups: [], groupsLoading: true };
    }

    case actions.ADMIN_USERS_SET_ALL_GROUPS: {
      const groups = payload.map((g) => ({ ...g, value: g.GROUPID, label: g.GROUPID }));
      return { ...state, groups, groupsLoading: false };
    }

    case actions.ADMIN_USERS_SET_REMOVING_GROUP: {
      return { ...state, removingGroup: payload || false };
    }

    case actions.ADMIN_USERS_GROUP_REMOVED: {
      const groups = state.groups.filter((g) => g.GROUPID !== payload.GROUPID);
      return { ...state, groups };
    }

    case actions.ADMIN_USERS_UPDATING_USER_USER_OF_GROUPS: {
      return { ...state, updatingUserUserOfGroups: payload };
    }

    
    case actions.ADMIN_USERS_IS_LOADING_VALIDATE_USER_PROVIDER: {
      return { ...state, isUserInProviderLoading: true };
    }

    case actions.ADMIN_USERS_SET_VALIDATE_USER_PROVIDER: {
      return { ...state, isUserInProvider: payload, isUserInProviderLoading: false };
    }

    case actions.ADMIN_USERS_LOADING_VALIDATE_USER_PROVIDER_FAILED: {
      return { ...state, isUserInProvider: null, isUserInProviderLoading: false };
    }

    default:
      return state;
  }
};

export default adminReducer;
