import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

// Suppress prop-types warning on Route component when using with React.lazy
if (Route.propTypes == undefined) // Fixed error when loading app in deployed env
{
  Route.propTypes = PropTypes.object;
}
Route.propTypes.component = PropTypes.oneOfType([Route.propTypes.component, PropTypes.object]);

/**
 * PrivateRoute is the parent component for every component that needs authentication.
 * It checks authentication status and either redirects to the login page or to a given component.
 */
const PrivateRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = useSelector((state) => state.user.authenticated);

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([Route.propTypes.component, PropTypes.object]),
  location: PropTypes.object,
};

export default PrivateRoute;
