import { notify } from "@app/notification/notification.action.js";
import * as effect from "./imageBank.effect.js";
import * as actionCreator from "@utils/actionCreator.js";
import { tr } from "@utils/translation.js";

const SLICE = "imageBank";

export const IMAGE_BANK_IS_LOADING_IMAGE = `${SLICE}/IS_LOADING_IMAGE`;
export const IMAGE_BANK_SET_IMAGE = `${SLICE}/SET_IMAGE`;
export const requestImageById = (imageId) => async (dispatch) => {
  dispatch(actionCreator.createAction(IMAGE_BANK_IS_LOADING_IMAGE));
  const response = await effect.fetchImageById(imageId);
  if (response.error) {
    dispatch(actionCreator.createAction(IMAGE_BANK_SET_IMAGE, null));
    return;
  }
  dispatch(actionCreator.createAction(IMAGE_BANK_SET_IMAGE, response));
};


export const addImage = (image, callback = () => { }) => async (dispatch) => {
  const response = await effect.postImage(image);
  if (response.error) {
    return response;
  }
  callback();
  return response;
};

export const IMAGE_BANK_CLEAR_IMAGE = `${SLICE}/CLEAR_IMAGE`;
export const clearImageInStore = (callback = () => { }) => async (dispatch) => {
  callback();
  dispatch(actionCreator.createAction(IMAGE_BANK_CLEAR_IMAGE));
};