import * as actions from "./translations.action.js";

// define CONSTANTS in all caps
const TRANSLATION_DEBUG_MODES = {
  TRANSLATION_ONLY: "Only translation",
  TRANSLATION_AND_CODE: "Translation and code",
  ONLY_CODE: "Only code",
};

const defaultState = {
  available: {},
  current: null,
  processing: false,
  translations: {},
  translationDebugMode: "TRANSLATION_ONLY",
  translationDebugModes: TRANSLATION_DEBUG_MODES,
};

const translationsReducer = (state = defaultState, action) => {
  const { type, payload } = action;

  switch (type) {
    case actions.SET_PROCESSING:
      return { ...state, processing: payload };

    case actions.SET_TRANSLATION: {
      const { langCode, values } = payload;
      const newTranslations = { ...state.translations, [langCode]: values };

      return { ...state, translations: newTranslations };
    }

    case actions.SWITCH_TRANSLATION: {
      const langCode = payload;
      if (langCode in state.translations) {
        return { ...state, current: langCode };
      }

      return state;
    }

    case actions.SET_AVAILABLE_TRANSLATIONS:
      return { ...state, available: payload };

    case actions.SET_TRANSLATION_DEBUG_MODE:
      return { ...state, translationDebugMode: payload };

    default:
      return state;
  }
};

export default translationsReducer;
