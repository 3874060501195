import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push, go } from "connected-react-router";

import store from "@app/store.js";
import { notify } from "@app/notification/notification.action.js";
import {
  changePublisher,
  setAuth,
  getUserDefaults,
  getParrowUserSettings,
} from "@app/user/user.action.js";
import { getSystemParameters } from "../../../actions/parameter.js";
import {
  addRecentCustomer,
  getRecentCustomers,
  getCancelReasons,
  shouldClearCustomer,
  setIsSearchingCustomer,
  setCustomerSearchResult,
  clearCustomer,
  setHistorySelection,
} from "../../../actions/customer.js";
import { getVersionInformation } from "../../../actions/version.js";
import { Fetcher } from "@utils/fetcher.js";
import apiUrl from "@utils/url.js";
import { tr } from "@utils/translation.js";
import { logout } from "@utils/auth.js";
import { setUserData } from "@utils/user.js";

import Header from "./Header.jsx";

/*
 * Application's top header which is always visible.
 */
class HeaderContainer extends React.Component {
  _onLogoClick() {
    store.dispatch(shouldClearCustomer(true));
    store.dispatch(push("/"));
  }

  // Selected customer changed so redirect to the customer page and load customer data
  _historySelection(item) {
    // #30631, check if customer is valid to show for current user
    store.dispatch(setIsSearchingCustomer(false));
    const url = apiUrl("customers/search");
    const requestBody = {
      cusno: item.cusno,
    };

    // Build 3.4.6.2 (Issue #33409)
    store.dispatch(setHistorySelection(parseInt(item.cusno)));

    return Fetcher.postJson(url, requestBody)
      .then((data) => {
        if (Object.keys(data).length <= 0) {
          // #30612, let wk load the url
          store.dispatch(clearCustomer());
          store.dispatch(push("/customer/"));
          store.dispatch(setCustomerSearchResult([]));
          return;
        }

        // #30612, reload page even it is the same customer
        if (store.getState().customer.selectedCustomer.cusno === item.cusno) {
          store.dispatch(go(`/customer/${item.cusno}`));
        } else {
          store.dispatch(push(`/customer/${item.cusno}`));
        }
      })
      .catch(() => {});
  }

  _publisherChanged(ev) {
    const publisher = ev.target.getAttribute("data-value");

    // Abort if the currently selected publisher is clicked (i.e. nothing to do)
    if (this.props.current === publisher) {
      return;
    }

    // Update document title with selected publisher
    document.title = publisher;

    store.dispatch(changePublisher(publisher));
    store.dispatch(notify(tr("publisherChanged"), "success"));

    // Save the selected publisher to user database
    setUserData("publisher", publisher);
    // Get publisher specific subscription cancel reasons
    store.dispatch(getCancelReasons());
    // Get, filter, and parse publisher specific system parameters
    store.dispatch(getSystemParameters());

    // When the current publisher is changed, redirect the user to the index page.
    store.dispatch(push("/"));
  }

  // Log the user out and redirect to the login page
  _logout() {
    logout()
      .then(() => {
        const data = {
          authenticated: false,
          denied_module_names: new Set([]),
          denied_paper_groups: [],
          denied_modules: [],
        };
        store.dispatch(setAuth(data));
        store.dispatch(push("/login"));
      })
      .catch(() => {
        store.dispatch(notify(tr("undefinedError"), "error"));
      });
  }

  _addCustomerToRecents() {
    store.dispatch(addRecentCustomer(this.props.selectedCustomer.cusno));
  }

  constructor(props) {
    super(props);

    this._onLogoClick = this._onLogoClick.bind(this);
    this._publisherChanged = this._publisherChanged.bind(this);
    this._addCustomerToRecents = this._addCustomerToRecents.bind(this);
  }

  componentDidMount() {
    // Get recent customers from Customer API
    store.dispatch(getRecentCustomers());
    // Get user publisher specific defaults
    store.dispatch(getUserDefaults());

    // Build 3.6.2.2 (Issue #31348)
    store.dispatch(getParrowUserSettings(store.getState().user.properties.userid));

    // Get current version for application
    store.dispatch(getVersionInformation());
  }

  componentDidUpdate(prevProps) {
    // Reload publisher specific data when publisher changes
    if (this.props.current !== prevProps.current) {
      store.dispatch(getRecentCustomers());
      store.dispatch(getUserDefaults());
      store.dispatch(getVersionInformation());
    }
  }

  render() {
    const publisherMenuProps = {
      publishers: this.props.publishers,
      current: this.props.current,
      publisherChanged: this._publisherChanged,
    };

    const headerMenuProps = {
      user: this.props.user,
      logout: this._logout,
      chgPwdOpen: this.props.chgPwdOpen,
    };

    const historySelectionProps = {
      handleSelection: this._historySelection,
      recentCustomers: this.props.recentCustomers,
      addCustomerToRecents: this._addCustomerToRecents,
      selectedCustomer: this.props.selectedCustomer,
    };
    const versionProps = {
      versionInformation: this.props.versionInformation,
    };

    return (
      <Header
        onLogoClick={this._onLogoClick}
        publisherMenuProps={publisherMenuProps}
        headerMenuProps={headerMenuProps}
        historySelectionProps={historySelectionProps}
        versionProps={versionProps}
      />
    );
  }
}

HeaderContainer.propTypes = {
  publishers: PropTypes.object.isRequired,
  current: PropTypes.string.isRequired,
  recentCustomers: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  selectedCustomer: PropTypes.object,
  version: PropTypes.string,
  chgPwdOpen: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    publishers: state.user.publishers.publishers,
    current: state.user.publishers.current,
    user: state.user.properties,
    recentCustomers: state.customer.recentCustomers,
    selectedCustomer: state.customer.selectedCustomer,
    versionInformation: state.versionInformation,
    chgPwdOpen: state.user.forceResetPassword || false,
  };
};

export default connect(mapStateToProps)(HeaderContainer);
