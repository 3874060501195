import apiUrl from "@utils/url.js";
import { fetchWithRetry } from "@utils/fetcher.js";

export async function fetchUserDefaults() {
  const url = apiUrl("/defaults");

  try {
    return await fetchWithRetry("getJson", [url]);
  } catch (error) {
    return { error };
  }
}

export async function fetchParrowUserSettings() {
  const url = apiUrl("/parameters/USER_SETTINGS/ALL");

  try {
    return await fetchWithRetry("getJson", [url]);
  } catch (error) {
    return { error };
  }
}
