import React from "react";
import PropTypes from "prop-types";

import ReportMenu from "../../components/report/ReportMenu.jsx";
import apiUrl from "@utils/url.js";
import { Fetcher } from "@utils/fetcher.js";
import { tr } from "@utils/translation.js";

import "@assets/styles/navigation.scss";

/*
 * Menu is a list of objects which is used to construct the actual main menu.
 * Link paths in the menu are also defined in the file ui/src/containers/Main.jsx,
 * which defines application's main routing.
 *
 * Menu supports link and submenu items. Item format described below:
 *
 * Link:
 * {
 *   title: 'Title string',     // A string or translation id (Integer)
 *   icon: 'link-icon',         // Ionions 3 icon name string
 *   link: '/path/to/somewhere' // A string URL
 *   matcher: 'RegExp object'   // Route matching RegExp. Required if a path has URL parameters.
 *   options: { object }        // React router Route component options
 * }
 *
 * Submenu:
 * {
 *   title: 123,                // A string or translation id (Integer)
 *   icon: 'submenu-icon',      // Ionions 3 icon name string
 *   items: [{}, {}, ...]       // A list of link and/or submenu item objects
 * }
 */
let menuItems = [];

let repgroupNames = {
  "02": "Customer service",
  "03": "Invoicing",
  "04": "Sales and Marketing",
  "05": "Post",
  "08": "Distribution",
  "09": "Salesmaterial",
  undef: "Undefined",
};

// This function goes through given menu structure and assigns necessary
// variables (id, parent menus, types, and classNames). This is so that it's
// easier to define the application menu by hand without needing to set every
// variable the menu needs to function properly. This menu variable must be
// immutable.
const _initMenu = (data) => {
  // Assign id's and types for menu items
  let counter = 0;
  const loop = (items, parent) => {
    return items.map((item) => {
      counter += 1;
      if (item.items !== undefined) {
        return {
          ...item,
          id: counter,
          type: "menu",
          parent: parent,
          items: loop(item.items, counter),
        };
      } else {
        return { ...item, id: counter, type: "link", parent: parent };
      }
    });
  };

  const menu = loop(data, null);

  // CSS classes for menu items based on their level
  const classes = {
    0: "main-menu-item",
    1: "sub-menu-item",
    2: "sub-sub-menu-item",
    3: "sub-sub-sub-menu-item",
  };

  // Assign levels and classNames for menu items
  let level = 0;
  const iter = (itemArray) => {
    const arr = [];

    // eslint-disable-next-line array-callback-return
    itemArray.map((items) => {
      // eslint-disable-next-line array-callback-return
      items.map((item) => {
        item.level = level;
        item.className = classes[level] !== undefined ? classes[level] : "";

        if (item.type === "menu") {
          arr.push(item.items);
        }
      });
    });

    level += 1;
    if (arr.length > 0) {
      iter(arr);
    }
  };

  iter([menu]);

  return menu;
};

// Initialize a simpler menu state which is a flat object with id's as keys.
// This object is used to track and change menu's state.
const _initMenuState = (menuItems) => {
  const state = {};
  const loop = (items) => {
    // eslint-disable-next-line array-callback-return
    items.map((item) => {
      if (item.type === "menu") {
        state[item.id] = { type: "menu", parent: item.parent, open: false };
        loop(item.items);
      } else {
        state[item.id] = {
          type: "link",
          parent: item.parent,
          link: item.link,
          matcher: item.matcher,
          report: item.report,
        };
      }
    });
  };

  loop(menuItems);

  return state;
};

class ReportNavigation extends React.Component {
  _fetchReports() {
    const requestBody = null;
    const url = apiUrl("reports");
    if (this.state.loading) return null;

    this.setState({ loading: true, reports: null });

    return Fetcher.getJson(url)
      .then((reports) => {
        if (this.state.reports !== null) {
          // When reports has value, return it
          return reports;
        }
        this.setState({ loading: false, reports: reports });
      })
      .catch(() => {
        this.setState({ loading: false, reports: this.state.reports });
      });
  }

  _toggleVisibility() {
    this.setState({ visible: !this.state.visible });
  }

  _menuClickHandler(id) {
    const target = id;
    const state = this.state.menu[target];
    const newState = { ...this.state.menu, [target]: { ...state, open: !state.open } };

    this.setState({ menu: newState });
    // Update parents repno which will propagate to other childs
    this.props.setReport(newState[id].report);
  }

  _findMenuItemByRoute(route, menu) {
    for (const key in menu) {
      const item = menu[key];
      if (item.matcher !== undefined) {
        if (item.matcher.test(route)) {
          return key;
        }
      } else {
        if (item.link === route) {
          return key;
        }
      }
    }
  }

  // Open submenus until the currently active route's link becomes visible.
  _openMenus(location, menu) {
    const id = this._findMenuItemByRoute(location.pathname, menu);
    const open = {};

    if (id !== undefined) {
      let parent = menu[id].parent;
      while (parent !== null) {
        open[parent] = { ...menu[parent], open: true };
        parent = menu[parent].parent;
      }
    }

    return open;
  }

  _toggleCollapse() {
    this.setState({ menuCollapsed: !this.state.menuCollapsed });
  }

  constructor(props) {
    super(props);

    // Immutable menu structure
    this.menu = _initMenu(menuItems);
    // Menu's state
    const menuState = _initMenuState(this.menu);
    // Which submenus should be opened
    const openMenus = this._openMenus("/", menuState);

    this.state = {
      menu: { ...menuState, ...openMenus },
      visible: true,
      menuCollapsed: false,
    };

    repgroupNames = {
      "02": tr("repGroup02"),
      "03": tr("repGroup03"),
      "04": tr("repGroup04"),
      "05": tr("repGroup05"),
      "08": tr("repGroup08"),
      "09": tr("repGroup09"),
      undef: tr("repGroupUndef"),
    };

    this._toggleCollapse = this._toggleCollapse.bind(this);
  }

  componentDidUpdate() {
    if (this.state.reports === undefined || this.state.reports === null) {
      this._fetchReports();
    }
    const reports = this.state.reports;
    if (!reports) return;
    if (this.state.loading === false) {
      menuItems = [];
      for (const i in reports) {
        const report = reports[i];
        let group = this._findMenuItemByRoute(`#${report.repgroupno}`, menuItems);
        let repGrpName = repgroupNames[report.repgroupno];
        if (repGrpName === undefined) repGrpName = repgroupNames.undef;
        repGrpName = `${repGrpName} - ${report.repgroupno}`;
        if (group === undefined) {
          const groupI = menuItems.push({
            title: repGrpName,
            icon: "ion-md-albums",
            link: `#${report.repgroupno}`,
            items: [],
          });
          group = menuItems[groupI - 1];
        } else group = menuItems[group];
        group.items.push({
          title: report.repname,
          icon: "",
          link: `#${report.repgroupno}/${report.repno}`,
          report: report,
        });
      }
      // Immutable menu structure
      this.menu = _initMenu(menuItems);
      // Menu's state
      const menuState = _initMenuState(this.menu);
      // Which submenus should be opened
      const openMenus = this._openMenus("/", menuState);
      this.setState({
        menu: { ...menuState, ...openMenus },
        loading: null,
        visible: true,
        menuCollapsed: false,
        reports: reports,
      });
    }

    // Check whether route has changed and, if so, open menus so that the
    // current route's link is visible on the navigation bar.
    /* if (this.props.location.pathname != prevProps.location.pathname) {
      let open = this._openMenus(this.props.location, this.state.menu);
      this.setState({ menu: { ...this.state.menu, ...open } });
    } */
  }

  render() {
    return (
      <div
        id="report-nav"
        ref={this.refCallback}
        className={this.state.menuCollapsed ? "menu-collapsed" : ""}
        style={{ overflowY: "scroll", maxHeight: "650px" }}
      >
        {!this.state.menuCollapsed && (
          <ReportMenu
            items={this.menu}
            state={this.state.menu}
            clickHandler={this._menuClickHandler.bind(this)}
          />
        )}
      </div>
    );
  }
}

ReportNavigation.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  setReport: PropTypes.func,
};

export default ReportNavigation;
