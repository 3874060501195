import { removeNullValues, resolveFieldValueFromObject } from "@utils/common.js";
import { Fetcher, fetchWithRetry } from "@utils/fetcher.js";
import apiUrl from "@utils/url.js";


export async function fetchSubsTypes() {
  const url = apiUrl(`substype`);

  return fetchWithRetry("getJson", [url])
    .then((substype = []) => {
      return substype;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function postSubsType(subsType) {
  const url = apiUrl(`substype`);
  let payload = subsType;

  return Fetcher.post(url, payload)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function putSubsType(subsType) {
  const url = apiUrl(`/substype`);
  let payload = subsType;

  return Fetcher.put(url, payload)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function deleteSubsType(subsType) {
  const url = apiUrl(`/substype/${subsType.papercode}/${subsType.substype_code}`);

  return Fetcher.delete(url)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}