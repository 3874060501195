import * as actionCreator from "@utils/actionCreator.js";

const SLICE = "translations";

export const SET_PROCESSING = `${SLICE}/SET_PROCESSING`;
export const setProcessing = (value) => {
  return actionCreator.createAction(SET_PROCESSING, value);
};

export const SET_TRANSLATION = `${SLICE}/SET_TRANSLATION`;
export const setTranslation = (langCode, translations) => {
  const payload = { langCode: langCode, values: translations };

  return actionCreator.createAction(SET_TRANSLATION, payload);
};

export const SWITCH_TRANSLATION = `${SLICE}/SWITCH_TRANSLATION`;
export const switchTranslation = (langCode) => {
  return actionCreator.createAction(SWITCH_TRANSLATION, langCode);
};

export const SET_AVAILABLE_TRANSLATIONS = `${SLICE}/SET_AVAILABLE_TRANSLATIONS`;
export const setAvailableTranslations = (items) => {
  return actionCreator.createAction(SET_AVAILABLE_TRANSLATIONS, items);
};

export const SET_TRANSLATION_DEBUG_MODE = `${SLICE}/SET_TRANSLATION_DEBUG_MODE`;
export const setTranslationDebugMode = (debugMode) => {
  return actionCreator.createAction(SET_TRANSLATION_DEBUG_MODE, debugMode);
};
