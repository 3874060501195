import React from "react";
import PropTypes from "prop-types";
import { DropdownToggle, DropdownMenu, DropdownItem, Dropdown } from "reactstrap";

class PublisherMenu extends React.Component {
  _renderItems() {
    const sorted = Object.keys(this.props.publishers).sort();

    return sorted.map((key, index) => {
      return (
        <DropdownItem key={index} data-value={key} onClick={this.props.publisherChanged}>
          {key}
        </DropdownItem>
      );
    });
  }

  _toggleOpen() {
    this.setState({ open: !this.state.open });
  }

  constructor(props) {
    super(props);
    this.state = { open: false };
  }

  render() {
    return (
      <div
        id="publisher-menu"
        className={this.state.open ? "publisher-button active" : "publisher-button"}
      >
        <Dropdown nav isOpen={this.state.open} toggle={this._toggleOpen.bind(this)}>
          <DropdownToggle nav caret>
            {this.props.current}
          </DropdownToggle>
          <DropdownMenu>{this._renderItems()}</DropdownMenu>
        </Dropdown>
      </div>
    );
  }
}

PublisherMenu.propTypes = {
  publishers: PropTypes.object.isRequired, // An object containing available publishers
  current: PropTypes.string.isRequired, // The currently selected publisher
  publisherChanged: PropTypes.func.isRequired, // A function to call on publisher change
};

export default PublisherMenu;
