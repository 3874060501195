import * as actions from "./reports.action";

const defaultState = {
  initialized: false,
  reportsReqList: [],
  reportsReqLoading: false,
  removingReport: false,
  reportsList:[],
  reportsLoading:false,
  paperTranslations:[],
  paperTranslations:false,
};

const reportsReducer = (state = defaultState, action) => {
  const { type, payload } = action;

  switch (type) {
    case actions.REPORTS_IS_LOADING_ALL_REPORTS_REQ: {
      return { ...state, reportsReqList: [], reportsReqLoading: true };
    }
    case actions.REPORTS_SET_ALL_REPORTS_REQ: {
      return { ...state, reportsReqList: payload, reportsReqLoading: false };
    }
    case actions.REPORTS_IS_LOADING_ALL_REPORTS: {
      return { ...state, reportsList: [], reportsLoading: true };
    }
    case actions.REPORTS_SET_ALL_REPORTS: {
      return { ...state, reportsList: payload, reportsLoading: false };
    }
    case actions.REPORTS_SET_REMOVING_REPORT: {
      return { ...state, removingReport: payload || false };
    }
    case actions.REPORTS_REPORT_REMOVED: {
      const reportsReqList = state.reportsReqList.filter((report) => report.reprequest_key !== payload.reprequest_key && report.paramkey !== payload.paramkey );
      return { ...state, reportsReqList };
    }
    case actions.REPORTS_IS_LOADING_PAPER_TRANSLATIONS: {
      return { ...state, paperTranslations: [], paperTranslationsLoading: true };
    }
    case actions.REPORTS_SET_PAPER_TRANSLATIONS: {
      return { ...state, paperTranslations: payload, paperTranslationsLoading: false };
    }
    default:
      return state;
  }
};

export default reportsReducer;
