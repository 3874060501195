import store from "@app/store.js";
import { postProductText } from "@app/productTexts/productTexts.effect.js";

export const addProductTexts = async (product) => {

  let texts = [];
  Object.entries(product).forEach(([key, value], index) => {
    if (key && key.indexOf("descriptiveText") !== -1 && value) {
      let _key = key.split("_")[1];
      texts.push({ key: _key, value: value });//index + 1
    }
  })

  if (texts && texts[0] && texts[0].key) {
    let response = await postProductText(texts[0].key, texts[0].value);
    response = await response.json();
    if (response) {

      const promises = texts.map((text, index) => {
        if (index > 0) {
          let descriptiveTextNo = text.key;
          let descriptiveText = text.value ? text.value : "";
          return postProductText(descriptiveTextNo, descriptiveText, response.descriptivetextid);
        }
      });
      await Promise.all(promises);
      return response.descriptivetextid;
    }
  }
}

